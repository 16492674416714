import * as Yup from "yup";
import {
  transformNumberValue,
  transformNumberValueAllowZero,
} from "../../../shared/transformNumberValue";
import { IEmissionFactors } from "./types";
import { testNumberWithDotLength } from "../../../shared/testNumberWithDotLength";

export const formSchema = Yup.object().shape({
  productType: Yup.number()
    .typeError("Field accepts numbers only")
    .min(0, "Please select product type")
    .required()
    .integer(),
  productId: Yup.number()
    .typeError("Field accepts numbers only")
    .min(0, "Please select product type")
    .required()
    .integer(),
  productNameAtSource: Yup.string()
    .max(200, "Max 200 characters")
    .required("Product Name At Source is a required field"),
  numberOfStudies: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .positive("Field value should be greater than 0!")
    .required("Number of studies is required")
    .integer(),
  numberOfCycles: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .positive("Field value should be greater than 0!")
    .integer(),
  countryId: Yup.number()
    .typeError("Field accepts numbers only")
    .integer()
    .nullable(true),
  referenceSourcingCountry: Yup.number()
    .typeError("Field accepts numbers only")
    .integer()
    .nullable(true),
  regionIdentifierId: Yup.number()
    .typeError("Please select region")
    .min(1, "Please select region")
    .required("Please select region")
    .integer(),
  referenceRegionIdentifier: Yup.number()
    .typeError("Field accepts numbers only")
    .min(1, "Please select region")
    .required()
    .integer(),
  productionSystem: Yup.number().when("productType", {
    is: (val: number) => val == 0,
    then: Yup.number().min(1, "Please select production system").required(),
    otherwise: Yup.number().nullable(true),
  }),
  plantProtectionTypeGroup: Yup.number().when("productType", {
    is: (val: number) => val == 0,
    then: Yup.number()
      .min(1, "Please select plant protection type group")
      .required(),
    otherwise: Yup.number().nullable(true),
  }),
  functionalUnit: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Please select unit")
    .required("Functional Unit is a required field")
    .integer(),
  coreVersionRelease: Yup.string()
    .max(
      256,
      "Reference Technology Type field maximum length is 256 characters!"
    )
    .required("Core Version Release field is required"),
  calculatorVersion: Yup.string().when("dataOrigin", {
    is: (val: number) => val == 1 || val == 2,
    then: Yup.string().nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  farmDataCalculator: Yup.string().when("dataOrigin", {
    is: (val: number) => val == 1 || val == 2,
    then: Yup.string()
      .required("Farm Data Calculator is a required field")
      .nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  pnNExcelCalculator: Yup.number().when("dataOrigin", {
    is: (val: number) => val == 1 || val == 2,
    then: Yup.number()
      .transform(transformNumberValueAllowZero)
      .typeError("Field accepts numbers only")
      .min(0, "Please select excel calculator")
      .required("PnN Excel Calculator is a required field")
      .nullable(true),
    otherwise: Yup.number().nullable(true),
  }),
  dataOrigin: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Please select data origin")
    .required("Please select data origin")
    .integer(),
  externalSource: Yup.string().when("dataOrigin", {
    is: (val: number) => val == 3,
    then: Yup.string()
      .required("External Source field is required")
      .nullable(true),
    otherwise: Yup.string().nullable(true),
  }),
  postHarvestLossesId: Yup.number().when("functionalUnit", {
    is: (val: number) => val == 0 || val == 5 || val == 6,
    then: Yup.number()
      .transform(transformNumberValueAllowZero)
      .required("Post Harvest Losses Id field is required")
      .nullable(true),
    otherwise: Yup.number().nullable(true),
  }),
  postHarvestLosses: Yup.number().when("functionalUnit", {
    is: (val: number) => val == 0 || val == 5 || val == 6,
    then: Yup.number()
      .required("Post Harvest Losses field is required")
      .min(0, "Number should be greater or equal 0")
      .max(100, "Number should be less or equal 100"),
    otherwise: Yup.number().nullable(true),
  }),
  landUse: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Land use is required")
    .min(0, "Number should be greater or equal 0")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  biodiversity: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Biodiversity is required")
    .min(0, "Number should be greater or equal 0")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  biodiversityPDFYear: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Number should be greater or equal 0")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  carbon: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Carbon is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  eutrophication: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Eutrophication is required")
    .min(0, "Number should be greater or equal 0")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  waterUsage: Yup.number().when("productType", {
    is: (val: number) => val == 3,
    then: Yup.number()
      .transform(transformNumberValueAllowZero)
      .typeError("Field accepts numbers only")
      .required("Water usage is required")
      .test(
        "len",
        "Max symbols allowed - 32. Max symbols after dot - 16",
        testNumberWithDotLength
      ),
    otherwise: Yup.number()
      .transform(transformNumberValueAllowZero)
      .typeError("Field accepts numbers only")
      .required("Water usage is required")
      .min(0, "Number should be greater or equal 0")
      .test(
        "len",
        "Max symbols allowed - 32. Max symbols after dot - 16",
        testNumberWithDotLength
      ),
  }),
  waterUsageIncludingWasterScarcity: Yup.number().when("productType", {
    is: (val: number) => val == 3,
    then: Yup.number()
      .transform(transformNumberValueAllowZero)
      .typeError("Field accepts numbers only")
      .required("Field is required")
      .test(
        "len",
        "Max symbols allowed - 32. Max symbols after dot - 16",
        testNumberWithDotLength
      ),
    otherwise: Yup.number()
      .transform(transformNumberValueAllowZero)
      .typeError("Field accepts numbers only")
      .required("Field is required")
      .min(0, "Number should be greater or equal 0")
      .test(
        "len",
        "Max symbols allowed - 32. Max symbols after dot - 16",
        testNumberWithDotLength
      ),
  }),
  referencesSamplingStartYear: Yup.number()
    .typeError("Field accepts numbers only")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .nullable(true),
  referencesSamplingYear: Yup.number()
    .typeError("Field accepts numbers only")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .nullable(true),
  secondaryDataReferenceSourceType: Yup.number()
    .typeError("Field accepts numbers only")
    .min(0, "Please select sources")
    .required()
    .integer(),
  referenceDataPublicationYear: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .required("Reference data publication year field is required!"),
  referenceTechnologyType: Yup.string()
    .max(
      256,
      "Reference Technology Type field maximum length is 256 characters!"
    )
    .required("Reference technology type field is required"),
  referenceTechnologyProxyContribution: Yup.number()
    .typeError("Please enter value between 0 and 100")
    .min(0, "Value should be between 0 and 100")
    .max(100, "Value should be between 0 and 100")
    .test("len", "Max symbols after dot - 16", testNumberWithDotLength)
    .nullable(true),
  referenceSourcingCountryProxyContribution: Yup.number()
    .typeError("Field accepts numbers only")
    .min(0, "Value should be between 0 and 100")
    .max(100, "Value should be between 0 and 100")
    .test("len", "Max symbols after dot - 16", testNumberWithDotLength)
    .nullable(true),
  flag_L1_VerifiedRemovalsCarbon: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required()
    .integer(),
  flag_L1_UnverifiedRemovalsCarbon: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required()
    .integer(),
});

export const copyToClipboard = (value: string) => {
  const anyNavigator: any = window.navigator;
  anyNavigator.clipboard.writeText(value);
};

export enum TabsEnum {
  PRODUCT = "PRODUCT",
  DATA_ORIGIN = "DATA_ORIGIN",
  EMISSIONS = "EMISSIONS",
  IMPACTS = "IMPACTS",
  LOSSES = "LOSSES",
  DATA_QUALITY = "DATA_QUALITY",
}

export const FLAG_FIELDS = [
  "flag_L1_LandManagementCarbon",
  "flag_L1_LandManagementNonCarbon",
  "flag_L1_LandUseChangeCarbon",
  "flag_L1_NonLandCarbon",
];

export const TAB_LABELS = {
  [TabsEnum.PRODUCT]: "Product/produce info",
  [TabsEnum.DATA_ORIGIN]: "Data origin",
  [TabsEnum.EMISSIONS]: "GHG Emissions",
  [TabsEnum.IMPACTS]: "Impacts",
  [TabsEnum.LOSSES]: "Losses",
  [TabsEnum.DATA_QUALITY]: "Data quality",
};

export const TAB_ERRORS = {
  [TabsEnum.PRODUCT]: [
    "productType",
    "productId",
    "productNameAtSource",
    "countryId",
    "regionIdentifierId",
    "productionSystem",
    "plantProtectionTypeGroup",
    "functionalUnit",
  ],
  [TabsEnum.DATA_ORIGIN]: [
    "calculatorVersion",
    "farmDataCalculator",
    "pnNExcelCalculator",
    "dataOrigin",
    "externalSource",
  ],
  [TabsEnum.EMISSIONS]: [
    "flag_L1_UnverifiedRemovalsCarbon",
    "flag_L1_VerifiedRemovalsCarbon",
  ],
  [TabsEnum.IMPACTS]: [
    "landUse",
    "biodiversity",
    "biodiversityPDFYear",
    "carbon",
    "eutrophication",
    "waterUsage",
    "waterUsageIncludingWasterScarcity",
  ],
  [TabsEnum.LOSSES]: ["postHarvestLossesId", "postHarvestLosses"],
  [TabsEnum.DATA_QUALITY]: [
    "referencesSamplingStartYear",
    "referencesSamplingYear",
    "secondaryDataReferenceSourceType",
    "referenceDataPublicationYear",
    "referenceSourcingCountry",
    "referenceRegionIdentifier",
    "referenceTechnologyType",
    "referenceTechnologyProxyContribution",
    "referenceSourcingCountryProxyContribution",
    "numberOfStudies",
    "numberOfCycles",
  ],
};

export const getFLAGCarbonValue = (data: IEmissionFactors) =>
  FLAG_FIELDS.every((item) => data[item] === null)
    ? null
    : FLAG_FIELDS.reduce((acc, field) => {
        const fieldValue = data[field];

        if (!fieldValue) {
          return acc;
        }

        const value = parseFloat(fieldValue);

        return value ? Number((acc + value).toPrecision(12)) : acc;
      }, 0);
