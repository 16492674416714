import { gql } from "@apollo/client";

export const GET_ALL_STORAGES = gql`
  query AllPieces(
    $str: String
    $temperatureConditions: NullableOfTemperatureConditionsEnumOperationFilterInput
    $enclosure: NullableOfEnclosureEnumOperationFilterInput
    $foodCategory: NullableOfFoodCategoryEnumOperationFilterInput
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $state: StateEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [StorageActivitiesGraphQlSortInput!]
  ) {
    temperatureConditions: __type(name: "TemperatureConditionsEnum") {
      name
      enumValues {
        name
        description
      }
    }
    enclosure: __type(name: "EnclosureEnum") {
      name
      enumValues {
        name
        description
      }
    }
    foodCategory: __type(name: "FoodCategoryEnum") {
      name
      enumValues {
        name
        description
      }
    }
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }

    storageActivity: __type(name: "StorageActivitiesGraphQl") {
      fields {
        name
        description
      }
    }

    storageActivities(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { researcherName: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { referenceCountryISOCode: { contains: $str } }
          { referenceRegionIdentifier: { contains: $str } }
          { code: { contains: $str } }
          { enclosure: $enclosure }
          { foodCategory: $foodCategory }
          { temperatureConditions: $temperatureConditions }
          {
            secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType
          }
          { state: $state }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          state
          name
          description
          temperatureConditions
          enclosure
          foodCategory
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          referencesSamplingYear
          referenceDataPublicationYear
          referenceSourcingCountry
          referenceCountryISOCode
          referenceRegionIdentifier
          dataQualityComment
          researcherName
          isVerified
          verifiedOn
          lastUpdatedName
          lastPublishedDate
          updated
        }
        cursor
      }
    }
  }
`;
