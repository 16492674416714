import * as Yup from "yup";
import {
  transformNumberValue,
  transformNumberValueAllowZero,
} from "../../../shared/transformNumberValue";
import { testNumberWithDotLength } from "../../../shared/testNumberWithDotLength";
import { IOptionProps } from "../../../types/componentTypes";

export const pieceComponentSchema = Yup.object().shape({
  componentName: Yup.number()
    .min(0, "Component name is required")
    .transform(transformNumberValueAllowZero)
    .required("Component name is required"),
  componentMass: Yup.number()
    .typeError("Field accepts numbers only")
    .positive("Field value should be greater than 0!")
    .required("Component Mass is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  materialId: Yup.number()
    .transform(transformNumberValue)
    .required("Material is required"),
  materialComposition: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Field accepts positive numbers only")
    .required("Material composition is required")
    .test(
      "percentage",
      "Max value should be less than 100%",
      (val = 0) => val <= 100
    ),
  processingTypeId: Yup.number()
    .transform(transformNumberValue)
    .required("Processing type is required"),
  recycledContent: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Field accepts positive numbers only")
    .required("Recycle content is required")
    .test(
      "percentage",
      "Max value should be less than 100%",
      (val = 0) => val <= 100
    ),
});

export const formSchema = Yup.object().shape({
  pieceGroup: Yup.number()
    .typeError("Please select group")
    .min(0, "Please select group")
    .required()
    .integer(),
  typicalUses: Yup.number()
    .typeError("Please select typical uses")
    .min(0, "Please select typical uses")
    .required()
    .integer(),
  sourcedFromRERRoW: Yup.number()
    .typeError("Please select region")
    .min(1, "Please select region")
    .required()
    .integer(),
  packagingType: Yup.number()
    .typeError("Please select packaging type")
    .min(0, "Please select packaging type")
    .required()
    .integer(),
  referenceSourcingCountry: Yup.number()
    .typeError("Please select Reference Sourcing Country")
    .integer()
    .nullable(true),
  secondaryDataReferenceSourceType: Yup.number()
    .typeError("Please select Secondary Data Reference Source Type")
    .integer()
    .nullable(true),
  pieceName: Yup.string()
    .max(256, "Too Long!")
    .required("Piece description field is required!"),
  clickUpId: Yup.string()
    .transform((val) => (val === null ? null : val.trim()))
    .url('Click up ID must be a valid URL like "https://mondra.com"')
    .nullable(true),
  length: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .positive("Field value should be greater than 0!"),
  width: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .positive("Field value should be greater than 0!"),
  height: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .positive("Field value should be greater than 0!"),
  maximumLoadingCapacity: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .required("Capacity is required")
    .positive("Field value should be greater than 0!")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  numberOfUses: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .required("Number of uses is required")
    .positive("Field value should be greater than 0!"),
  totalMass: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .required("Total mass is required")
    .positive("Field value should be greater than 0!")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  carbon: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Carbon is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  waterUsage: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Water usage is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  packagingMaterialLoss: Yup.number()
    .typeError("Field accepts numbers only")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 10",
      (val) => testLength(val)
    )
    .nullable(true),
  eutrophication: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Eutrophication is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  referenceRegionIdentifier: Yup.number()
    .typeError("Field accepts numbers only")
    .nullable(true),
  referenceDataPublicationYear: Yup.number()
    .typeError("Field accepts numbers only")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .nullable(true),
  referencesSamplingYear: Yup.number()
    .typeError("Field accepts numbers only")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .nullable(true),
  componentsDetails: Yup.array().of(pieceComponentSchema),
});

const testLength = (value?: number) => {
  const val = value || 0;
  return (
    (val.toString().includes(".") && val.toString().split(".")[1].length) <
      11 && val.toString().length < 33
  );
};

export const filterUnknown = (
  array: IOptionProps[],
  id: string | null
): IOptionProps[] => {
  const ignoreItems = [
    "Unspecified",
    "Unknown Packaging Piece",
    "Unknown Component",
    "Unknown Material",
    "Unknown Processing Type",
  ];
  const ignoreID = "PackagingPiece_001999";

  return array.filter((fil) =>
    id === ignoreID ? true : !ignoreItems.includes(fil.label)
  );
};
