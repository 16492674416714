import React, { FC } from "react";

import InputItem from "../../../components/InputItem";
import { ITabBaseProps } from "./types";

export const Impacts: FC<ITabBaseProps> = ({ isCustomEF, formik }) => {
  return (
    <div className="mb-5 mt-5">
      <p>
        Please provide environmental impacts for 1 kg of the functional unit
        specified
      </p>
      <div className="piece-content-inputs mb-5">
        <InputItem
          disabled={isCustomEF.current}
          name="Land Use"
          description="Please provide Land use in m2*year."
          type="input"
          addonTitle="m2*y"
          onChange={formik.handleChange}
          formikName="landUse"
          showAsterisk
          formik={formik}
          defaultValue={
            formik.values.landUse === 0 ? "0" : formik.values.landUse
          }
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Eutrophication"
          description="Please provide eutrophication in g PO43- eq"
          type="input"
          addonTitle="g"
          onChange={formik.handleChange}
          formikName="eutrophication"
          formik={formik}
          showAsterisk
          defaultValue={
            formik.values.eutrophication === 0
              ? "0"
              : formik.values.eutrophication
          }
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Biodiversity. sp.yr*10^14"
          description="Please provide the Biodiversity impact in sp.yr*10^14."
          type="input"
          addonTitle="sp.yr*10^14"
          onChange={formik.handleChange}
          formikName="biodiversity"
          formik={formik}
          showAsterisk
          defaultValue={
            formik.values.biodiversity === 0 ? "0" : formik.values.biodiversity
          }
        />
        {(formik.values.dataOrigin == 1 || formik.values.dataOrigin == 2) &&
          formik.values.farmDataCalculator == 0 && (
            <InputItem
              disabled={isCustomEF.current}
              name="Biodiversity (PDF*year)"
              description={
                'Please provide the Biodiversity impact in "Potentially Disappeared Fraction" (PDF) *year'
              }
              type="input"
              addonTitle="PDF*year"
              onChange={formik.handleChange}
              formikName="biodiversityPDFYear"
              formik={formik}
              defaultValue={
                formik.values.biodiversityPDFYear === 0
                  ? "0"
                  : formik.values.biodiversityPDFYear
              }
            />
          )}
        <InputItem
          disabled={isCustomEF.current}
          name="Freshwater Withdrawal"
          description="Please provide water usage in L"
          type="input"
          addonTitle="L"
          onChange={formik.handleChange}
          formikName="waterUsage"
          formik={formik}
          showAsterisk
          defaultValue={
            formik.values.waterUsage === 0 ? "0" : formik.values.waterUsage
          }
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Freshwater accounting for Water Scarcity"
          description="Please provide water usage including water scarcity conversion in L eq."
          type="input"
          addonTitle="L eq"
          onChange={formik.handleChange}
          formikName="waterUsageIncludingWasterScarcity"
          formik={formik}
          showAsterisk
          defaultValue={
            formik.values.waterUsageIncludingWasterScarcity === 0
              ? "0"
              : formik.values.waterUsageIncludingWasterScarcity
          }
        />
      </div>
      <InputItem
        disabled={isCustomEF.current}
        name="Comment"
        description="Please provide any additional information you have on this entry."
        type="text"
        onChange={formik.handleChange}
        formik={formik}
        className={"w-full"}
        defaultValue={formik.values.comment || ""}
        formikName="comment"
      />
    </div>
  );
};

export default Impacts;
