import React, { FC } from "react";

import {
  getDefaults,
  multipleObject,
  sortDropdown,
} from "../../../shared/formFunctions";

import Select from "react-select";
import InputItem from "../../../components/InputItem";
import {
  IEmissionFactors,
  IConfiguration,
  IProxyProduceDetails,
  IDropdown,
  ITabBaseProps,
} from "./types";

interface IDataQuality extends ITabBaseProps {
  configuration: IConfiguration;
  isNew: boolean;
  selectedProxyProduce: IProxyProduceDetails;
  proxyCategory: IDropdown[];
  proxySubcategory: IDropdown[];
  factor: IEmissionFactors;
  proxyFunctionalUnit: IDropdown[];
  onProxyClassChange(e: React.ChangeEvent): void;
  onProxyCategoryChange(e: React.ChangeEvent): void;
  onProxyProductGroupChange(e: React.ChangeEvent): void;
}

export const DataQuality: FC<IDataQuality> = ({
  isCustomEF,
  formik,
  configuration,
  isNew,
  selectedProxyProduce,
  proxyCategory,
  proxySubcategory,
  factor,
  proxyFunctionalUnit,
  onProxyClassChange,
  onProxyCategoryChange,
  onProxyProductGroupChange,
}) => {
  return (
    <>
      {formik.values.dataOrigin == 1 && (
        <>
          <h6 className="mt-3">Data quality: Impact Proxy</h6>
          <div className="piece-content-inputs">
            <div className="inputItem">
              <h3 className="mt-5">Missing Impact Factor</h3>
              <p className={"decoration-gray-600 mb-3"}>
                Please specify if this MA was carried out to replace a missing
                eutrophication value in the PnN Original Publication.
              </p>
              <Select
                isDisabled={isCustomEF.current}
                isClearable={false}
                defaultValue={[
                  ...getDefaults(
                    factor.missingImpactFactor,
                    configuration.missingImpactFactors
                  ),
                ]}
                name="missingImpactFactor"
                onChange={(newValue: any, action: any) => {
                  action.action === "remove-value" &&
                    formik.setFieldValue(
                      "missingImpactFactor",
                      formik.values.missingImpactFactor !== null
                        ? (formik.values.missingImpactFactor -=
                            action.removedValue.value)
                        : null
                    );
                  action.action === "select-option" &&
                    formik.setFieldValue(
                      "missingImpactFactor",
                      (formik.values.missingImpactFactor += action.option.value)
                    );
                }}
                isMulti
                className="basic-multi-select"
                options={multipleObject(configuration.missingImpactFactors)}
              />
              {formik.touched.missingImpactFactor &&
              formik.errors.missingImpactFactor ? (
                <span className="formik-error">
                  {formik.errors.missingImpactFactor}
                </span>
              ) : (
                ""
              )}
            </div>
            <InputItem
              disabled={isCustomEF.current}
              name="Proxy Country"
              description=""
              type="select"
              defaultValue={formik.values.proxyCountryId || 0}
              onChange={formik.handleChange}
              formikName="proxyCountryId"
              create={true}
              formik={formik}
              options={sortDropdown(
                configuration.countries,
                formik.values.proxyCountryId !== null
                  ? +formik.values.proxyCountryId
                  : 0,
                "countryName"
              )}
            />
            {isNew || (!isNew && factor.proxyProduceId === null) ? (
              <>
                <InputItem
                  disabled={isCustomEF.current}
                  name="Proxy Class"
                  description=""
                  type="select"
                  defaultValue={selectedProxyProduce.class}
                  onChange={onProxyClassChange}
                />
                <InputItem
                  name="Proxy Category"
                  description=""
                  type="select"
                  defaultValue={selectedProxyProduce.category}
                  disabled={proxyCategory.length === 0 || isCustomEF.current}
                  onChange={onProxyCategoryChange}
                  options={proxyCategory.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                />
                <InputItem
                  name="Proxy Product Group"
                  description=""
                  type="select"
                  disabled={proxySubcategory.length === 0 || isCustomEF.current}
                  defaultValue={selectedProxyProduce.productGroup}
                  onChange={onProxyProductGroupChange}
                  options={proxySubcategory.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                />
                <InputItem
                  name="Proxy Produce"
                  description="Please select a suitable product Name from the Ingredient taxonomy."
                  type="select"
                  defaultValue={formik.values.proxyProduceId}
                  disabled={
                    proxyFunctionalUnit.length === 0 || isCustomEF.current
                  }
                  onChange={formik.handleChange}
                  formikName="proxyProduceId"
                  options={proxyFunctionalUnit.map((el) => ({
                    label: el.name,
                    value: el.id,
                  }))}
                />
              </>
            ) : (
              configuration.eutrophicationProxyProduceTaxonomies
                .functionalUnits !== null && (
                <>
                  <InputItem
                    disabled={isCustomEF.current}
                    name="Proxy Class"
                    description=""
                    type="select"
                    defaultValue={selectedProxyProduce.class}
                    onChange={onProxyClassChange}
                    options={configuration.eutrophicationProxyProduceTaxonomies.classes.map(
                      (el) => ({
                        label: el.name,
                        value: el.id,
                      })
                    )}
                  />
                  <InputItem
                    name="Proxy Category"
                    description=""
                    type="select"
                    defaultValue={selectedProxyProduce.category}
                    onChange={onProxyCategoryChange}
                    disabled={
                      (formik.values.proxyProduceId === null &&
                        proxyCategory.length === 0) ||
                      isCustomEF.current
                    }
                    options={
                      formik.values.proxyProduceId === null
                        ? proxyCategory.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : proxyCategory.length !== 0
                        ? proxyCategory.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.eutrophicationProxyProduceTaxonomies
                              .categories,
                            configuration.eutrophicationProxyProduceTaxonomies.categories.filter(
                              (el) =>
                                el.id ===
                                configuration.eutrophicationProxyProduceTaxonomies.subcategories.filter(
                                  (el) =>
                                    el.id ===
                                    configuration.eutrophicationProxyProduceTaxonomies.functionalUnits.find(
                                      (el) => el.id === factor.proxyProduceId
                                    )?.subcategoryId
                                )[0]?.categoryId
                            )[0]?.id,
                            "name"
                          )
                    }
                  />
                  <InputItem
                    name="Proxy Product Group"
                    description=""
                    type="select"
                    defaultValue={selectedProxyProduce.productGroup}
                    onChange={onProxyProductGroupChange}
                    disabled={
                      (formik.values.proxyProduceId === null &&
                        proxySubcategory.length === 0) ||
                      isCustomEF.current
                    }
                    options={
                      formik.values.proxyProduceId === null
                        ? proxySubcategory.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : proxySubcategory.length !== 0
                        ? proxySubcategory.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.eutrophicationProxyProduceTaxonomies
                              .subcategories,
                            configuration.eutrophicationProxyProduceTaxonomies.subcategories.filter(
                              (el) =>
                                el.id ===
                                configuration.eutrophicationProxyProduceTaxonomies.functionalUnits.find(
                                  (el) => el.id === factor.proxyProduceId
                                )?.subcategoryId
                            )[0]?.id,
                            "name"
                          )
                    }
                  />
                  <InputItem
                    name="Proxy Produce"
                    description="Please select a suitable product Name from the Ingredient taxonomy."
                    type="select"
                    defaultValue={formik.values.proxyProduceId}
                    onChange={formik.handleChange}
                    formikName="proxyProduceId"
                    disabled={
                      (formik.values.proxyProduceId === null &&
                        proxyFunctionalUnit.length === 0) ||
                      isCustomEF.current
                    }
                    create={
                      proxySubcategory.length !== 0 ||
                      proxyFunctionalUnit.length !== 0
                    }
                    options={
                      formik.values.proxyProduceId === null
                        ? proxyFunctionalUnit.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : proxyFunctionalUnit.length !== 0 ||
                          proxySubcategory.length !== 0
                        ? proxyFunctionalUnit.map((el) => ({
                            label: el.name,
                            value: el.id,
                          }))
                        : sortDropdown(
                            configuration.eutrophicationProxyProduceTaxonomies
                              .functionalUnits,
                            formik.values.proxyProduceId || 0,
                            "name"
                          )
                    }
                  />
                </>
              )
            )}
          </div>
        </>
      )}
      <h6 className="mt-5">Data Quality: Reliability and Completeness</h6>
      <div className="piece-content-inputs mb-5">
        <InputItem
          disabled={isCustomEF.current}
          name="Number of studies"
          description=""
          type="input"
          onChange={formik.handleChange}
          formikName="numberOfStudies"
          formik={formik}
          showAsterisk
          defaultValue={formik.values.numberOfStudies || ""}
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Number of cycles"
          description="The Inputs, Emissions, Products, and Practices created or used during a period."
          type="input"
          onChange={formik.handleChange}
          formikName="numberOfCycles"
          formik={formik}
          defaultValue={formik.values.numberOfCycles || ""}
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Study"
          description="Please list the references that were used to create this record."
          type="input"
          onChange={formik.handleChange}
          formikName="referenceStudy"
          formik={formik}
          defaultValue={formik.values.referenceStudy || ""}
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Secondary Data Reference Source Type"
          description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
          type="select"
          defaultValue={formik.values.secondaryDataReferenceSourceType}
          onChange={formik.handleChange}
          formik={formik}
          formikName="secondaryDataReferenceSourceType"
          showAsterisk
          options={sortDropdown(
            configuration.secondaryDataReferenceSourceType,
            formik.values.secondaryDataReferenceSourceType,
            "description"
          )}
        />
        <InputItem
          disabled
          name="Data quality reliability score"
          description="Read only"
          type="input"
          formik={formik}
          defaultValue={formik.values.dataQualityReliabilityScore || ""}
          formikName="dataQualityReliabilityScore"
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Data Publication Year"
          description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
          type="input"
          onChange={formik.handleChange}
          formik={formik}
          defaultValue={formik.values.referenceDataPublicationYear || ""}
          formikName="referenceDataPublicationYear"
          showAsterisk
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Sampling Start Date"
          description="The date in which the Site in its current form was established YYYY."
          type="input"
          onChange={formik.handleChange}
          formik={formik}
          defaultValue={formik.values.referencesSamplingStartYear || ""}
          formikName="referencesSamplingStartYear"
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Sampling End Date"
          description="The date in which the site in its current form ceased to exist or in which data sampling ended in YYYY. If the data was equally collected from multiple studies please select the sourcing end year from the study published the furthest in the past."
          type="input"
          onChange={formik.handleChange}
          formik={formik}
          defaultValue={formik.values.referencesSamplingYear || ""}
          formikName="referencesSamplingYear"
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Technology Proxy Contribution"
          description="Please indicate, the sum of the respective percentage contribution for the five research areas that use a proxy reference technology type (e.g. type of produce) to complete the dataset: Fertilisers – 15.3%, Pesticides – 15.3%, Irrigation  – 15.3%, Machinery, fuel/energy 15.3%, Yield, secondary products (Y/N), type of products & economic allocation – 38.5%. Please indicate the value as a percentage (e.g. 15.3%)."
          type="input"
          formik={formik}
          addonTitle="%"
          onChange={formik.handleChange}
          defaultValue={
            formik.values.referenceTechnologyProxyContribution === null
              ? ""
              : formik.values.referenceTechnologyProxyContribution.toString()
          }
          formikName="referenceTechnologyProxyContribution"
        />
      </div>
      <h3 className="mt-5">Reference Technology Type</h3>
      <p className={"decoration-gray-600 mb-1"}>
        Please specify the technology type (e.g. produce type for farming) that
        was used to generate this entry. This should be the produce that was
        used for the majority of the raw data entry. Please indicate the
        respective technology, if a proxy (technological/produce and
        geographical) was used for more than 20% of the data (excluding any gap
        filling applied in the calculator) – Please indicate, if a proxy was
        used for more than 20% of the weighting towards the key five research
        areas:
        <br />
        - Fertilisers – 15.3%
        <br />
        - Pesticides – 15.3%
        <br />
        - Irrigation, quantity of irrigation water – 15.3%
        <br />
        - machinery, fuel/energy inputs – 15.3%
        <br />- yield, secondary products (Y/N), type of products & economic
        allocation – 38.5%
      </p>
      <InputItem
        disabled={isCustomEF.current}
        name=""
        description=""
        type="text"
        onChange={formik.handleChange}
        formik={formik}
        showAsterisk
        className={"w-full mt-2"}
        defaultValue={formik.values.referenceTechnologyType || ""}
        formikName="referenceTechnologyType"
      />
      <div className="piece-content-inputs mb-5">
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Sourcing Country"
          description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
          type="select"
          onChange={formik.handleChange}
          defaultValue={
            formik.values.referenceSourcingCountry
              ? formik.values.referenceSourcingCountry
              : -1
          }
          formik={formik}
          formikName="referenceSourcingCountry"
          options={sortDropdown(
            configuration.countries,
            formik.values.referenceSourcingCountry ?? -1,
            "countryName"
          )}
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Country ISO Code"
          description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
          type="select"
          onChange={formik.handleChange}
          defaultValue={
            formik.values.referenceSourcingCountry
              ? formik.values.referenceSourcingCountry
              : -1
          }
          formik={formik}
          formikName="referenceSourcingCountry"
          options={sortDropdown(
            configuration.countries,
            formik.values.referenceSourcingCountry ?? -1,
            "countryISO"
          )}
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Region Identifier"
          description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
          type="select"
          defaultValue={formik.values.referenceRegionIdentifier}
          onChange={formik.handleChange}
          formik={formik}
          formikName="referenceRegionIdentifier"
          showAsterisk
          options={sortDropdown(
            configuration.regions,
            formik.values.referenceRegionIdentifier,
            "name"
          )}
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Reference Sourcing Country Proxy Contribution"
          description="Please indicate, the sum of the respective percentage contribution for the five research areas that use a proxy country to complete the dataset:  Fertilisers – 15.3%, Pesticides – 15.3%, Irrigation  – 15.3%, Machinery, fuel/energy 15.3%, Yield, secondary products (Y/N), type of products & economic allocation – 38.5%. Please indicate the value as a percentage (e.g. 15.3%)."
          type="input"
          formik={formik}
          addonTitle="%"
          onChange={formik.handleChange}
          defaultValue={
            formik.values.referenceSourcingCountryProxyContribution === null
              ? ""
              : formik.values.referenceSourcingCountryProxyContribution.toString()
          }
          formikName="referenceSourcingCountryProxyContribution"
        />
      </div>
      <InputItem
        disabled={isCustomEF.current}
        name="Data Quality Comment"
        description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
        type="text"
        onChange={formik.handleChange}
        formik={formik}
        className={"w-full"}
        defaultValue={formik.values.dataQualityComment || ""}
        formikName="dataQualityComment"
      />
    </>
  );
};

export default DataQuality;
