import { IGetTransport } from "./types";

export const getTransportVars: IGetTransport = {
  resource: {
    in: [
      "FreightTrain",
      "FreightTrainElectric",
      "FreightLorry",
      "FreightShip",
      "FreightShipContainer",
      "FreightShipDryGoods",
      "FreightAircraft",
    ],
  },
  temperatureConditions: {
    in: ["Ambient", "RoomTemperature", "Chilled", "Frozen"],
  },
  secondaryDataReferenceSourceType: {
    in: [
      "PrimaryDataFromIndustrySenseChecked",
      "PrimaryDataMeasurementsTakenByMondra",
      "PeerReviewedPublishedLiterature",
      "PublishedBook",
      "IndustryPublishedReport",
      "Ecoinvent",
      "AgriFootPrint",
      "GovernmentFundedOrganizationReport",
      "NonGovernmentalFundedOrganizationReport",
      "Patent",
      "CompanyWebsite",
      "BlogWebsiteForum",
      "OpenSourceWebsite",
      "UndocumentedEstimate",
    ],
  },
  medium: {
    in: ["Land", "Sea", "Air"],
  },
  state: {
    in: ["Unpublished", "Published"],
  },
};
