import { FC, ChangeEvent } from "react";
import { Select } from "@mondra/ui-components";
import { IOptionProps } from "../../types/componentTypes";
import { useAppSelector } from "../../hooks/redux";

import "./index.scss";

interface CompanySelectProps {
  onChange: (e: ChangeEvent) => void;
  companyId?: string | null;
  disabled?: boolean | false;
}

const CompanySelect: FC<CompanySelectProps> = ({ onChange, companyId, disabled }) => {
  const { companies } = useAppSelector((state) => state.companiesSlice);
  const options: IOptionProps[] = companies.map((el) => ({
    label: el.name,
    value: el.id,
  }));

  const getOptions = () => {
    if (companyId == null) {
      return [{ label: "NO COMPANY", value: 0 }, ...options];
    } else {
      let firstEl = options.filter((el) => el.value == companyId);
      options.push({ label: "NO COMPANY", value: 1 });
      options.unshift(...firstEl);
      return options.filter((el, idx) => options.indexOf(el) === idx);
    }
  };

  return (
    <div className="card mt-4 mb-3 select-company-col">
      <h4 className="text-sm">Company</h4>
      <div>
        <Select
          disabled={disabled}
          onChange={onChange}
          defaultValue={companyId ? companyId : -1}
          name="companyId"
          className={"mt-2 w-full text-sm"}
          options={getOptions()}
        />
      </div>
    </div>
  );
};

export default CompanySelect;
