import { ISpecialIngredient } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const SPECIAL_INGREDIENT: string = `${API_URL}/Taxonomy/SpecialIngredient`;

export const getSpecialIngredients = (pieceId: string) => http.get(`${SPECIAL_INGREDIENT}/functionalUnit/${pieceId}`);

export const updateSpecialIngredients = (pieceId: string, data: ISpecialIngredient) => http.put(`${SPECIAL_INGREDIENT}/functionalUnit/${pieceId}`, {data});

export const deleteSpecialIngredients = (pieceId: string) => http.delete(`${SPECIAL_INGREDIENT}/${pieceId}`);

export const createSpecialIngredients = (data) => http.post(`${SPECIAL_INGREDIENT}/functionalUnit`, {data});

export const getSpecialIngredientsConfiguration = (pieceId: string) => http.get(`${SPECIAL_INGREDIENT}/functionalunit/configuration/${pieceId}`);

export const addSpecialIngredientCategory = (data) => http.post(`${SPECIAL_INGREDIENT}/category`, {data});

export const updateSpecialPath = (path, data) => http.put(`${SPECIAL_INGREDIENT}/${path}/${data.id}`, {data});

export const publishPath = (path, id) => http.post(`${SPECIAL_INGREDIENT}/${path}/publish/${id}`);
export const unpublishPath = (path, id) => http.delete(`${SPECIAL_INGREDIENT}/${path}/unpublish/${id}`);

export const getProcessedClass = () =>
  http.get(`${SPECIAL_INGREDIENT}/class`);
  
export const getProcessedDropdowns = (level: string) =>
  http.get(
    `${SPECIAL_INGREDIENT}/${level}`
  );
