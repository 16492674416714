import React from "react";
import { Checkbox, Icon, Label } from "@mondra/ui-components";
import { IAttributes, IGpcAttribute, IGpcBrick } from "../types";
import Collapsible from "react-collapsible";
import InputItem from "../../../../components/InputItem";

interface IAttributeSelector {
  brick: IGpcBrick;
  attributes: IAttributes[];
  gpcAttributes: IGpcAttribute[];
  setGpcAttributes: React.Dispatch<React.SetStateAction<IGpcAttribute[]>>;
}

export default function AttributeSelector({
  brick,
  attributes,
  gpcAttributes,
  setGpcAttributes
}: IAttributeSelector) {

  const onAttributeSelected = (id: number, name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.currentTarget.checked){
      setGpcAttributes(attributes => [...attributes, {
        gpcAttributeId: id,
        gpcAttributeName: name
      } as any])
    }else {
      var attributes = gpcAttributes.filter(a => a.gpcAttributeId !== id)
      setGpcAttributes(attributes)
    }
  }

  const onValueSelected = (id: number, i: number) => (e: React.ChangeEvent<Element>) => {
    var gpcAttr = [...gpcAttributes]
    var attribute = gpcAttr.find(a => a.gpcAttributeId === id)
    var value = attributes.find(a => a.id === id)?.values.find(v => v.valueId === +(e.target as any).value)
    var index = gpcAttr.findIndex(a => a.gpcAttributeId === id)
    gpcAttr[index] = {...attribute, 
      gpcValueId: value?.valueId || 0,
      gpcValueName: value?.valueName || ''} as any
    setGpcAttributes(gpcAttr)
  }

  return (
    <Collapsible
      className="w-full"
      openedClassName="w-full"
      trigger={
        <div className="collapsible_trigger w-full">
          <span>Select brick attributes and their values</span>
          <Icon className="collapsible_icon" type="chevronDown" />
        </div>
      }
    >
      {attributes?.map((component: IAttributes, index) => {
        return (
          <div className="piece-content-inputs mx-1">
            <div className="flex justify-start items-center gap-6">
              <Checkbox
                key={index + component.name + Math.random()}
                checked={
                  gpcAttributes?.filter(
                    (a) => a.gpcAttributeId === component.id
                  )?.length > 0
                }
                defaultChecked
                onChange={onAttributeSelected(component.id, component.name)}
                size={18}
              />
              <Label
                key={index + component.name + Math.random()}
                className={`${
                  gpcAttributes?.filter(
                    (a) => a.gpcAttributeId === component.id
                  )?.length > 0
                    ? ""
                    : "text-gray-400"
                } w-72 flex-wrap`}
                title={component.name}
              >
                {component.name}
                {
                  brick.mainAttributeId &&
                  brick.mainAttributeId == component.id &&
                    <span className={"bg-primary-500 text-white rounded-2xl text-xs mx-1 px-2 py-1"}>default</span>
                }
              </Label>
            </div>
            <InputItem
              disabled={
                gpcAttributes?.filter((a) => a.gpcAttributeId === component.id)
                  ?.length === 0
              }
              defaultValue={
                gpcAttributes?.find((a) => a.gpcAttributeId === component.id)
                  ?.gpcValueId || -1
              }
              className="-mt-1"
              name=""
              description=""
              type="select"
              onChange={onValueSelected(component.id, index)}
              options={component.values.map((el) => ({
                label: el.valueName as string,
                value: el.valueId,
              }))}
            />
          </div>
        );
      })}
    </Collapsible>
  );
}
