import * as Yup from "yup";
import { transformNullToString } from "./../../../shared/transformStringValue";
import {
  transformNumberValue,
  transformNumberValueAllowZero,
} from "../../../shared/transformNumberValue";
import { testNumberWithDotLength } from "../../../shared/testNumberWithDotLength";

export const formSchema = Yup.object().shape({
  impactLossFactor: Yup.number()
    .transform(transformNumberValue)
    .typeError("Impact Loss Factor is required")
    .required("Impact Loss Factor is required")
    .positive("Field value should be greater than 0!")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  regionIdentifierId: Yup.number()
    .typeError("Please select region")
    .min(1, "Please select region")
    .required()
    .integer(),
  shelfLife: Yup.number()
    .typeError("Please select shelf life")
    .min(0, "Please select shelf life")
    .required()
    .integer(),
  channel: Yup.number()
    .typeError("Please select channel")
    .min(0, "Please select channel")
    .required()
    .integer(),
  temperatureConditions: Yup.number()
    .typeError("Please select temperature conditions")
    .min(0, "Please select temperature conditions")
    .required()
    .integer(),
  countryId: Yup.number()
    .typeError("Please select country")
    .integer()
    .nullable(true),
  comment: Yup.string().transform(transformNullToString).nullable(true),
  referenceProducts: Yup.string().max(50, "Max 50 characters").nullable(true),
  secondaryDataReferenceSourceType: Yup.number()
    .typeError("Please select sources")
    .min(0, "Please select sources")
    .required()
    .integer(),
  carbon: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Carbon is required")
    .required("Carbon is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  waterUsage: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Water usage is required")
    .required("Water usage is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  eutrophication: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Eutrophication is required")
    .required("Eutrophication is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  referenceDataPublicationYear: Yup.number()
    .transform(transformNumberValue)
    .typeError("Reference data publication year field is required")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .required("Reference data publication year field is required"),
  referenceRegionIdentifier: Yup.number()
    .typeError("ReferenceRegionIdentifierId is required")
    .min(1, "Please select region")
    .required("ReferenceRegionIdentifierId is required")
    .integer(),
  referencesSamplingYear: Yup.number()
    .typeError("Sampling End Date must be numbers")
    .min(1900, "Year can`t be less than 1900")
    .max(2200, "Year can`t be greater than 2200")
    .nullable(true),
});

export const rowData = [
  {
    category: "Ambient - short shelf-life",
    shelfLife: "> 01 day & < 07 days",
    optimalStorageTemperature: "+10 C",
    definition:
      "This category includes all the food products (For Ex: Fruit pies, Cake, Bread rolls etc.,) stored at +10 C between the shelf-life range.",
  },
  {
    category: "Ambient - medium shelf-life",
    shelfLife: "> 07 day & <30 days",
    optimalStorageTemperature: "+10 C",
    definition:
      "This category includes all the food products (For Ex: Fruit cake, whole wheat, onions, Potatoes etc.,) stored at +10 C between the shelf-life range.",
  },
  {
    category: "Ambient - high shelf-life",
    shelfLife: "> 30 days - <6months",
    optimalStorageTemperature: "> 30 days - <6months",
    definition: "> 30 days - <6months",
  },
  {
    category: "Frozen - short shelf-life",
    shelfLife: "0 Hrs to <1month",
    optimalStorageTemperature: "-12C to -15 C",
    definition:
      "This category includes all the food products (For Ex: unbaked bread rolls,Doughnut, tetrapack milk, Yogurt, cooked fish andshell fish, Raw sausage, Bacon, ham wole, Bananas, Grapes, Melons, Garlic, Parsley, Sandwiches,      etc.,) stored at -12 to -18 C between the shelflife range.",
  },
  {
    category: "Frozen - medium shelf-life",
    shelfLife: "> 1month - <6months",
    optimalStorageTemperature: "-12C to -18 C",
    definition:
      "This category includes all the food products (For Ex: Cookies, Margerine, Egg whites and yolks, Steaks and roasts, Berries, Juices, peach, pear, pinapple, Carrot, Broccoli, Peas, Mashed potato, Soups,   etc.,) stored at -12 to -18 degree C between the shelflife range.",
  },
  {
    category: "Chilled - short shelf-life",
    shelfLife: "0 - 48 Hrs",
    optimalStorageTemperature: "-1 C to 5 C",
    definition:
      "This category includes all the food products (For Ex: pies, Fatty fish , lobster, oyester, clams, mussels, scallops,  shrimp, Bratwurst, ground beef, Gravy/meat broths, duck and goose, chicken whole pieces, berries, corn , etc ) stored at -1 to 5 degree C between the shelflife range.",
  },
  {
    category: "Chilled - medium shelf-life",
    shelfLife: ">48 Hrs",
    optimalStorageTemperature: "-1 C to 7 C",
    definition:
      "This category includes all the food products (For Ex: Butter/buttermilk, cottage cheese, cream, dips, fresh pasteurized milk, eggs, casserolls, bacon, ham, apples, apricot, avocado, canned fruit, coconut, citrus fruits, raisins, asparagus, beans, beets, eggplant, cauliflower, lettuce, peas, tomatoes, soups, pizza, pickles, tofu, etc.,) stored at -1 to 5 degree C between the shelflife range",
  },
];
