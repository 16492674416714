import { AppDispatch } from "../../../redux/store";
import {
    getResourceSource,
    getResourceSourceConfiguration,
} from "./api";
import {
  resourceFailure,
  resourceRequest,
  resourceSuccess,
  configuration,
} from "../../../redux/resourcesSlice";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";

  export const fetchResources = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
    try {
      dispatch(resourceRequest());
      const {data: pieceData} = await getResourceSource(pieceId);
      dispatch(resourceSuccess(pieceData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      if (error.response.status == 400) {
        replace();
      }
      dispatch(resourceFailure(error.message));
    }
  };

  export const getConfiguration = (id:string) => async (dispatch: AppDispatch) => {
    try {
      const {data: configurationData} = await getResourceSourceConfiguration(id);
      dispatch(configuration(configurationData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      dispatch(resourceFailure(error.message));
    }
  };
