import { IEnum } from "../types/componentTypes";
import { LinkCellRenderer } from "../components/Table/LinkCellRenderer";

export function filterEnums<T>(tableSearch: string | null, vars: T, data: any) {
  let filteredEnums: any = [];
  tableSearch &&
    Object.entries(vars).forEach(([name, list]) => {
      const filteredArray: any = [];
      list.in.filter((el) =>
        data[name].enumValues.map((enumValue) => {
          const enumDescription = enumValue.description.toLowerCase();
          enumDescription.includes(tableSearch.toLowerCase()) &&
            enumValue.name === el &&
            filteredArray.push(enumValue.name);
        })
      );
      filteredEnums.push([name, { in: filteredArray }]);
    });
  return Object.fromEntries(filteredEnums);
}

export function getTableData(
  data: any,
  loading: boolean,
  setTableData: (variables?: any) => void,
  setColumn: (variables?: any) => void,
  setTotalCount: (variables: string) => void,
  setCursors: () => void,
  piece: string,
  pageId: string,
  headers: string,
  defaultColumns: any[] = [],
) {
  if (!loading) {
    let columns: any = [];
    let pieceEnums: [string, IEnum][] = Object.entries(data);
    let dataForPieces =
      data[pageId].totalCount > 0 &&
      data[pageId].edges.map((piece) => piece.node);
    let tableData =
      data[pageId].totalCount > 0
        ? dataForPieces.map((node) => {
            let newNode: [string, string][] = [];
            const props = ["faoDataProxy", "complexIngredientClassification"];
            var {
              hasProperty,
              updatedNode,
            }: { hasProperty: boolean; updatedNode: any } = getUpdatedNode(
              node,
              props
            );
            Object.entries(hasProperty ? updatedNode : node).forEach(
              ([key, value]) => {
                pieceEnums.map(
                  ([name, enums]) =>
                    name === key &&
                    enums.enumValues.map(
                      (enumObj) =>
                        value === enumObj.name &&
                        newNode.push([key, enumObj.description])
                    )
                );
              }
            );
            return Object.assign(
              {},
              hasProperty ? updatedNode : node,
              Object.fromEntries(newNode)
            );
          })
        : [];

    if (data[pageId].totalCount > 0) {
      for (const key in data[pageId].edges[0]?.node) {
        let header: string = "";
        data[headers]?.fields.forEach((piece) =>
          piece.name === key ? (header = piece.description) : null
        );
        var defaultItem = defaultColumns.find((dc) => dc.field === key);
        let column = {
          field: key,
          headerName: header,
          headerTooltip: header,
          sortable: true,
          resizable: true,
          ...defaultItem,
        };

        if (header === "Activity name") {
          column.sortable = false;
        } else if (
          header === "Code" ||
          key === "code" ||
          header === "Click Up Id" ||
          header === "Additional Supplementary Information"
        ) {
          column.cellRenderer = LinkCellRenderer;
          column.cellRendererParams = {
            dataKey: key,
            getUrl: (data) => {
              if (header === "Code" || key === "code") {
                return `${piece}?itemId=${data.id}`;
              } else if (header === "Click Up Id") {
                return data.clickUpId;
              } else if (header === "Additional Supplementary Information") {
                return data.additionalSupplementaryInformation;
              }
            },
          };
        } else if (key === "id") {
          column.minWidth = 100;
          column.maxWidth = 100;
        } else if (key === "isVerified" || key === "isTransportAirFreight") {
          column.valueFormatter = ({ value }) => Boolean(value) ? "Yes" : "No"
        }
        columns.push(column);
      }
    }
    columns.shift();
    setTableData(tableData);
    setColumn(data[pageId].totalCount > 0 ? columns : []);
    setTotalCount(data[pageId].totalCount);
    setCursors();
  }
}
function getUpdatedNode(node: any, props) {
  let hasProperty: boolean = false;
  let updatedNode = { ...node };
  props.forEach((prop: string) => {
    hasProperty = hasProperty || node.hasOwnProperty(prop);
    if (hasProperty) {
      updatedNode = {
        ...updatedNode,
        [prop]: updatedNode[prop] ? "Yes" : "No",
      };
    }
  });

  return { hasProperty, updatedNode };
}
