import { FC, useState } from "react";
import { FormDialog, Button } from "@mondra/ui-components";
import InputItem from "../../../../components/InputItem";
import { useFormik } from "formik";
import { filterUnknown, pieceComponentSchema } from "../utils";
import { addLocalPopUpNote } from "../../../../shared/noteFunctions";

interface ComponentFormProps {
  componentName: any[];
  code?: string | null;
  processes: any;
  material: any;
  addComponent: (variables?: any) => void;
}

const ComponentForm: FC<ComponentFormProps> = ({
  componentName,
  code = "",
  processes,
  material,
  addComponent,
}) => {
  const [openForm, setOpenForm] = useState(false);

  const initialValues = {
    componentName: -1,
    componentMass: "",
    materialId: 0,
    materialIdNotes: [],
    processingTypeIdNotes: [],
    materialComposition: "",
    processingTypeId: 0,
    recycledContent: "",
    recycledContentNotes: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: pieceComponentSchema,
    onSubmit: (values) => {
      values.componentName = +values.componentName;
      values.materialId = +values.materialId;
      values.processingTypeId = +values.processingTypeId;
      addComponent(values);
      setTimeout(() => setOpenForm(false), 500);
    },
  });

  const onCreateComponent = () => {
    setOpenForm(true);
    formik.setValues(initialValues);
    formik.setTouched({}, false);
  };

  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className="mt-5"
        onClick={onCreateComponent}
      >
        Create component
      </Button>
      <FormDialog
        title="Create component"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              name="Component name"
              description="Select your component name"
              type="select"
              formik={formik}
              defaultValue={formik.values.componentName}
              showAsterisk
              onChange={formik.handleChange}
              formikName="componentName"
              options={filterUnknown(
                componentName.map((el) => ({
                  label: el.description,
                  value: el.id,
                })),
                code
              )}
            />
            <InputItem
              name="Material"
              description="Select your component material"
              type="select"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.materialId}
              onChange={formik.handleChange}
              formikName="materialId"
              options={filterUnknown(
                material.map((el) => ({
                  label: el.processName,
                  value: el.id,
                })),
                code
              )}
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.materialIdNotes.length,
                  ...params,
                })
              }
              notes={formik.values.materialIdNotes || []}
            />
            <InputItem
              name="Processing type"
              description="Select your processing type"
              type="select"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={formik.values.processingTypeId}
              formikName="processingTypeId"
              options={filterUnknown(
                processes.map((el) => ({
                  label: el.processName,
                  value: el.id,
                })),
                code
              )}
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.processingTypeIdNotes.length,
                  ...params,
                })
              }
              notes={formik.values.processingTypeIdNotes || []}
            />
            <InputItem
              name="Component Mass (empty weight) "
              description="Mass of this component"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={formik.values.componentMass}
              formikName="componentMass"
            />
            <InputItem
              name="Material composition"
              description="Enter percentage"
              type="input"
              addonTitle="%"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={formik.values.materialComposition}
              formikName="materialComposition"
            />
            <InputItem
              name="Recycled content"
              description="Enter percentage"
              type="input"
              addonTitle="%"
              formik={formik}
              showAsterisk={true}
              onChange={formik.handleChange}
              defaultValue={formik.values.recycledContent}
              formikName="recycledContent"
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.recycledContentNotes.length,
                  ...params,
                })
              }
              notes={formik.values.recycledContentNotes || []}
            />
          </div>
        </form>
      </FormDialog>
    </>
  );
};

export default ComponentForm;
