import { AppDispatch } from "../../../redux/store";
import {
  getStorageActivity,
  getStorageActivityConfiguration,
} from "./api";
import {
  storageRequest,
  storageSuccess,
  storageFailure,
  configuration,
} from "../../../redux/storageSlice";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";

  export const fetchStorage = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
    try {
      dispatch(storageRequest());
      const {data: storageData} = await getStorageActivity(pieceId);
      dispatch(storageSuccess(storageData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      if (error.response.status == 400) {
        replace();
      }
      dispatch(storageFailure(error.message));
    }
  }

  export const getConfiguration = () => async (dispatch: AppDispatch) => {
    try {
      const {data: configurationData} = await getStorageActivityConfiguration();
      dispatch(configuration(configurationData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      dispatch(storageFailure(error.message));
    }
  }
