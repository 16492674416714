import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IResourcesSources,
  IConfiguration,
} from "../modules/resorces-sources/resource/types";

interface IState {
  resource: IResourcesSources;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    units: [],
    regions: [],
    resourcesSources: {},
    countries: [],
    secondaryDataReferenceSourceType: [],
    resourceNameForUpdate: null,
  },
  resource: {
    code: null,
    lastPublishedDate: "",
    researcherName: "",
    researcherEmail: "",
    state: 0,
    userId: null,
    userEmail: null,
    lastUpdatedName: null,
    updated: null,
    id: 0,
    resourceName: null,
    resourceSourceId: -1,
    countryId: null,
    regionIdentifierId: 0,
    referenceQuantity: 1,
    description: "",
    processName: null,
    comment: "",
    carbon: "",
    waterUsage: "",
    eutrophication: "",
    referenceRegionIdentifier: 0,
    secondaryDataReferenceSourceType: -1,
    dataQualityReliabilityScore: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: 0,
    dataQualityComment: "",
    referenceSourcingCountry: null,
    companyId: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  error: "",
};

const resourceSlice = createSlice({
  name: "resource",
  initialState,
  reducers: {
    resourceRequest(state) {
      state.loading = true;
    },
    resourceSuccess(state, action: PayloadAction<IResourcesSources>) {
      state.loading = false;
      state.resource = {
        ...action.payload,
        resourceName: "",
      };
    },
    resourceFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.resource.state = action.payload;
    },
    // addNote(state, action) {
    //   if (action.payload.index != undefined) {
    //     if (
    //       state.transport.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] == null
    //     ) {
    //       state.piece.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] = [];
    //     }
    //     state.piece.componentsDetails[action.payload.index][
    //       action.payload.key
    //     ].push(action.payload.value);
    //   } else {
    //     if (state.piece[action.payload.key] == null) {
    //       state.piece[action.payload.key] = [];
    //     }
    //     state.piece[action.payload.key].push(action.payload.value);
    //   }
    // },
    setDefaultResource: () => initialState,
  },
});

export default resourceSlice.reducer;
export const {
  resourceRequest,
  resourceSuccess,
  resourceFailure,
  handlePublishStatus,
  setDefaultResource,
  // addNote,
  configuration,
} = resourceSlice.actions;
