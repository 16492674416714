import React, { FC } from "react";
import { Pill } from "@mondra/ui-components";

interface IEFHeader {
  label?: string | null;
  isPublished?: boolean;
  datePublished?: string | null;
}

export const EFHeader: FC<IEFHeader> = ({
  label,
  isPublished,
  datePublished,
}) => {
  return (
    <div className="piece-header flex items-center mb-5">
      <h5>{label}</h5>
      <Pill
        text={!isPublished ? "Not Published" : "Published"}
        color={!isPublished ? "red1" : "green1"}
        className="min-w-fit"
      />
      {datePublished ? (
        <span className="text-sm font-medium">
          {`Last published date: ${new Date(datePublished).toLocaleString()}
          `}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default EFHeader;
