import { IOptionProps } from "../types/componentTypes";

export const sortDropdown = (
  array,
  position: number | boolean,
  key: string = ""
): IOptionProps[] => {
  let unsortedArr: IOptionProps[] = [];
  unsortedArr = array?.map((el) => ({
    label: el[key],
    value: el.id ?? el.resourceSourceId,
  }));
  let firstEl: IOptionProps[] = unsortedArr?.filter((defaultArr) => {
    return defaultArr.value == position;
  });
  unsortedArr?.unshift(...firstEl);
  return unsortedArr?.filter((el, idx) => unsortedArr.indexOf(el) === idx);
};

export const sortResourcesDropdown = (
  array,
  position: number | string | null,
): IOptionProps[] => {
  let unsortedArr: IOptionProps[] = [];
    unsortedArr = Object.keys(array).map((el: string) => {
      return {
        label: el,
        value: el,
      }
  });
  let firstEl: IOptionProps[] = unsortedArr.filter((defaultArr) => {
    return defaultArr.value == position;
  });
  unsortedArr.unshift(...firstEl);
  return unsortedArr.filter((el, idx) => unsortedArr.indexOf(el) === idx);
};

export const dec2bin = (dec) => (dec >>> 0).toString(2);


export const multipleObject = (array) => array.map(el => ({value: el.id, label: el.description}))

export const getDefaults = (str, array) => {
  let string = dec2bin(str)
  return string.split('').reverse().map((el, idx) =>  +el == 1 ? multipleObject(array)[idx] : null)
}

export const transformFormValues = (values: Record<string, any>) => {
  return Object.keys(values).reduce((acc: any, key: string) => {
    const val = values[key];
    if (['boolean', 'object'].includes(typeof val)) {
      return {
        ...acc,
        [key]: val
      }
    }
    if (['', -1, null].includes(val)) {
      return {
        ...acc,
        [key]: null,
      }
    }
    if (!isNaN(val)) {
      return {
        ...acc,
        [key]: Number(val),
      }
    }
    return {
      ...acc,
      [key]: val
    }
  }, {})
}