import React from "react";
import { Label } from "@mondra/ui-components";
import { IGpcAttribute } from "../types";

interface IAttributeDetails {
  attributes: IGpcAttribute[];
  brickName: string;
}

export default function AttributeDetails({
  attributes,
  brickName,
}: IAttributeDetails) {
  return (
    <div className="p-0 mt-6 w-full border border-gray-200 bg-gray-50">
      <div className="w-full bg-gray-100 h-10 p-2 border border-gray-200">
        {" "}
        {`Brick [ ${brickName} ] attributes and their value`}
      </div>
      {attributes?.map((att: IGpcAttribute) => {
        return (
          <div className="grid grid-cols-3 mx-4 my-2 border border-gray-200 bg-gray-100 px-4">
            <Label key={att.gpcAttributeId} title={att.gpcAttributeName}>
              {att.gpcAttributeName}
            </Label>
            <Label
              className="col-span-2 text-gray-600"
              key={att.gpcValueId}
              title={att.gpcValueName}
            >
              {`(${att.gpcValueName || ''})`}
            </Label>
          </div>
        );
      })}
    </div>
  );
}
