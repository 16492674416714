import { ISalesActivity } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const SALES_ACTIVITY_URL: string = `${API_URL}/SalesActivity`;

export const getSalesActivity = (pieceId: string) => http.get(`${SALES_ACTIVITY_URL}/${pieceId}`);

export const updateSalesActivity = (pieceId: string, data: ISalesActivity) => http.put(`${SALES_ACTIVITY_URL}/${pieceId}`, {data});

export const publishSalesActivity = (pieceId: string) => http.post(`${SALES_ACTIVITY_URL}/publish/${pieceId}`);

export const unPublishSalesActivity = (pieceId: string) => http.delete(`${SALES_ACTIVITY_URL}/unpublish/${pieceId}`);

export const deleteSalesActivity = (pieceId: string) => http.delete(`${SALES_ACTIVITY_URL}/${pieceId}`);

export const createSalesActivity = (data) => http.post(`${SALES_ACTIVITY_URL}`, {data});

export const getSalesActivityConfiguration = () => http.get(`${SALES_ACTIVITY_URL}/configuration`);
