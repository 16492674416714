import React, { FC } from "react";

import InputItem from "../../../components/InputItem";
import { getFLAGCarbonValue } from "./utils";
import { ITabBaseProps } from "./types";

export const Emissions: FC<ITabBaseProps> = ({ isCustomEF, formik }) => {
  const flagNetCarbon = getFLAGCarbonValue(formik.values);

  return (
    <div className="piece-content-inputs mb-5">
      <InputItem
        disabled={isCustomEF.current}
        name="Original Net Carbon Emissions"
        description="Please provide carbon emissions in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="carbon"
        showAsterisk
        formik={formik}
        defaultValue={formik.values.carbon === 0 ? "0" : formik.values.carbon}
      />
      <InputItem
        disabled
        name="FLAG Net Carbon Emissions"
        description="FLAG carbon emissions in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        defaultValue={flagNetCarbon}
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Total Land Use Change Emissions"
        description="Please provide emissions in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="flag_L1_LandUseChangeCarbon"
        formik={formik}
        defaultValue={
          formik.values.flag_L1_LandUseChangeCarbon === 0
            ? "0"
            : formik.values.flag_L1_LandUseChangeCarbon
        }
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Total Land Management, CO2 Emissions"
        description="Please provide carbon emissions in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="flag_L1_LandManagementCarbon"
        formik={formik}
        defaultValue={
          formik.values.flag_L1_LandManagementCarbon === 0
            ? "0"
            : formik.values.flag_L1_LandManagementCarbon
        }
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Total Land Management, Non-CO2 Emissions"
        description="Please provide emissions in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="flag_L1_LandManagementNonCarbon"
        formik={formik}
        defaultValue={
          formik.values.flag_L1_LandManagementNonCarbon === 0
            ? "0"
            : formik.values.flag_L1_LandManagementNonCarbon
        }
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Total Non-land Emissions"
        description="Please provide emissions in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="flag_L1_NonLandCarbon"
        formik={formik}
        defaultValue={
          formik.values.flag_L1_NonLandCarbon === 0
            ? "0"
            : formik.values.flag_L1_NonLandCarbon
        }
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Total Unverified Removals"
        description="Please provide emissions removed in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="flag_L1_UnverifiedRemovalsCarbon"
        showAsterisk
        formik={formik}
        defaultValue={
          formik.values.flag_L1_UnverifiedRemovalsCarbon === 0
            ? "0"
            : formik.values.flag_L1_UnverifiedRemovalsCarbon
        }
      />
      <InputItem
        disabled={isCustomEF.current}
        name="Total Verified Removals"
        description="Please provide emissions removed in g CO2 eq."
        type="input"
        addonTitle="g"
        onChange={formik.handleChange}
        formikName="flag_L1_VerifiedRemovalsCarbon"
        showAsterisk
        formik={formik}
        defaultValue={
          formik.values.flag_L1_VerifiedRemovalsCarbon === 0
            ? "0"
            : formik.values.flag_L1_VerifiedRemovalsCarbon
        }
      />
    </div>
  );
};

export default Emissions;
