import { gql } from "@apollo/client";

export const GET_ALL_RESOURCES = gql`
  query AllPieces(
    $str: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ResourceSortInput!]
  ) {
    unit: __type(name: "UnitEnum") {
      name
      enumValues {
        name
        description
      }
    }
    resource: __type(name: "Resource") {
      fields {
        name
        description
      }
    }
    resources(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { name: { contains: $str } }
          { resourceId: { contains: $str } }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          resourceId
          name
          unit
          created
        }
        cursor
      }
    }
  }
`;
