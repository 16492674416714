import {
  ISalesActivity,
  IConfiguration,
} from "./../modules/sales-activity/sale/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IState {
  sale: ISalesActivity;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    regions: [],
    countries: [],
    temperatureConditions: [],
    shelfLife: [],
    channel: [],
    secondaryDataReferenceSourceType: [],
  },
  sale: {
    code: null,
    userId: null,
    userEmail: null,
    lastUpdatedName: null,
    companyId: null,
    lastPublishedDate: "",
    researcherName: "",
    researcherEmail: "",
    updated: null,
    state: 0,
    id: 0,
    channel: -1,
    countryId: null,
    regionIdentifierId: -1,
    referenceProducts: null,
    temperatureConditions: -1,
    impactLossFactor: null,
    shelfLife: -1,
    category: "",
    comment: "",
    carbon: "",
    waterUsage: "",
    eutrophication: "",
    referenceRegionIdentifier: -1,
    secondaryDataReferenceSourceType: -1,
    dataQualityReliabilityScore: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: null,
    dataQualityComment: "",
    referenceSourcingCountry: -1,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  error: "",
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    salesRequest(state) {
      state.loading = true;
    },
    salesSuccess(state, action: PayloadAction<ISalesActivity>) {
      state.loading = false;
      state.sale = action.payload;
    },
    salesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.sale.state = action.payload;
    },
    // addNote(state, action) {
    //   if (action.payload.index != undefined) {
    //     if (
    //       state.piece.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] == null
    //     ) {
    //       state.piece.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] = [];
    //     }
    //     state.piece.componentsDetails[action.payload.index][
    //       action.payload.key
    //     ].push(action.payload.value);
    //   } else {
    //     if (state.piece[action.payload.key] == null) {
    //       state.piece[action.payload.key] = [];
    //     }
    //     state.piece[action.payload.key].push(action.payload.value);
    //   }
    // },
    setDefaultSale: () => initialState,
  },
});

export default salesSlice.reducer;
export const {
  salesRequest,
  salesSuccess,
  salesFailure,
  handlePublishStatus,
  setDefaultSale,
  // addNote,
  configuration,
} = salesSlice.actions;
