import { FC } from "react";
import { Header as MainHeader, Logo } from "@mondra/ui-components";
import { NavLink } from "react-router-dom";

import { UserAvatarMenu } from "../UserAvatarMenu";

interface HeaderProps {
  name: string;
}

const Header: FC<HeaderProps> = ({ name }) => {
  return (
    <MainHeader
      className="dark"
      left={
        <NavLink to="/" className="focus-visible:outline-none">
          <Logo className="text-white" />
        </NavLink>
      }
      right={
        <div className="flex flex-row justify-end items-center gap-x-2">
          <UserAvatarMenu />
        </div>
      }
    />
  );
};

export default Header;
