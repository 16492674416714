import { IProcessedIngredient } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const PROCESSED_INGREDIENT: string = `${API_URL}/Taxonomy/ProcessedIngredient`;
const PRODUCE_INGREDIENT: string = `${API_URL}/Taxonomy/Produce`;
const PRODUCT: string = `${API_URL}/Taxonomy/Product`;

export const getProcessedIngredients = (pieceId: string) => http.get(`${PROCESSED_INGREDIENT}/functionalUnit/${pieceId}`);

export const updateProcessedIngredients = (pieceId: string, data: IProcessedIngredient) => http.put(`${PROCESSED_INGREDIENT}/functionalUnit/${pieceId}`, {data});

export const deleteProcessedIngredients = (pieceId: string) => http.delete(`${PROCESSED_INGREDIENT}/${pieceId}`);

export const createProcessedIngredients = (data) => http.post(`${PROCESSED_INGREDIENT}/functionalUnit`, {data});

export const getProcessedIngredientsConfiguration = (pieceId: string) => http.get(`${PROCESSED_INGREDIENT}/functionalunit/configuration/${pieceId}`);

export const addProcessedCategory = (array, data) => http.post(`${PROCESSED_INGREDIENT}/${array}`, {data});

export const updateProcessedPath = (path, data) => http.put(`${PROCESSED_INGREDIENT}/${path}/${data.id}`, {data});

export const publishPath = (path, id) => http.post(`${PROCESSED_INGREDIENT}/${path}/publish/${id}`);
export const unpublishPath = (path, id) => http.delete(`${PROCESSED_INGREDIENT}/${path}/unpublish/${id}`);

export const getProcessedClass = () => http.get(`${API_URL}/Taxonomy/ProcessedIngredient/class`);

export const getProduceClass = () => http.get(`${API_URL}/Taxonomy/Produce/class`);

export const getProcessedDropdowns = (level: string) => http.get(`${PROCESSED_INGREDIENT}/${level}` );

export const getProduceDropdowns = (level: string) => http.get(`${PRODUCE_INGREDIENT}/${level}`);

export const getFAOItems = (faoType: number | undefined, query: string) => http.get(`${API_URL}/FAO?type=${faoType}&query=${query}&limit=200`)