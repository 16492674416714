
export const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

export const ROUTES = {
    dashboard: '/',
    notFound: '/not-found',
    packaging: '/package-pieces',
    piece: '/package-pieces/piece',
    transportActivity: '/transport-activity',
    transport: '/transport-activity/transport',
    saleActivity: '/sale-activity',
    sale: '/sale-activity/sale',
    resourcesSources: '/resources-and-sources',
    resourcesSourcesReadonly: '/resources-sources-readonly',
    resource: '/resources-and-sources/resource',
    storageActivity: '/storage-activity',
    storage: '/storage-activity/storage',
    processingActivity: '/processing-activity',
    processing: '/processing-activity/process',
    emissionFactors: '/emission-factors',
    emission: '/emission-factors/factor',
    processedIngredients: '/processed-ingredients',
    processedIngredient: '/processed-ingredients/ingredient',
    specialIngredients: '/special-ingredients',
    specialIngredient: '/special-ingredients/ingredient',
    produce: '/produce',
    product: '/product',
    productGpc: '/product/gpc',
    produceIngredient: '/produce/ingredient',
    waterScarcity: '/water-scarcity',
    resources: '/resources',
    sources: '/sources',
};

export enum ActivityTypes {
    EmissionFactors = "EmissionFactors",
    PackagingPiece = "PackagingPiece",
    ProcessingActivity = "ProcessingActivity",
    ProcessedIngredient = "Taxonomy/ProcessedIngredient/functionalUnit",
    ProduceIngredient = "Taxonomy/Produce/functionalUnit",
    ProductGPC = "Taxonomy/Product/brick",
    ResourceSources = "ResourceSourceImpacts",
    SalesActivity = "SalesActivity",
    SpecialIngredient = "Taxonomy/SpecialIngredient/functionalUnit",
    StorageActivity = "StorageActivity",
    TransportActivity = "TransportActivity",
}
