import { gql } from "@apollo/client";

export const GET_ALL_SALES = gql`
  query AllPieces(
    $str: String
    $channel: NullableOfChannelEnumOperationFilterInput
    $temperatureConditions: NullableOfTemperatureConditionsEnumOperationFilterInput
    $shelfLife: NullableOfShelfLifeEnumOperationFilterInput
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $state: StateEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [SalesActivitiesGraphQlSortInput!]
  ) {
    channel: __type(name: "ChannelEnum") {
      name
      enumValues {
        name
        description
      }
    }
    temperatureConditions: __type(name: "TemperatureConditionsEnum") {
      name
      enumValues {
        name
        description
      }
    }
    shelfLife: __type(name: "ShelfLifeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }

    salesActivity: __type(name: "SalesActivitiesGraphQl") {
      fields {
        name
        description
      }
    }

    salesActivities(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { researcherName: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { code: { contains: $str } }
          { channel: $channel }
          { shelfLife: $shelfLife }
          { regionIdentifier: { contains: $str } }
          { temperatureConditions: $temperatureConditions }
          {
            secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType
          }
          { state: $state }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          state
          channel
          countryName
          regionIdentifier
          referenceProducts
          category
          carbon
          waterUsage
          eutrophication
          comment
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          referenceDataPublicationYear
          referenceSourcingCountry
          referenceRegionIdentifier
          dataQualityComment
          lastPublishedDate
          researcherName
          isVerified
          verifiedOn
          updated
          lastUpdatedName
          # countryId
          # comment
          # isDeleted
          # userEmail
          # userId
          # companyId
        }
        cursor
      }
    }
  }
`;
