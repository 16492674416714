import { IEmissionFactors } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const EMISSION_FACTORS_URL: string = `${API_URL}/EmissionFactors`;

export const getEmissionFactors = (pieceId: string) => http.get(`${EMISSION_FACTORS_URL}/${pieceId}`);

export const updateEmissionFactors = (pieceId: string, data: IEmissionFactors) => http.put(`${EMISSION_FACTORS_URL}/${pieceId}`, {data});

export const publishEmissionFactors = (pieceId: string) => http.post(`${EMISSION_FACTORS_URL}/publish/${pieceId}`);

export const unPublishEmissionFactors = (pieceId: string) => http.delete(`${EMISSION_FACTORS_URL}/unpublish/${pieceId}`);

export const deleteEmissionFactors = (pieceId: string) => http.delete(`${EMISSION_FACTORS_URL}/${pieceId}`);

export const createEmissionFactors = (data) => http.post(`${EMISSION_FACTORS_URL}`, {data});

export const getEmissionFactorsConfiguration = (id) => http.get(`${EMISSION_FACTORS_URL}/configuration/${id}`);
  
export const getProcessedDropdowns = (level: string) =>
  http.get(
    `${API_URL}/Taxonomy/Produce/published/${level}`
  );

export const getSpecialDropdowns = (level: string) =>
http.get(
  `${API_URL}/Taxonomy/SpecialIngredient/published/${level}`
);
