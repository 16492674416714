import React, { FC, useEffect, useState, EffectCallback, useMemo } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Icon,
  LottieLoader,
  Notification,
  ToggleSwitch,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchProduct, getConfiguration } from "./productActions";
import { formSchema } from "./utils";
import {
  updateBrick,
  createProduct,
  getProcessedDropdowns,
  getProcessedSegment,
  getProduct,
  publishPath,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";

import {
  handlePublishStatus,
  setDefaultProduct,
} from "../../../redux/productSlice";

import InputItem from "../../../components/InputItem";

import "./index.scss";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import { IAttributes, IBrickAttributeValue } from "./types";
import Collapsible from "react-collapsible";
import PackagingForm from "../../../components/Packaging/Packaging";
import { sortDropdown } from "../../../shared/formFunctions";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

interface Dropdown {
  id: number;
  code: string;
  name: string;
  state: number;
}

const Product: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, product, configuration } = useAppSelector(
    (state) => state.productSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);

  const [segments, setSegments] = useState<[] | Dropdown[]>([]);
  const [families, setFamilies] = useState<[] | Dropdown[]>([]);
  const [classesList, setClassesList] = useState<[] | Dropdown[]>([]);
  const [bricks, setBricks] = useState<[] | Dropdown[]>([]);
  const [brickPackaging, setBrickPackaging] = useState<any>({});

  let productId = searchParams.get("itemId") || "";

  useEffect((): ReturnType<EffectCallback> => {
    if (productId == "") {
      navigate("/not-found");
    }
    if (productId === "new") {
      dispatch(getConfiguration("0"));
      getProcessedSegment().then((res) => setSegments(res.data));
    }
    if (productId != "new") {
      getProduct(productId).then((res) =>
        setBrickPackaging({
          id: res.data.id,
          name: res.data.name,
          classId: res.data.classId,
          packagingPieces: res.data.packagingPieces?.sort(
            (a, b) => a.order - b.order
          ),
          mainAttributeId: res.data.mainAttributeId,
          transportCondition: res.data.transportCondition,
          lastUpdatedName: res.data.lastUpdatedName,
          researcherName: res.data.researcherName,
          approvedForRelease: res.data.approvedForRelease,
          minCarbonAllowed: res.data.minCarbonAllowed,
          maxCarbonAllowed: res.data.maxCarbonAllowed,
          attributes: res.data.attributes,
          secondaryDataReferenceSourceType:
            res.data.secondaryDataReferenceSourceType,
          referenceAuthorName: res.data.referenceAuthorName,
          referenceLink: res.data.referenceLink,
          storageReferenceLink: res.data.storageReferenceLink,
          referencesSamplingYear: res.data.referencesSamplingYear,
          referenceDataPublicationYear: res.data.referenceDataPublicationYear,
          referenceSourcingCountry: res.data.referenceSourcingCountry,
          dataQualityComment: res.data.dataQualityComment,
        })
      );
      dispatch(getConfiguration(productId));
      dispatch(fetchProduct(productId, () => navigate("/product/gpc")));
    }
    return () => {
      dispatch(setDefaultProduct());
    };
  }, []);

  useEffect(() => {
    formik.setValues({ ...product });
  }, [product]);

  const addProcess = (values, array) => {
    setBrickPackaging((p) => ({ ...p, [array]: values }));
  };

  const deleteProcess = (idx, array) => {
    const newComponents = brickPackaging[array].filter(
      (el, index) => index != +idx
    );
    setBrickPackaging((p) => ({ ...p, [array]: newComponents }));
  };

  const onPieceChange = (order: number) => (e) => {
    var value = +(e.target as any).value;
    var packPiece = configuration.packagingPieces.find((el) => value === el.id);
    var p = [...brickPackaging.packagingPieces];
    p[order] = {
      id: brickPackaging.packagingPieces[order]?.id,
      packagingPieceId:
        packPiece === undefined || packPiece === null ? 0 : packPiece?.id,
      packagingType:
        (packPiece === undefined || packPiece === null) && order !== 0
          ? 1
          : packPiece?.packagingType,
      order,
    } as any;
    setBrickPackaging((b) => ({ ...b, packagingPieces: p }));
  };

  const handleChangeItemsNumber = (index: number) => (event: any) => {
    var parsed = parseInt(event.target.value);
    var itemsNumber = isNaN(parsed) ? undefined : parsed;
    const updatedPieces = brickPackaging.packagingPieces.map((piece) => {
      if (piece.order === index) {
        return {
          ...piece,
          itemsNumber: itemsNumber,
        };
      }
      return piece;
    });
    setBrickPackaging((b) => ({ ...b, packagingPieces: updatedPieces }));
  };

  const isItemsNumberValid = (index: number) => {
    return (
      brickPackaging.packagingPieces[index]?.itemsNumber !== undefined &&
      (brickPackaging.packagingPieces[index]?.itemsNumber ?? 0) > 0
    );
  };

  const onAdd = () => {
    setBrickPackaging((b) => ({
      ...b,
      packagingPieces: [
        ...b.packagingPieces,
        {
          packagingPieceId: 0,
          packagingType: 1,
          order: b.packagingPieces.length,
          itemsNumber: null,
        } as any,
      ],
    }));
  };

  function canSaveForm(formik) {
    return (
      formik.values.name === "" ||
      formik.isSubmitting ||
      (brickPackaging !== undefined &&
        brickPackaging !== null &&
        brickPackaging.packagingPieces !== undefined &&
        brickPackaging.packagingPieces !== null &&
        brickPackaging.packagingPieces.filter(
          (f) =>
            f.packagingType === 1 &&
            (f.itemsNumber === undefined ||
              f.itemsNumber === null ||
              f.itemsNumber === 0 ||
              !configuration.packagingPieces.some(
                (el) => el.id === f.packagingPieceId
              ))
        ).length !== 0)
    );
  }

  const handleNullNumberChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value === "" ? null : value);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    let pasteData = e.clipboardData.getData("text");
    const textarea = e.target;
    const pasteText = pasteData.replace(/(\r\n|\n|\r)/gm, ", ");
    const currentText = textarea.value.trim();
    const currentArray = splitAndTrim(currentText);
    const pasteArray = splitAndTrim(pasteText);
    const uniqueArray = appendUnique(currentArray, pasteArray);
    formik.setFieldValue(textarea.name, uniqueArray.join(","));
  };

  const splitAndTrim = (text: string) =>
    text
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item);
  const appendUnique = (arr1: string[], arr2: string[]) =>
    arr1.concat(arr2.filter((item) => !arr1.includes(item)));

  const handleToggle = (checked: boolean) => {
    setBrickPackaging((b) => ({ ...b, approvedForRelease: checked }));
  };

  const formik = useFormik({
    initialValues: {
      ...product,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (product.state === 0) {
        publishPath("brick", productId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data.title));
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const { values } = formik;
    values.transportCondition =
      values.transportCondition != null ? +values.transportCondition : null;
    values.storageFoodCategory =
      values.storageFoodCategory != null ? +values.storageFoodCategory : null;
    values.secondaryDataReferenceSourceType =
      values.secondaryDataReferenceSourceType != null
        ? +values.secondaryDataReferenceSourceType
        : null;
    values.referenceSourcingCountry =
      values.referenceSourcingCountry != null
        ? +values.referenceSourcingCountry
        : null;
    values.referenceDataPublicationYear =
      values.referenceDataPublicationYear != null
        ? +values.referenceDataPublicationYear
        : null;
    values.referencesSamplingYear =
      values.referencesSamplingYear != null
        ? +values.referencesSamplingYear
        : null;

    values.mainAttributeId =
      !values.mainAttributeId && values.mainAttributeId !== 0
        ? null
        : +values.mainAttributeId;

    if (productId === "new") {
      try {
        await createProduct(values).then(() => {
          navigate("/product/gpc");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateBrick(productId, {
          ...brickPackaging,
          mainAttributeId: values.mainAttributeId,
          attributes: values.attributes,
          transportCondition: values.transportCondition,
          storageFoodCategory: values.storageFoodCategory,
          storageDurationInHours: values.storageDurationInHours,
          minCarbonAllowed: values.minCarbonAllowed,
          maxCarbonAllowed: values.maxCarbonAllowed,
          isVerified: values.isVerified,
          verificationComments: values.verificationComments,
          verifiedByEmail: values.verifiedByEmail,
          verifiedByName: values.verifiedByName,
          verifiedOn: values.verifiedOn,
          secondaryDataReferenceSourceType:
            values.secondaryDataReferenceSourceType,
          referenceAuthorName: values.referenceAuthorName,
          referenceLink: values.referenceLink,
          storageReferenceLink: values.storageReferenceLink,
          referencesSamplingYear: values.referencesSamplingYear,
          referenceDataPublicationYear: values.referenceDataPublicationYear,
          referenceSourcingCountry: values.referenceSourcingCountry,
          dataQualityComment: values.dataQualityComment,
        })
          .then(() =>
            dispatch(fetchProduct(productId, () => navigate("/product/gpc")))
          )
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  };

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    productId !== "new"
      ? JSON.stringify(product) != JSON.stringify(formik.values)
      : false
  );
  const consumerPiece = useMemo(
    () => configuration.packagingPieces.filter((pp) => pp.packagingType == 0),
    [configuration]
  );
  const distributionPiece = useMemo(
    () => configuration.packagingPieces.filter((pp) => pp.packagingType == 1),
    [configuration]
  );

  return (productId === "new" && segments.length != 0) ||
    loading !== true ||
    productId === "new" ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/product",
                title: "Product Table",
              },
              {
                path: `/product/gpc?itemId=${productId}`,
                title: "Product",
              },
            ]}
          />
        </div>
        {productId !== "new" && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  product.state === 0 ? "bg-red-500" : "bg-emerald-500"
                }`}
              >
                {product.state === 0 ? "Not Published" : "Published"}
              </div>
              {product?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    product.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        )}
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5"}>Product (GPC)</h5>
          <br />
          <div className="inputItem-header">
            <div className="flex items-center gap-x-2">
              Is approved for release
              <ToggleSwitch
                checked={brickPackaging.approvedForRelease}
                onChange={handleToggle}
              />
            </div>
          </div>
          {productId === "new" ? (
            <div className="piece-content-inputs">
              <InputItem
                name="Segment"
                description=""
                type="select"
                create={true}
                formikName="segmentId"
                showAsterisk
                onChange={(e) => {
                  getProcessedDropdowns(
                    `family?segmentId=${(e.target as any).value}`
                  ).then((res) => setFamilies(res.data));
                }}
                options={segments.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
              <InputItem
                name="Family"
                description=""
                type="select"
                create={true}
                formikName="familyId"
                showAsterisk
                onChange={(e) => {
                  getProcessedDropdowns(
                    `class?familyId=${(e.target as any).value}`
                  ).then((res) => setClassesList(res.data));
                }}
                options={families.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
              <InputItem
                name="Class"
                description=""
                type="select"
                create={true}
                formikName="classId"
                showAsterisk
                onChange={(e) => {
                  getProcessedDropdowns(
                    `brick?classId=${(e.target as any).value}`
                  ).then((res) => setBricks(res.data));
                }}
                options={classesList.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
              <InputItem
                name="Brick"
                description=""
                type="select"
                create={true}
                formikName="brickId"
                showAsterisk
                onChange={(e) => {}}
                options={bricks.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
              />
            </div>
          ) : (
            <div className="piece-content-inputs">
              <InputItem
                name="Segment"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                disabled={true}
                readOnly={true}
                onChange={formik.handleChange}
                defaultValue={configuration.segment}
                formikName="segment"
              />
              <InputItem
                name="Family"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                disabled={true}
                readOnly={true}
                onChange={formik.handleChange}
                defaultValue={configuration.family}
                formikName="family"
              />
              <InputItem
                name="Class"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                disabled={true}
                readOnly={true}
                onChange={formik.handleChange}
                defaultValue={configuration.class}
                formikName="class"
              />
              <InputItem
                name="Brick"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                disabled={true}
                readOnly={true}
                onChange={formik.handleChange}
                defaultValue={configuration.brick}
                formikName="brick"
              />
              <InputItem
                name="Complex Ingredient Classification"
                description=""
                type="input"
                formik={formik}
                showAsterisk={true}
                disabled={true}
                readOnly={true}
                onChange={formik.handleChange}
                defaultValue={`${
                  formik.values.complexIngredientClassification ? "Yes" : "No"
                }`}
                formikName="brick"
              />
            </div>
          )}
          <div>
            <h5 className={"mt-5"}>Outliers detection</h5>
            <div className="piece-content-inputs">
              <InputItem
                name="Min. Carbon"
                description="Carbon emissions in g CO2 eq."
                type="input"
                addonTitle="g"
                formik={formik}
                onChange={handleNullNumberChange}
                defaultValue={formik.values.minCarbonAllowed}
                readOnly={false}
                formikName={"minCarbonAllowed"}
              />

              <InputItem
                name="Max. Carbon"
                description="Carbon emissions in g CO2 eq."
                type="input"
                addonTitle="g"
                formik={formik}
                onChange={handleNullNumberChange}
                defaultValue={formik.values.maxCarbonAllowed}
                readOnly={false}
                formikName={"maxCarbonAllowed"}
              />
            </div>
          </div>

          {formik.values.attributes && (
            <div>
              <h5 className={"mt-5"}>Attributes</h5>
              <InputItem
                name="Main Attribute"
                key={
                  formik.values.mainAttributeId
                    ? formik.values.mainAttributeId + Math.random()
                    : Math.random()
                }
                description=""
                type="select"
                defaultValue={
                  formik.values.mainAttributeId
                    ? formik.values.mainAttributeId
                    : -1
                }
                formik={formik}
                create={true}
                onChange={handleNullNumberChange}
                formikName="mainAttributeId"
                options={sortDropdown(formik.values.attributes, -1, "name")}
              />
            </div>
          )}
          {formik.values.attributes &&
            formik.values.attributes?.map(
              (component: IAttributes, indexAttribute: number) => {
                return (
                  <Collapsible
                    key={`collapsible-${component.id}`}
                    trigger={
                      <div className="collapsible_trigger">
                        <span>
                          {component.name}
                          {formik.values.mainAttributeId &&
                            formik.values.mainAttributeId == component.id && (
                              <span
                                className={
                                  "bg-primary-500 text-white rounded-2xl text-xs mx-1 px-2 py-1"
                                }
                              >
                                default
                              </span>
                            )}
                        </span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    {component.values &&
                      component.values?.map(
                        (componentValue: IBrickAttributeValue, index) => {
                          return (
                            <div>
                              <h5 className="mt-6">
                                {componentValue.valueName}{" "}
                                <small className="text-body-secondary">
                                  ({componentValue.valueCode})
                                </small>
                              </h5>

                              <div className="piece-content-inputs">
                                <InputItem
                                  className="inputItem"
                                  key={`minCarbonAllowed-${component.id}-${componentValue.id}`}
                                  formikName={`attributes.${indexAttribute}.values.${index}.minCarbonAllowed`}
                                  name="Min. Carbon"
                                  description="Carbon emissions in g CO2 eq."
                                  type="input"
                                  addonTitle="g"
                                  onChange={handleNullNumberChange}
                                  defaultValue={componentValue.minCarbonAllowed}
                                  readOnly={false}
                                />

                                <InputItem
                                  className="inputItem"
                                  key={`maxCarbonAllowed-${component.id}-${componentValue.id}`}
                                  formikName={`attributes.${indexAttribute}.values.${index}.maxCarbonAllowed`}
                                  name="Max. Carbon"
                                  description="Carbon emissions in g CO2 eq."
                                  type="input"
                                  addonTitle="g"
                                  onChange={handleNullNumberChange}
                                  defaultValue={componentValue.maxCarbonAllowed}
                                  readOnly={false}
                                />

                                <InputItem
                                  className="inputItem"
                                  key={`mainProcessedIngredientCodes-${component.id}-${componentValue.id}`}
                                  formikName={`attributes.${indexAttribute}.values.${index}.mainProcessedIngredientCodes`}
                                  name="Bowls"
                                  description="List of processed ingredients that represents the main ingredient of a product"
                                  type="text"
                                  onPaste={handlePaste}
                                  onChange={formik.handleChange}
                                  defaultValue={
                                    componentValue.mainProcessedIngredientCodes
                                  }
                                  readOnly={false}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                  </Collapsible>
                );
              }
            )}
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Packaging</h5>
            {brickPackaging.packagingPieces?.length === 0 && (
              <span className="text-rose-600">
                You should have at least one packaging piece
              </span>
            )}
            {brickPackaging.packagingPieces?.length > 0 && (
              <Collapsible
                trigger={
                  <div className="collapsible_trigger">
                    <span>
                      {
                        configuration.packagingPieces.find(
                          (p) =>
                            p.id ==
                            brickPackaging.packagingPieces[0]?.packagingPieceId
                        )?.displayName
                      }
                    </span>
                    <Icon className="collapsible_icon" type="chevronDown" />
                  </div>
                }
              >
                <div className="piece-content-inputs">
                  <InputItem
                    key={
                      brickPackaging.packagingPieces[0].packagingPieceId
                        ? brickPackaging.packagingPieces[0].packagingPieceId +
                          Math.random()
                        : Math.random()
                    }
                    name="Primary Packaging piece"
                    description=""
                    type="select"
                    formik={formik}
                    onChange={onPieceChange(0)}
                    defaultValue={
                      brickPackaging.packagingPieces[0]?.packagingPieceId
                    }
                    formikName={`packagingPieces[${0}].packagingPieceId`}
                    options={sortDropdown(consumerPiece, 0, "displayName")}
                  />
                  {brickPackaging.packagingPieces
                    .filter((f) => f.packagingType === 1)
                    ?.map((item, index) => (
                      <div className="w-full flex justify-start items-end gap-4">
                        <InputItem
                          key={
                            brickPackaging.packagingPieces[index + 1]
                              .packagingPieceId
                              ? brickPackaging.packagingPieces[index + 1]
                                  .packagingPieceId + Math.random()
                              : Math.random()
                          }
                          valid={
                            brickPackaging.packagingPieces[index + 1]
                              ?.packagingPieceId !== 0
                          }
                          name={
                            index === 0 ? "Distribution Packaging piece" : ""
                          }
                          description={
                            index === 0
                              ? "Please specify the distribution packaging piece of this entry"
                              : ""
                          }
                          type="select"
                          formik={formik}
                          create
                          onChange={onPieceChange(index + 1)}
                          defaultValue={
                            brickPackaging.packagingPieces[index + 1]
                              ?.packagingPieceId
                          }
                          formikName={`packagingPieces[${
                            index + 1
                          }].packagingPieceId`}
                          options={sortDropdown(
                            distributionPiece,
                            0,
                            "displayName"
                          )}
                          index={index}
                        />
                        <InputItem
                          name={index === 0 ? "Items Number" : ""}
                          description={
                            index === 0 ? "Number previous items" : ""
                          }
                          onChange={handleChangeItemsNumber(index + 1)}
                          type="input"
                          valid={isItemsNumberValid(index + 1)}
                          defaultValue={
                            brickPackaging.packagingPieces[index + 1]
                              ?.itemsNumber
                          }
                          index={index}
                        />
                        <Button
                          variant="secondary"
                          iconType="close"
                          isOnlyIcon
                          onClick={() =>
                            deleteProcess(index + 1, "packagingPieces")
                          }
                        />
                      </div>
                    ))}
                </div>
                <div className="w-full flex justify-end items-center mt-6 gap-4">
                  <Button
                    iconType="add"
                    onClick={onAdd}
                    disabled={
                      brickPackaging.packagingPieces.filter(
                        (f) => f.packagingType === 0
                      ).length === 0
                    }
                  >
                    Distribution packaging
                  </Button>
                  <Button
                    onClick={() => {
                      formik.setValues({
                        ...formik.values,
                        packagingPieces: [],
                      });
                      brickPackaging.packagingPieces = [];
                    }}
                    variant="danger"
                    iconType="close"
                  >
                    Delete
                  </Button>
                </div>
              </Collapsible>
            )}
            <div>
              <PackagingForm
                addProcess={addProcess}
                disabled={brickPackaging.packagingPieces?.length > 0}
                packagingPieces={configuration.packagingPieces}
              />
            </div>
          </div>
          <h5 className={"mt-5"}>Transport and Storage</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="Storage/Transport condition"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.transportCondition}
              onChange={handleNullNumberChange}
              formikName="transportCondition"
              options={sortDropdown(
                configuration.transportCondition,
                formik.values.transportCondition || -1,
                "description"
              )}
            />

            <InputItem
              name="Storage Density Type"
              description=""
              type="select"
              formik={formik}
              defaultValue={formik.values.storageFoodCategory}
              onChange={handleNullNumberChange}
              formikName="storageFoodCategory"
              options={sortDropdown(
                configuration.storageFoodCategory,
                formik.values.storageFoodCategory || -1,
                "description"
              )}
            />

            <InputItem
              name="Storage duration in hours"
              description=""
              type="input"
              addonTitle="h"
              onChange={handleNullNumberChange}
              formikName="storageDurationInHours"
              formik={formik}
              defaultValue={formik.values.storageDurationInHours}
            />
          </div>
          <h5 className={"mt-5"}>Storage References</h5>
          <h6>Duration</h6>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={handleNullNumberChange}
              formik={formik}
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                formik.values.secondaryDataReferenceSourceType ?? -1,
                "description"
              )}
            />
          </div>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Reference Author Name"
              description="Please indicate author of the referene"
              type="input"
              onChange={formik.handleChange}
              formikName="referenceAuthorName"
              formik={formik}
              defaultValue={formik.values.referenceAuthorName || ""}
            />
            <InputItem
              name="Reference Link"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="referenceLink"
              formik={formik}
              defaultValue={formik.values.referenceLink || ""}
            />
            <InputItem
              name="References Sampling Year"
              description="The year in which most of the data was collected. If this is a time frame please choose the end date. If the data was equally collected from multiple studies please select the sourcing year from the study published the furthest in the past."
              type="input"
              onChange={handleNullNumberChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={handleNullNumberChange}
              formik={formik}
              defaultValue={formik.values.referenceDataPublicationYear || ""}
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={handleNullNumberChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country which most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={formik.values.referenceSourcingCountry || -1}
              onChange={handleNullNumberChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          <h6>Food Category</h6>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Reference Link"
              description="Please list the references that were used to create this record."
              type="input"
              onChange={formik.handleChange}
              formikName="storageReferenceLink"
              formik={formik}
              defaultValue={formik.values.storageReferenceLink || ""}
            />
          </div>
          <div>{errors}</div>
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map((err) => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className="piece-actions">
            {
              //sessionStorage.getItem("permission") == "true" &&
              product.state === 0 && productId != "new" && (
                <>
                  <Button
                    variant="tertiary"
                    size="md"
                    type="submit"
                    disabled={canSaveForm(formik)}
                    className="w-32"
                  >
                    Publish
                  </Button>
                </>
              )
            }
            <Button
              variant="primary"
              size="md"
              className="w-32"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={productId}
        activityType={ActivityTypes.ProductGPC}
        auditCreatedBy={brickPackaging.researcherName || ""}
        auditLastUpdatedBy={brickPackaging.lastUpdatedName}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
        hideCompanyselect
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Product;
