import { FC, useState, useMemo } from "react";
import { FormDialog, Button } from "@mondra/ui-components";
import InputItem from "../InputItem";
import { sortDropdown } from "../../shared/formFunctions";


export interface IPackaging {
    code: null | string;
    id: number
    displayName: null | string;
    packagingType: number
    itemsNumber?: number
}

interface ComponentFormProps {
  addProcess: (variables?: any, array?: string) => void;
  packagingPieces: IPackaging[]
  disabled?: boolean
}

interface IPiece {
  id?: number,
  packagingPieceId: number,
  packagingType: number,
  order: number,
  itemsNumber?: number  
};

const PackagingForm: FC<ComponentFormProps> = ({
  addProcess,
  disabled = false,
  packagingPieces = []
}) => {
  const [openForm, setOpenForm] = useState(false);
  const [selectedPiece, setSelectedPiece] = useState<number>(-1)
  const [pieces, setPieces] = useState<[] | IPiece[]>([]);

  const onSubmit = () => {
    if(pieces.find(p=> p.packagingType === 1 && (p.packagingPieceId === 0 || p.itemsNumber === undefined || p.itemsNumber <=0))){
      return
    }
    addProcess(pieces, "packagingPieces");
    setTimeout(() => setOpenForm(false), 500);
  }

  const onPieceSelection = (order: number) => (e: any) => {
    var value = +(e.target as any).value;
    var p = [...pieces]
    var packPiece  = packagingPieces.find(
      (el) => value === el.id)      
    p[order] = {
      packagingPieceId : packPiece  === undefined || packPiece  === null ? 0 : packPiece?.id,
      packagingType : (packPiece  === undefined || packPiece  === null) && order !==0 ? 1 : packPiece?.packagingType,
      order
    } as any
    setSelectedPiece(value)
    setPieces(p)
  }

  const handleChangeItemsNumber = (index: number) => (event: any) => {
    var parsed = parseInt(event.target.value)
    var itemsNumber = isNaN(parsed)? undefined : parsed;
    const updatedPieces = pieces.map((piece) => {
      if (piece.order === index) {
        return {
          ...piece,
          itemsNumber: itemsNumber,
        };
      }
      return piece; 
    });
    setPieces(updatedPieces)
  };

  const isItemsNumberValid =  (index: number) => {
    return pieces[index]?.itemsNumber !== undefined &&  (pieces[index]?.itemsNumber ?? 0 )> 0;
  }

  const onAdd = () => {
    setPieces( p => ([...p, {
      packagingPieceId : 0,
      packagingType : 1,
      order: p.length,
      itemsNumber: null
    } as any]))
  }

  const onRemove = (index) => () => {
    const p = [...pieces]
    p.splice(index, 1)
    setPieces(p.map((p, i)=> ({...p, order: i})))
  }

  const onCreateComponent = () => {
    setOpenForm(true);
    setPieces([])
  };

  function canCreatePackaging() {
    return pieces.filter(f=> f.packagingType === 0).length === 0
      || pieces.filter(f=> f.packagingType === 1 && (f.itemsNumber === undefined || f.itemsNumber === null || f.itemsNumber === 0 || !packagingPieces.some((el) => el.id === f.packagingPieceId))).length !== 0;
  }

  const primaryPiece = useMemo(() => packagingPieces.filter(pp => pp.packagingType == 0), [packagingPieces])
  const distributionPiece = useMemo(() => packagingPieces.filter(pp => pp.packagingType == 1), [packagingPieces])
  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className={"mt-5"}
        onClick={onCreateComponent}
        disabled={disabled}
      >
        Create packaging
      </Button>
      <FormDialog
        title="Create Packaging"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        primaryBtnDisabled={canCreatePackaging()}
        onPrimaryButtonClick={onSubmit}
        open={openForm}
      >
        <form onSubmit={onSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              className="w-full"
              name="Primary Packaging piece"
              description="Please specify the primary packaging piece of this entry"
              type="select"
              showAsterisk
              defaultValue={selectedPiece}
              onChange={onPieceSelection(0)}
              options={sortDropdown(primaryPiece, 0, "displayName")}
            />
            {pieces.filter(f=> f.packagingType === 1)?.map((item, index)=><div className="w-full flex justify-between items-end gap-4">
              <InputItem
                key={
                  pieces[index+1].packagingPieceId
                    ? pieces[index+1]
                        .packagingPieceId + Math.random()
                    : Math.random()
                }
                disabled={pieces.filter(f=> f.packagingType === 0).length === 0}
                className="w-full"
                valid={pieces[index+1]?.packagingPieceId !== 0}
                name={index === 0 ? "Distribution Packaging piece" : ""}
                description={index === 0 ? "Please specify the distribution packaging piece of this entry" : "" }
                type="select"
                defaultValue={pieces[index+1]?.packagingPieceId}
                create
                showAsterisk ={index === 0}
                onChange={onPieceSelection(index + 1)}
                options={sortDropdown(distributionPiece, 0, "displayName")}
              />
              <InputItem
                  name={index === 0 ? "Items Number": ""}
                  description={index === 0 ? "Number previous items": ""}
                  disabled = {pieces.filter(f=> f.packagingType === 0).length === 0 || pieces[index+1]?.packagingPieceId === 0}
                  onChange={handleChangeItemsNumber(index+1)}
                  type="input"
                  defaultValue={pieces[index+1]?.itemsNumber}
                  valid={isItemsNumberValid(index+1)}
                />
              <Button variant="secondary" iconType="close" className="pr-1 mt-4" onClick={onRemove(index+1)}/>
            </div>)}
          </div>
          <div className="w-full flex justify-end items-center mt-6">
            <Button iconType="add" onClick={onAdd} disabled={pieces.filter(f=> f.packagingType === 0).length === 0}>
              Distribution packaging</Button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

export default PackagingForm;
