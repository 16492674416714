import { AppDispatch } from "../../../redux/store";
import {
  getProduct,
  getProductConfiguration,
} from "./api";
import {
  productRequest,
  productSuccess,
  productFailure,
  configuration,
} from "../../../redux/productSlice";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";

  export const fetchProduct = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
    try {
      dispatch(productRequest());
      const {data: pieceData} = await getProduct(pieceId);
      dispatch(productSuccess(pieceData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      if (error.response.status == 400) {
        replace();
      }
      dispatch(productFailure(error.message));
    }
  };

  export const getConfiguration = (pieceId: string) => async (dispatch: AppDispatch) => {
    try {
      const {data: configurationData} = await getProductConfiguration(pieceId);
      dispatch(configuration(configurationData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      dispatch(productFailure(error.message));
    }
  };
