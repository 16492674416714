import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  showCorrelationIdNotification: boolean;
  errorId: string;
}

const initialState: IState = {
  showCorrelationIdNotification: false,
  errorId: "",
};

const correlationIdSlice = createSlice({
  name: "correlationId",
  initialState,
  reducers: {
    showCorrelationIdError(state, action: PayloadAction<string>) {
      state.showCorrelationIdNotification = true;
      state.errorId = action.payload;
    },
    hideCorrelationIdError(state) {
      state.showCorrelationIdNotification = false;
      state.errorId = "";
    },
  },
});

export default correlationIdSlice.reducer;
export const { showCorrelationIdError, hideCorrelationIdError } = correlationIdSlice.actions;
