import {
  IProcessedIngredient,
  IConfiguration,
} from "./../modules/processed-ingredients/processed-ing/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IState {
  ingredient: IProcessedIngredient;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    path: {
      activity: '',
      activityId: 0,
      activityState: 0,
      activityCode: null,
      produceCategory: '',
      produceCategoryId: 0,
      produceCategoryState: 0,
      produceCategoryCode: null,
      class: '',
      classId: 0,
      classState: 0,
      classCode: null,
      produceSubcategory: '',
      produceSubcategoryId: 0,
      produceSubcategoryState: 0,
      produceSubcategoryCode: null,
      produceSubcategoryFAOProcessedTradeType: 0,
      produceSubcategoryFAOProcessedProductionType: 0,
      faoProductionId : null,
      faoProductionName: '',
      faoTradeId : null,
      faoTradeName : '',
    },
    packagingPieces: [],
    upstreamIngredientTypes: [],
    transportCondition: [],
    storageFoodCategory: [],
    faoSource: [],
    regions: [],
    countries: [],
    secondaryDataReferenceSourceType: []
  },
  ingredient: {
    id: 1,
    userEmail: "",
    lastUpdatedName: "",
    researcherName: "",
    researcherEmail: "",
    updated: "",
    state: 0,
    created: "",
    lastPublishedDate: null,
    functionalUnitAliases: "",
    upstreamIngredientType: -1,
    upstreamIngredientId: -1,
    ingredient: "",
    code: null,
    name: "",
    subclassId: -1,
    activityId: -1,
    categoryId: -1,
    packagingPieces: [],
    produceSubcategoryId: -1,
    transportCondition: -1,
    isTransportAirFreight: false,
    storageFoodCategory: -1,
    storageDurationInHours: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
    faoTradeId: null,
    faoTradeSource: -1,
    faoProductionId: null,
    faoProductionSource: -1,
    upstreamIngredientSubcategoryId: -1,
    secondaryDataReferenceSourceType: null,
    referenceAuthorName: null,
    referenceLink: null,
    storageReferenceLink: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear:  null,
    referenceSourcingCountry: null,
    dataQualityComment: null
  },
  loading: true,
  error: "",
};

const processedIngredientSlice = createSlice({
  name: "processedIngredient",
  initialState,
  reducers: {
    processedRequest(state) {
      state.loading = true;
    },
    processedSuccess(state, action: PayloadAction<IProcessedIngredient>) {
      state.loading = false;
      state.ingredient = action.payload;
    },
    processedFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.ingredient.state = action.payload;
    },
    // addNote(state, action) {
    //   if (action.payload.index != undefined) {
    //     if (
    //       state.piece.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] == null
    //     ) {
    //       state.piece.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] = [];
    //     }
    //     state.piece.componentsDetails[action.payload.index][
    //       action.payload.key
    //     ].push(action.payload.value);
    //   } else {
    //     if (state.piece[action.payload.key] == null) {
    //       state.piece[action.payload.key] = [];
    //     }
    //     state.piece[action.payload.key].push(action.payload.value);
    //   }
    // },
    setDefaultIngredient: () => initialState,
  },
});

export default processedIngredientSlice.reducer;
export const {
  processedRequest,
  processedSuccess,
  processedFailure,
  handlePublishStatus,
  setDefaultIngredient,
  configuration,
} = processedIngredientSlice.actions;
