import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { LottieLoader, Button } from "@mondra/ui-components";

import "./index.scss";

const NotFound: FC = () => {
    const navigate = useNavigate();
  return (
    <div className="not_found">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
      <h3>Oops! There is no such page :(</h3>
      <Button onClick={() => navigate("/")} iconType="home">Home</Button>
    </div>
  );
};

export default NotFound;
