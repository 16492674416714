import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPiece, IConfiguration } from "../modules/packaging-pieces/piece/types";
interface IState {
  piece: IPiece;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    regions: [],
    countries: [],
    materials: [],
    processes: [],
    componentName: [],
    packagingType: [],
    pieceGroup: [],
    typicalUses: [],
    secondaryDataReferenceSourceType: []
  },
  piece: {
    code: null,
    id: 0,
    length: null,
    width: null,
    height: null,
    maximumLoadingCapacity: null,
    maximumLoadingCapacityNotes: [],
    totalMassNotes: [],
    numberOfUses: null,
    numberOfComponents: 0,
    numberOfUsesNotes: [],
    packagingMaterialLoss: 0,
    packagingMaterialLossNotes: [],
    pieceGroup: -1,
    pieceName: "",
    clickUpId: "",
    sourcedFromRERRoW: 0,
    packagingType: -1,
    totalMass: null,
    typicalUses: -1,
    componentsDetails: [],
    comment: null,
    lastPublishedDate: null,
    researcherName: "",
    researcherEmail: "",
    lastUpdatedName: null,
    state: 0,
    carbon: "",
    waterUsage: "",
    eutrophication: "",
    secondaryDataReferenceSourceType: null,
    dataQualityReliabilityScore: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: null,
    dataQualityComment: null,
    referenceSourcingCountry: null,
    referenceRegionIdentifier: null,
    companyId: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  error: "",
};

const packagingSlice = createSlice({
  name: "packaging",
  initialState,
  reducers: {
    pieceRequest(state) {
      state.loading = true;
    },
    pieceSuccess(state, action: PayloadAction<IPiece>) {
      state.loading = false;
      state.piece = action.payload;
    },
    pieceFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.piece.state = action.payload;
    },
    addNote(state, action) {
      if (action.payload.index != undefined) {
        if (
          state.piece.componentsDetails[action.payload.index][
            action.payload.key
          ] == null
        ) {
          state.piece.componentsDetails[action.payload.index][
            action.payload.key
          ] = [];
        }
        state.piece.componentsDetails[action.payload.index][
          action.payload.key
        ].push(action.payload.value);
      } else {
        if (state.piece[action.payload.key] == null) {
          state.piece[action.payload.key] = [];
        }
        state.piece[action.payload.key].push(action.payload.value);
      }
    },
    setDefaultPiece: () => initialState,
    deleteComponent(state, action) {
      state.piece.componentsDetails = state.piece.componentsDetails.filter(
        (el, index) => index != +action.payload
      );
    },
  },
});

export default packagingSlice.reducer;
export const {
  pieceRequest,
  pieceSuccess,
  pieceFailure,
  handlePublishStatus,
  setDefaultPiece,
  addNote,
  configuration
} = packagingSlice.actions;
