import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanies } from "../types/componentTypes";

interface IState {
  companies: ICompanies[];
  loading: boolean;
  error: string;
}

const initialState: IState = {
  companies: [
    {
      id: "",
      zohoCrmAccountId: "",
      name: "",
      description:
        "",
      website: "",
      companyLogoUrl:
        "",
      verified: false,
      type: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      street: "",
      registrationNumber: null,
      requiresNda: false,
      ssoConnection: "",
      sharePointPath: "",
    },
  ],
  loading: true,
  error: "",
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    companiesRequest(state) {
      state.loading = true;
    },
    companiesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    companies(state, action: PayloadAction<ICompanies[]>) {
      state.loading = false;
      state.companies = action.payload;
    },
  },
});

export default companiesSlice.reducer;
export const { companiesRequest, companiesFailure, companies } = companiesSlice.actions;
