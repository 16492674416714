import { IPiece } from './types';
import http from "../../../http";
import { API_URL } from "../../../const";

const PACKAGING_PIECE_URL: string = `${API_URL}/packagingPiece`;

export const getPackagingPiece = (pieceId: string) => http.get(`${PACKAGING_PIECE_URL}/${pieceId}`);

export const updatePackagingPiece = (pieceId: string, data: IPiece) => http.put(`${PACKAGING_PIECE_URL}/${pieceId}`, {data});

export const publishPackagingPiece = (pieceId: string) => http.post(`${PACKAGING_PIECE_URL}/publish/${pieceId}`);

export const unPublishPackagingPiece = (pieceId: string) => http.delete(`${PACKAGING_PIECE_URL}/unpublish/${pieceId}`);

export const deletePackagingPiece = (pieceId: string) => http.delete(`${PACKAGING_PIECE_URL}/${pieceId}`);

export const createPackagingPiece = (data) => http.post(`${PACKAGING_PIECE_URL}`, {data});

export const getPackagingPieceConfiguration = () => http.get(`${PACKAGING_PIECE_URL}/configuration`);
