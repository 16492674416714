import * as Yup from 'yup';
import { transformNullToString } from '../../../shared/transformStringValue';
import { transformNumberValueAllowZero } from '../../../shared/transformNumberValue';
import { testNumberWithDotLength } from '../../../shared/testNumberWithDotLength';

export const formSchema = Yup.object().shape({
    functionalUnitProxy: Yup.string().transform(transformNullToString).nullable(true),
    name: Yup.string().transform(transformNullToString).required('This field is required'),
    secondaryDataReferenceSourceType: Yup.number().typeError('Field accepts numbers only').min(0, 'Please select sources').integer().nullable(true),
    referencesSamplingYear: Yup.number().typeError('Field accepts numbers only').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').nullable(true),
    referenceDataPublicationYear: Yup.number().typeError('Field accepts numbers only').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').nullable(true),
    referenceAuthorName: Yup.string().max(256, 'Reference Author Name field maximum length is 256 characters!').nullable(true),
    referenceLink: Yup.string().transform(val => val === null ? null : val.trim()).url('Reference link must be a valid URL like "https://mondra.com"').nullable(true),
    storageReferenceLink: Yup.string().transform(val => val === null ? null : val.trim()).url('Storage Reference link must be a valid URL like "https://mondra.com"').nullable(true),  
    dataQualityComment: Yup.string().max(512, 'Data Quality Comment field maximum length is 512 characters!').nullable(true),
    storageFoodCategory: Yup.number().nullable()
        .transform(transformNumberValueAllowZero).typeError('Field accepts numbers only')
        .test('storageFoodCategory', 'Validation error message', function (value) {
            const { storageDurationInHours } = this.parent;
            if (value != null && (storageDurationInHours == null)) {
                return this.createError({ path: 'storageDurationInHours', message: '"Storage duration in hours" is required if "Storage Density Type" is specified' });
            }
            return true;
        }),
    storageDurationInHours: Yup.number().nullable()
        .transform(transformNumberValueAllowZero).typeError('Field accepts numbers only')
        .test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength)
        .test('storageDurationInHours', 'Validation error message', function (value) {
            const { storageFoodCategory } = this.parent;
            if (value != null && (storageFoodCategory == null)) {
                return this.createError({ path: 'storageFoodCategory', message: '"Storage Density Type" is required if "Storage duration in hours" is specified' });
            }
            return true;
        })
});
