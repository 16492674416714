import { IBrick, IProduct } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const PRODUCT_BASE: string = `${API_URL}/Taxonomy/Product`;

export const getProduct = (pieceId: string) => http.get(`${PRODUCT_BASE}/brickWithChildren/${pieceId}`);

export const getBrick = (brickId: string) => http.get(`${PRODUCT_BASE}/brick/${brickId}`);

export const updateProduct = (pieceId: string, data: IProduct) => http.put(`${PRODUCT_BASE}/brick/${pieceId}`, {data});

export const updateBrick = (brickId: string, data: IBrick) => http.put(`${PRODUCT_BASE}/brick/${brickId}`, {data});

export const deleteProduct = (pieceId: string) => http.delete(`${PRODUCT_BASE}/${pieceId}`);

export const createProduct = (data) => http.post(`${PRODUCT_BASE}/brick`, {data});

export const getProductConfiguration = (pieceId: string) => http.get(`${PRODUCT_BASE}/brick/configuration/${pieceId}`);

export const addProductCategory = (array, data) => http.post(`${PRODUCT_BASE}/${array}`, {data});

export const updateProductPath = (path, data) => http.put(`${PRODUCT_BASE}/${path}/${data.id}`, {data});

export const publishPath = (path, id) => http.post(`${PRODUCT_BASE}/${path}/publish/${id}`);
export const unpublishPath = (path, id) => http.delete(`${PRODUCT_BASE}/${path}/unpublish/${id}`);


export const getProcessedSegment = () =>
  http.get(`${PRODUCT_BASE}/segment`);
  
export const getProcessedDropdowns = (level: string) =>
  http.get(
    `${PRODUCT_BASE}/${level}`
  );
