export function transformNumberValue(value: number, originalValue: number) {
  return !originalValue ? undefined : value;
}

export function transformNumberValueAllowZero(
  value: number,
  originalValue: number | string
) {
  return !originalValue && (originalValue === '' || originalValue === null) ? undefined : value;
}
