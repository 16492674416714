import * as Yup from "yup";
import {
  transformNumberValue,
  transformNumberValueAllowZero,
} from "../../../shared/transformNumberValue";
import { testNumberWithDotLength } from "../../../shared/testNumberWithDotLength";

export const ingredientSchema = Yup.object().shape({
  inputIngredientId: Yup.number().required("Ingredient is required"),
  quantity: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Quantity is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  correspondingProcess: Yup.string().nullable(true),
});

export const resourceSchema = Yup.object().shape({
  resourceSourceId: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Please select ResourceSourceId")
    .required("ResourceSourceId is required"),
  quantity: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Quantity is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  correspondingProcess: Yup.string().nullable(true),
});

export const productSchema = Yup.object().shape({
  outputProductId: Yup.number()
    .typeError("Field accepts numbers only")
    .min(1, "Please select name")
    .required("Product name is required"),
  quantity: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Quantity is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
  outputProductType: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .required("Product type is required"),
  economicAllocation: Yup.number()
    .typeError("Field accepts numbers only")
    .required("Economic allocation is required")
    .test(
      "percentage",
      "Max value should be less than 100%",
      (val = 0) => val <= 100
    ),
});

export const wasteSchema = Yup.object().shape({
  resourceSourceId: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .min(0, "Please select Waste")
    .required("Waste is required"),
  quantity: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Quantity is required")
    .test(
      "len",
      "Max symbols allowed - 32. Max symbols after dot - 16",
      testNumberWithDotLength
    ),
});

export const emissionToAirSchema = Yup.object().shape({
  resourceSourceId: Yup.number()
    .transform(transformNumberValue)
    .typeError("Field accepts numbers only")
    .min(0, "Please select Emission")
    .required("Emission is required"),
  quantity: Yup.number()
    .transform(transformNumberValueAllowZero)
    .typeError("Field accepts numbers only")
    .required("Quantity is required")
    .test(
      "len",
      "Max symbols allowed - 28. Max symbols after dot - 16",
      (value, config: any) => {
        const val = config.options.originalValue
          ? config.options.originalValue
          : 0;
        return (
          (!val.toString().includes(".") ||
            (val.toString().includes(".") &&
              val.toString().split(".")[1].length < 17)) &&
          val.toString().length < 29
        );
      }
    ),
});

export const formSchema = Yup.object().shape({
  processFlow: Yup.string().required("Process flow field is required!"),
  referenceTechnologyType: Yup.string().required(
    "Reference technology type field is required!"
  ),
  clickUpId: Yup.string()
    .transform((val) => (val === null ? null : val.trim()))
    .url('Click up ID must be a valid URL like "https://mondra.com"')
    .nullable(true),
  secondaryDataReferenceSourceType: Yup.number()
    .typeError("Please select Secondary Data Reference Source Type")
    .integer()
    .nullable(false),
  referenceSourcingCountry: Yup.number()
    .typeError("Please select Reference Sourcing Country")
    .integer()
    .nullable(true),
  referenceRegionIdentifier: Yup.number()
    .typeError("Please select Reference Region Identifier")
    .integer()
    .nullable(false),
  referenceDataPublicationYear: Yup.number()
    .typeError("Please select Reference Data Publication Year")
    .min(1900, "Reference Data Publication Year can`t be less than 1900")
    .max(2200, "Reference Data Publication Year can`t be greater than 2200")
    .nullable(false),
  referencesSamplingYear: Yup.number()
    .typeError("Please select Sampling End Date")
    .min(1900, "Sampling End Date can`t be less than 1900")
    .max(2200, "References Sampling Year can`t be greater than 2200")
    .nullable(true),
  referenceTechnologyProxyContribution: Yup.number()
    .typeError("Please select Reference Technology Proxy Contribution")
    .min(0, "Reference Technology Proxy Contribution can`t be less than 0")
    .max(
      100,
      "Reference Technology Proxy Contribution can`t be greater than 100"
    )
    .nullable(false),
  ingredients: Yup.array().of(ingredientSchema),
  resourceSources: Yup.array().of(resourceSchema),
  products: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number()
        .transform(transformNumberValueAllowZero)
        .typeError("Field accepts numbers only")
        .required("Quantity is required")
        .test(
          "len",
          "Max symbols allowed - 32. Max symbols after dot - 16",
          testNumberWithDotLength
        ),
      productType: Yup.number()
        .typeError("Please select Product type")
        .required("Product type is required"),
      economicAllocation: Yup.number()
        .typeError("Please select Field Economic allocation")
        .required("Economic allocation is required")
        .test(
          "percentage",
          "Max value should be less than 100%",
          (val = 0) => val <= 100
        ),
    })
  ),
  waste: Yup.array().of(wasteSchema),
  emissions: Yup.array().of(emissionToAirSchema),
});

export const findParentWithChildId = (type, childId, taxonomy) => {
  if (type === 1) {
    return productTaxonomy(childId, taxonomy?.productTaxonomies || {});
  }
  const {
    processedTaxonomies = {},
    produceTaxonomies = {},
    specialTaxonomies = {},
  } = taxonomy;
  const {
    classes,
    categories,
    functionalUnits,
    produceCategories,
    produceSubcategories,
    subcategories,
  } =
    type === 0
      ? produceTaxonomies
      : type === 3
      ? specialTaxonomies
      : processedTaxonomies;

  const functionalUnit = functionalUnits?.find((fu) => fu.id === childId);
  let subCategory, cls, category, activity;

  if (functionalUnit && processedTaxonomies.activities) {
    activity = processedTaxonomies.activities?.find(
      (sc) => sc.id === functionalUnit?.activityId
    );
  }
  if (functionalUnit && produceSubcategories) {
    subCategory = produceSubcategories?.find(
      (sc) => sc.id === functionalUnit?.produceSubcategoryId
    );
  } else {
    subCategory = subcategories?.find(
      (sc) => sc.id === functionalUnit?.subcategoryId
    );
  }
  if (subCategory && produceCategories) {
    category = produceCategories?.find(
      (sc) => sc.id === subCategory?.categoryId
    );
  } else if (functionalUnit && type === 3) {
    category = categories?.find((sc) => sc.id === functionalUnit?.categoryId);
  } else {
    category = categories?.find((sc) => sc.id === subCategory?.categoryId);
  }
  if (category) {
    cls = classes?.find((sc) => sc.id === category.classId);
  }
  return {
    activity,
    class: cls,
    family: {},
    category,
    functionalUnit,
    subCategory,
  };
};

const productTaxonomy = (childId, taxonomy) => {
  const { families, classes, bricks } = taxonomy;

  const functionalUnit = bricks?.find((br) => br.id === childId);
  let family, cls;
  if (functionalUnit) {
    cls = classes?.find((sc) => sc.id === functionalUnit?.classId);
  }
  if (functionalUnit) {
    family = families?.find((sc) => sc.id === cls?.familyId);
  }
  return {
    functionalUnit,
    class: cls,
    family,
    activity: {},
    category: {},
    subCategory: {},
  };
};
