import { FC, useEffect, useState, EffectCallback } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  Breadcrumbs,
  Button,
  LottieLoader,
  Notification,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchSales, getConfiguration } from "./salesActivity";
import { AgGridReact } from "ag-grid-react";
import {
  sortDropdown,
  transformFormValues,
} from "../../../shared/formFunctions";
import { formSchema, rowData } from "./utils";
import {
  updateSalesActivity,
  publishSalesActivity,
  unPublishSalesActivity,
  createSalesActivity,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";

import { handlePublishStatus, setDefaultSale } from "../../../redux/salesSlice";

import InputItem from "../../../components/InputItem";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import "./index.scss";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";

const salesColDefs = [
  { field: "category", width: 220, autoHeight: true, wrapText: true },
  { field: "shelfLife", autoHeight: true, wrapText: true },
  { field: "optimalStorageTemperature", autoHeight: true, wrapText: true },
  {
    field: "definition",
    flex: 1,
    resizable: true,
    autoHeight: true,
    wrapText: true,
  },
];

const Sale: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, sale, configuration } = useAppSelector(
    (state) => state.salesSlice
  );
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);

  let saleId = searchParams.get("itemId") || "";
  const isNew = saleId === "new";

  useEffect((): ReturnType<EffectCallback> => {
    if (!companiesLoading) {
      if (saleId == "") {
        navigate("/not-found");
      }
      dispatch(getConfiguration());
      if (!isNew) {
        dispatch(fetchSales(saleId, () => navigate("/sale-activity")));
      }
      return () => {
        dispatch(setDefaultSale());
      };
    }
  }, [companiesLoading, isNew]);

  useEffect(() => {
    formik.setValues({ ...sale });
  }, [sale]);

  const formik = useFormik({
    initialValues: {
      ...sale,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (sale.state === 0) {
        publishSalesActivity(saleId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data.title));
      } else {
        unPublishSalesActivity(saleId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data.title));
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const { values } = formik;

    const tValues = transformFormValues(values);

    if (isNew) {
      try {
        await createSalesActivity(tValues).then(() => {
          navigate("/sale-activity");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateSalesActivity(saleId, tValues)
          .then(() =>
            dispatch(fetchSales(saleId, () => navigate("/sale-activity")))
          )
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  };

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    !isNew ? JSON.stringify(sale) != JSON.stringify(formik.values) : false
  );

  return loading !== true || isNew ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/sale-activity",
                title: "Sales Activity Table",
              },
              {
                path: `/sale-activity/sale?itemId=${saleId}`,
                title: "Sale Activity Item",
              },
            ]}
          />
        </div>
        {saleId !== "new" && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  sale.state === 0 ? "bg-red-500" : "bg-emerald-500"
                }`}
              >
                {sale.state === 0 ? "Not Published" : "Published"}
              </div>
              {sale?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    sale.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        )}

        <p className={"decoration-gray-600 mt-1 mb-7 piece-description"}>
          This database is a collation of impacts incurred through sales
          activities in the sales channels and conditions of interest. All sales
          activity data has been reported for a functional unit of{" "}
          <b>1 kg of the final product</b>.
        </p>
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5"}>Activity Type</h5>
          <div className="piece-content-inputs">
            <InputItem
              name="Channel"
              description="Please choose the name based on the type of sales stage"
              type="select"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.channel}
              onChange={formik.handleChange}
              formikName="channel"
              options={sortDropdown(
                configuration.channel,
                formik.values.channel,
                "description"
              )}
            />
            <InputItem
              name="Country name"
              description="The country in which the data was collected."
              type="select"
              onChange={formik.handleChange}
              formikName="countryId"
              defaultValue={
                formik.values.countryId ? formik.values.countryId : -1
              }
              formik={formik}
              options={sortDropdown(
                configuration.countries,
                formik.values.countryId ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Country ISO Code"
              description="Please select the country in which the data was collected."
              type="select"
              defaultValue={
                formik.values.countryId ? formik.values.countryId : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="countryId"
              options={sortDropdown(
                configuration.countries,
                formik.values.countryId ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              name="Region Identifier"
              description="The region identifier in which the data was collected."
              type="select"
              defaultValue={formik.values.regionIdentifierId}
              onChange={formik.handleChange}
              formikName="regionIdentifierId"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.regions,
                formik.values.regionIdentifierId,
                "name"
              )}
            />
            <InputItem
              name="Reference product(s)"
              description="Specified which product data has been utilized for proxy"
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referenceProducts || ""}
              formikName="referenceProducts"
            />
            <InputItem
              name="Temperature conditions"
              description="Select temperature conditions"
              type="select"
              defaultValue={formik.values.temperatureConditions}
              onChange={formik.handleChange}
              formikName="temperatureConditions"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.temperatureConditions,
                +formik.values.temperatureConditions,
                "description"
              )}
            />
            <InputItem
              name="Shelf life"
              description="Select shelf life. See definitions below for what Short, Medium and High shelf lives mean"
              type="select"
              defaultValue={formik.values.shelfLife}
              onChange={formik.handleChange}
              formikName="shelfLife"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.shelfLife,
                +formik.values.shelfLife,
                "description"
              )}
            />
            <InputItem
              disabled={true}
              name="Category"
              description="Please enter a category based on the [Temperature conditions] & [Shelf life] e.g. Ambient - short shelf-life"
              type="input"
              defaultValue={`${
                (configuration.temperatureConditions[
                  formik.values.temperatureConditions
                ]?.description &&
                  configuration.temperatureConditions[
                    formik.values.temperatureConditions
                  ]?.description + "-") ||
                ""
              }${
                (configuration.shelfLife[formik.values.shelfLife]
                  ?.description &&
                  configuration.shelfLife[formik.values.shelfLife]
                    ?.description) ||
                ""
              }`}
            />
            <InputItem
              name="Impact Loss Factor"
              description="Please enter an impact loss factor"
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              defaultValue={formik.values.impactLossFactor || ""}
              formikName="impactLossFactor"
            />
            <InputItem
              name="External code"
              description=""
              type="input"
              disabled
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.code || ""}
              formikName="code"
            />
          </div>
          <div
            className="ag-theme-alpine sale-ag-grid-container"
            style={{ height: 400, width: "59.375rem", marginTop: "30px" }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={salesColDefs}
            ></AgGridReact>
          </div>
          <h5 className={"mt-5"}>Impacts</h5>
          <p>
            Please provide the impacts below in the units specified for 1 kg net
            mass of product for the specified sales stage
          </p>
          <div className={"piece-content-inputs"}>
            <InputItem
              name="Carbon"
              description="Please provide carbon emissions in kg CO2 eq"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={
                formik.values.carbon === 0 ? "0" : formik.values.carbon
              }
              formikName="carbon"
            />
            <InputItem
              name="Water usage"
              description="Please provide water usage in m3"
              type="input"
              addonTitle="m3"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={
                formik.values.waterUsage === 0 ? "0" : formik.values.waterUsage
              }
              formikName="waterUsage"
            />
            <InputItem
              name="Eutrophication"
              description="Please provide eutrophication in  kg PO4^3- eq"
              type="input"
              addonTitle="kg"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={
                formik.values.eutrophication === 0
                  ? "0"
                  : formik.values.eutrophication
              }
              formikName="eutrophication"
            />
            <InputItem
              name="Comment"
              description="Please add comment"
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.comment || ""}
              formikName="comment"
            />
          </div>
          <h5 className={"mt-5"}>Data Quality</h5>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter"
              type="select"
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                formik.values.secondaryDataReferenceSourceType,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data quality reliability score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityReliabilityScore || ""}
              formikName="dataQualityReliabilityScore"
            />
            <InputItem
              name="Sampling End Date"
              description="The date in which the site in its current form ceased to exist or in which data sampling ended in YYYY. If the data was equally collected from multiple studies please select the sourcing end year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              showAsterisk
              defaultValue={formik.values.referenceDataPublicationYear}
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description=" Please select the Country Name identifier in which the data was collected (not published). Please select the country which most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              name="Reference Region Identifier"
              description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
              type="select"
              defaultValue={formik.values.referenceRegionIdentifier}
              onChange={formik.handleChange}
              formikName="referenceRegionIdentifier"
              formik={formik}
              showAsterisk
              options={sortDropdown(
                configuration.regions,
                formik.values.referenceRegionIdentifier,
                "name"
              )}
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          {errors && (
            <ul className="piece-errors">
              {errors.map(([key, value]) =>
                value.map((err) => (
                  <li>
                    <b>{key}:</b> {err}
                  </li>
                ))
              )}
            </ul>
          )}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              saleId != "new" && (
                <Button
                  variant="tertiary"
                  size="md"
                  className="w-32"
                  type="submit"
                  disabled={
                    JSON.stringify(sale) != JSON.stringify(formik.values)
                  }
                >
                  {sale.state === 0 ? "Publish" : "Unpublish"}
                </Button>
              )}
            <Button
              variant="primary"
              size="md"
              className="w-32"
              onClick={saveDraft}
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
            {JSON.stringify(sale) != JSON.stringify(formik.values) && (
              <b className="absolute text-sm bottom-0 text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={saleId}
        activityType={ActivityTypes.SalesActivity}
        auditCreatedBy={sale.researcherName}
        auditLastUpdatedBy={sale.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Sale;
