import { FC, useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Icon, LottieLoader } from "@mondra/ui-components";
import { ColDef } from "ag-grid-community";
import { IPieces } from "../../modules/packaging-pieces/packaging-grid/types";
import { ITransport } from "../../modules/transport-activity/transport-grid/types";
import { ISale } from "../../modules/sales-activity/sales-grid/types";
import { IResources } from "../../modules/resorces-sources/resources-grid/types";
import { IStorage } from "../../modules/storage-activity/storage-grid/types";
import { IProcess } from "../../modules/processing-activity/processing-grid/types";
import { IProcessedIngredient } from "../../modules/processed-ingredients/processed-grid/types";
import { ISpecialIngredient } from "../../modules/special-ingredients/special-grid/types";
import { IProduce } from "../../modules/produce/produce-grid/types";
import { IProduct } from "../../modules/product/product-grid/types";
import { IFactor } from "../../modules/emission-factors/emission-grid/types";
import { IWaterScarcity } from "../../modules/water-scarcity/water-scarcity-grid/types";
import { IResource } from "../../modules/resources/resources-grid/types";
import { ISources } from "../../modules/sources/sources-grid/types";
import { IResourcesSources } from "../../modules/resourcesSourcesReadonly/resourcesSourcesReadonly-grid/types";
import { LinkCellRenderer } from "./LinkCellRenderer";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./index.scss";

interface TableProps {
  columnDefs: any[];
  tableData:
    | IPieces[]
    | ITransport[]
    | ISale[]
    | IResources[]
    | IStorage[]
    | IProcess[]
    | IProcessedIngredient[]
    | ISpecialIngredient[]
    | IProduce[]
    | IProduct[]
    | IFactor[]
    | IWaterScarcity[]
    | IResource[]
    | IResourcesSources[]
    | ISources[];
  totalNumber: string;
  startCursor: string | null;
  endCursor: string | null;
  refetch: (variables?: any) => Promise<any>;
  defaultVars: any;
  setCursors: () => void;
  tableSearch: string | null;
  setOrder: (order: any) => void;
  order: any;
  loading: boolean;
  suppressRowClickSelection?: boolean;
}

const Table: FC<TableProps> = ({
  columnDefs,
  tableData,
  totalNumber,
  tableSearch,
  startCursor,
  endCursor,
  refetch,
  defaultVars,
  setCursors,
  setOrder,
  order,
  loading,
  suppressRowClickSelection,
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    };
  }, []);

  const handleSortingChange = (e) => {
    const columnState = e.columnApi.getColumnState();
    columnState.forEach((el) => {
      if (el.sort !== null) {
        setOrder(JSON.parse(`[{"${el.colId}": "${el.sort.toUpperCase()}"}]`));
      }
    });
  };

  useEffect(() => {
    setPageNumber(1);
  }, [tableSearch]);

  return (
    <div
      className="ag-theme-alpine content grid-table"
      style={{ height: "calc(100% - 190px)", width: "100%" }}
    >
      {loading && (
        <div className="loader-div">
          <LottieLoader
            lottieType="butterflyLottie"
            style={{ height: "6.25rem", width: "6.25rem" }}
          />
        </div>
      )}
      <AgGridReact
        onSortChanged={handleSortingChange}
        rowData={tableData}
        columnDefs={columnDefs}
        rowSelection={"single"}
        animateRows={true}
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={100}
        suppressContextMenu={true}
        suppressRowClickSelection={suppressRowClickSelection}
      ></AgGridReact>
      {tableData.length != 0 && (
        <div className={"grid-table-pagination"}>
          {pageNumber < Math.ceil(+totalNumber / 100)
            ? 100 * pageNumber
            : totalNumber}{" "}
          of {totalNumber}
          <Icon
            type="chevronLeft"
            className={`${
              pageNumber == 1 ? "cursor-disabled" : "cursor-pointer"
            }`}
            onClick={() => {
              if (pageNumber > 1) {
                refetch({
                  str: !tableSearch ? "" : tableSearch,
                  ...defaultVars,
                  before: startCursor,
                  after: null,
                  first: null,
                  last: 100,
                  order: order,
                }).then(() => {
                  setPageNumber(pageNumber - 1);
                  setCursors();
                });
              }
            }}
          />
          Page {pageNumber} of {Math.ceil(+totalNumber / 100)}
          <Icon
            type="chevronRight"
            className={`${
              pageNumber >= Math.ceil(+totalNumber / 100)
                ? "cursor-disabled"
                : "cursor-pointer"
            }`}
            onClick={() => {
              if (pageNumber < Math.ceil(+totalNumber / 100)) {
                refetch({
                  str: !tableSearch ? "" : tableSearch,
                  ...defaultVars,
                  after: endCursor,
                  first: 100,
                  before: null,
                  last: null,
                  order: order,
                }).then(() => {
                  setPageNumber(pageNumber + 1);
                  setCursors();
                });
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
