import { IGetResources } from "./types";

export const getResourcesVars: IGetResources = {
  secondaryDataReferenceSourceType: {
    in: [
      "PrimaryDataFromIndustrySenseChecked",
      "PrimaryDataMeasurementsTakenByMondra",
      "PeerReviewedPublishedLiterature",
      "PublishedBook",
      "IndustryPublishedReport",
      "Ecoinvent",
      "AgriFootPrint",
      "GovernmentFundedOrganizationReport",
      "NonGovernmentalFundedOrganizationReport",
      "Patent",
      "CompanyWebsite",
      "BlogWebsiteForum",
      "OpenSourceWebsite",
      "UndocumentedEstimate",
    ],
  },
  state: {
    in: ["Unpublished", "Published"],
  },
};
