
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAudit } from "../types/componentTypes";

interface IState {
  audit: IAudit[];
  fieldAudit: IAudit[];
  loading: boolean;
  loadingFieldAudit: boolean;
  error: string;
}

const initialState: IState = {
  audit: [
    {
      changes: [
        {
          stage: "",
          operationType: "",
          fields: [
            {
              field: "",
              previous: "",
              current: "",
              notes: []
            },
          ],
          userEmail: "",
          updatedDate: "",
        },
      ],
      userName: null,
      userEmail: "",
      updatedDate: "",
    },
  ],
  fieldAudit: [
    {
      changes: [
        {
          stage: "",
          operationType: "",
          fields: [
            {
              field: "",
              previous: "",
              current: "",
              notes: []
            },
          ],
          userEmail: "",
          updatedDate: "",
        },
      ],
      userName: null,
      userEmail: "",
      updatedDate: "",
    },
  ],
  loading: true,
  loadingFieldAudit: true,
  error: "",
};

const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    auditRequest(state) {
      state.loading = true;
    },
    fieldAuditRequest(state) {
      state.loadingFieldAudit = true;
    },
    auditFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.loadingFieldAudit = false;
      state.error = action.payload;
    },
    audit(state, action: PayloadAction<IAudit[]>) {
      state.loading = false;
      state.audit = action.payload;
    },
    fieldAudit(state, action: PayloadAction<IAudit[]>) {
      state.loadingFieldAudit = false;
      state.fieldAudit = action.payload;
    },
    resetFieldAudit(state) {
      state.fieldAudit = initialState.fieldAudit;
    },
  },
});

export default auditSlice.reducer;
export const {
  auditFailure,
  auditRequest,
  audit,
  fieldAudit,
  fieldAuditRequest,
  resetFieldAudit,
} = auditSlice.actions;
