import { AppDispatch } from "../../../redux/store";
import {
  getProcessedIngredients,
  getProcessedIngredientsConfiguration,
} from "./api";
import {
  processedRequest,
  processedSuccess,
  processedFailure,
  configuration,
} from "../../../redux/processedIngredient";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";

  export const fetchProcessedIngredient = (pieceId: string, replace) => async (dispatch: AppDispatch) => {
    try {
      dispatch(processedRequest());
      const {data: pieceData} = await getProcessedIngredients(pieceId);
      dispatch(processedSuccess(pieceData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      if (error.response.status == 400) {
        replace();
      }
      dispatch(processedFailure(error.message));
    }
  }

  export const getConfiguration = (pieceId: string) => async (dispatch: AppDispatch) => {
    try {
      const {data: configurationData} = await getProcessedIngredientsConfiguration(pieceId);
      dispatch(configuration(configurationData));
    } catch (error: any) {
      if (error.showCorrelationId) {
        dispatch(showCorrelationIdError(error.CorrelationId));
      }
      dispatch(processedFailure(error.message));
    }
  }
