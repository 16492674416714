import { gql } from "@apollo/client";

export const GET_ALL_RESOURCES = gql`
  query AllPieces(
    $str: String
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $state: StateEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ResourceSourceImpactsGraphQlSortInput!]
  ) {
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }
    resourceSourceImpact: __type(name: "ResourceSourceImpactsGraphQl") {
      fields {
        name
        description
      }
    }

    resourcesSourcesImpacts(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { countryName: { contains: $str } }
          { countryISOCode: { contains: $str } }
          { researcherName: { contains: $str } }
          { description: { contains: $str } }
          { sourceName: { contains: $str } }
          { combinedName: { contains: $str } }
          { resourceId: { contains: $str } }
          { dataQualityComment: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { code: { contains: $str } }
          {
            secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType
          }
          { state: $state }
        ]
      }
      order: $order
    ) {
      totalCount
      edges {
        node {
          id
          code
          state
          resourceName
          sourceName
          combinedName
          countryName
          countryISOCode
          regionIdentifier
          description
          processName
          comment
          carbon
          waterUsage
          eutrophication
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          referencesSamplingYear
          referenceDataPublicationYear
          referenceSourcingCountry
          referenceSourcingCountryISOCode
          referenceRegionIdentifier
          dataQualityComment
          lastPublishedDate
          researcherName
          isVerified
          verifiedOn
          updated
          lastUpdatedName
        }

        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
