import { gql } from "@apollo/client";

export const GET_ALL_RESOURCES_READONLY = gql`
query AllPieces(
  $str: String
  $state: StateEnumOperationFilterInput
  $after: String
  $before: String
  $first: Int
  $last: Int
  $order: [ResourcesSourcesGraphQlSortInput!]
) {
  state: __type(name: "StateEnum") {
    name
    enumValues {
      name
      description
      __typename
    }
    __typename
  }
  resourceSourceFields: __type(name: "ResourcesSourcesGraphQl") {
    fields {
      name
      description
      __typename
    }
    __typename
  }
  resourcesSources(
    first: $first
    last: $last
    after: $after
    before: $before
    where: {
      id: { gt: 0 }
      or: [
        { researcherName: { contains: $str } }
        { description: { contains: $str } }
        { sourceName: { contains: $str } }
        { combinedName: { contains: $str } }
        { resourceId: { contains: $str } }
        { lastUpdatedName: { contains: $str } }
        { state: $state }
      ]
    }
    order: $order
  ) {
    totalCount
    edges {
      node {
        id
        resourceId
        state
        resourceName
        sourceName
        combinedName
        lastPublishedDate
        researcherName
        defaultSource
        isForWaste
        isEmissionToAir
        description
        updated
        lastUpdatedName
        __typename
      }
      cursor
      __typename
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    __typename
  }
}
`;
