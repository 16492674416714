import axios from "axios";
import { AppDispatch } from "../store";
import { companies, companiesFailure, companiesRequest } from "../companiesSlice";
import { API_URL } from "../../const";

export const getCompanies = () => async (dispatch: AppDispatch) => {
    try {
      dispatch(companiesRequest());
      const { data: companiesData } = await axios.get(
        `${API_URL}/Integration/companies`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'X-MONDRA-APP': 'DataPlatform',
          },
        }
      );
      sessionStorage.setItem(
        'companies',
        companiesData.map((company) => company.id).toString()
      );
      dispatch(companies(companiesData));
    } catch (error: any) {
      dispatch(companiesFailure(error.message));
    }
  };
  