import { IProduce, IConfiguration } from './../modules/produce/produce-ing/types';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface IState {
  ingredient: IProduce;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    path: {
      category: '',
      categoryId: 0,
      categoryState: 0,
      class: '',
      classId: 0,
      classState: 0,
      subcategory: '',
      subcategoryId: 0,
      subcategoryState: 0,
      categoryCode: null,
      subcategoryCode: null,
      subcategoryFAOProduceTradeType: 0,
      subcategoryFAOProduceProductionType: 0,
      faoProductionId : null,
      faoProductionName: '',
      faoTradeId : null,
      faoTradeName : ''
    },
    regions: [],
    countries: [],
    secondaryDataReferenceSourceType: [],
    transportCondition: [],
    storageFoodCategory: [],
    faoSource: [],
  },
  ingredient: {
    id: 1,
    userEmail: "",
    lastUpdatedName: "",
    lastPublishedDate: "",
    researcherName: "",
    researcherEmail: "",
    updated: "",
    state: 0,
    created: "",
    functionalUnitProxy: "",
    code: null,
    name: "",
    subcategoryId: -1,
    classId: -1,
    categoryId: -1,
    transportCondition: -1,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
    faoTradeId: null,
    faoTradeSource: -1,
    faoProductionId: null,
    faoProductionSource: -1,
  },
  loading: true,
  error: "",
};

const produceSlice = createSlice({
  name: "produce",
  initialState,
  reducers: {
    produceRequest(state) {
      state.loading = true;
    },
    produceSuccess(state, action: PayloadAction<IProduce>) {
      state.loading = false;
      state.ingredient = action.payload;
    },
    produceFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.ingredient.state = action.payload;
    },
    setDefaultIngredient: () => initialState,
  },
});

export default produceSlice.reducer;
export const {
  produceRequest,
  produceSuccess,
  produceFailure,
  handlePublishStatus,
  setDefaultIngredient,
  configuration,
} = produceSlice.actions;
