import { IResourcesSources } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const RESOURCE_SOURCE_URL: string = `${API_URL}/ResourceSourceImpacts`;

export const getResourceSource = (pieceId: string) => http.get(`${RESOURCE_SOURCE_URL}/${pieceId}`);

export const updateResourceSource = (pieceId: string, data: IResourcesSources) => http.put(`${RESOURCE_SOURCE_URL}/${pieceId}`, {data});

export const publishResourceSource = (pieceId: string) => http.post(`${RESOURCE_SOURCE_URL}/publish/${pieceId}`);

export const unPublishResourceSource = (pieceId: string) => http.delete(`${RESOURCE_SOURCE_URL}/unpublish/${pieceId}`);

export const deleteResourceSource = (pieceId: string) => http.delete(`${RESOURCE_SOURCE_URL}/${pieceId}`);

export const createResourceSource = (data) => http.post(`${RESOURCE_SOURCE_URL}`, {data});

export const getResourceSourceConfiguration = (id) => http.get(`${RESOURCE_SOURCE_URL}/configuration/${id}`);
