import { gql } from "@apollo/client";

export const GET_ALL_PROCESSES = gql`
  query AllPieces(
    $str: String
    $secondaryDataReferenceSourceType: NullableOfReferenceSourceTypeEnumOperationFilterInput
    $state: StateEnumOperationFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: [ProcessingActivitiesGraphQlSortInput!]
  ) {
    secondaryDataReferenceSourceType: __type(name: "ReferenceSourceTypeEnum") {
      name
      enumValues {
        name
        description
      }
    }
    functionalUnit: __type(name: "FunctionalUnitEnum") {
      name
      enumValues {
        name
        description
      }
    }
    state: __type(name: "StateEnum") {
      name
      enumValues {
        name
        description
      }
    }
    processingActivity: __type(name: "ProcessingActivitiesGraphQl") {
      fields {
        name
        description
      }
    }

    processingActivities(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        id: { gt: 0 }
        or: [
          { referenceCountryISOCode: { contains: $str } }
          { referenceRegionIdentifier: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { processFlow: { contains: $str } }
          { code: { contains: $str } }
          { additionalSupplementaryInformation: { contains: $str } }
          { referenceSourcingCountry: { contains: $str } }
          { referenceRegionIdentifier: { contains: $str } }
          { referenceCountryISOCode: { contains: $str } }
          { lastUpdatedName: { contains: $str } }
          { researcherName: { contains: $str } }
          {
            secondaryDataReferenceSourceType: $secondaryDataReferenceSourceType
          }
          { state: $state }
          { functionalUnit: { contains: $str } }
        ]
      }
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          code
          state
          activityName
          processFlow
          clickUpId
          additionalSupplementaryInformation
          functionalUnit
          totalIngredientMass
          totalProductAndWasteWeight
          secondaryDataReferenceSourceType
          dataQualityReliabilityScore
          dataQualityCompletenessScore
          referencesSamplingYear
          referenceDataPublicationYear
          referenceSourcingCountry
          referenceCountryISOCode
          referenceRegionIdentifier
          referenceTechnologyType
          referenceTechnologyProxyContribution
          dataQualityComment
          lastPublishedDate
          researcherName
          isVerified
          verifiedOn
          lastUpdatedName
          updated
        }
        cursor
      }
    }
  }
`;
