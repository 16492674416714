import { gql } from "@apollo/client";

export const GET_ALL_PRODUCTS = gql`
query AllPieces(
  $str: String
  $state: StateEnumOperationFilterInput
  $after: String
  $before: String
  $first: Int
  $last: Int
  $order: [TaxonomyGPCBrickGraphQLSortInput!]
) {
  state: __type(name: "StateEnum") {
    name
    enumValues {
      name
      description
      __typename
    }
    __typename
  }
  taxonomyGPCBrick: __type(name: "TaxonomyGPCBrickGraphQL") {
    fields {
      name
      description
      __typename
    }
    __typename
  }
  taxonomyGPCBricks(
    first: $first
    last: $last
    after: $after
    before: $before
    where: {
      id: { gt: 0 }
      or: [
        { state: $state }
        { code: { contains: $str } }
        { segmentCode: { contains: $str } }
        { segmentName: { contains: $str } }
        { familyCode: { contains: $str } }
        { familyName: { contains: $str } }
        { classCode: { contains: $str } }
        { brickName: { contains: $str } }
        { lastUpdatedName: { contains: $str } }
        { researcherName: { contains: $str } }
      ]
    }
    order: $order
  ) {
    totalCount
    edges {
      node {
        id
        code
        brickName
        state
        segmentCode
        segmentName
		    segmentDefinition
        familyCode
        familyName
        familyDefinition
        classCode
        className
        classDefinition
        definitionExcludes
        definitionIncludes
        transportCondition
        mainAttributeName
        isVerified
        verifiedOn
        lastPublishedDate
        lastUpdatedName
        updated
        researcherName
        created
        __typename
      }
      cursor
      __typename
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
      __typename
    }
    __typename
  }
}
`;
