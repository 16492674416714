import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  EffectCallback,
  useRef,
} from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  LottieLoader,
  Notification,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchFactor, getConfiguration } from "./emissionFactorsActions";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import { transformFormValues } from "../../../shared/formFunctions";
import {
  copyToClipboard,
  formSchema,
  TAB_LABELS,
  TAB_ERRORS,
  TabsEnum,
} from "./utils";
import {
  updateEmissionFactors,
  publishEmissionFactors,
  unPublishEmissionFactors,
  createEmissionFactors,
  getProcessedDropdowns,
  getSpecialDropdowns,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";
import {
  handlePublishStatus,
  setDefaultFactor,
} from "../../../redux/factorSlice";

import { ContentTabs } from "../../../components/ContentTabs";
import { EFHeader } from "../../../components/EFHeader";
import { ProductInfo } from "./ProductInfo";
import { DataOrigin } from "./DataOrigin";
import { Impacts } from "./Impacts";
import { Emissions } from "./Emissions";
import { Losses } from "./Losses";
import { DataQuality } from "./DataQuality";
import "./index.scss";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes } from "../../../const";
import { IDropdown, IProxyProduceDetails } from "./types";

const defaultProxyProduceDetails = {
  class: -1,
  category: -1,
  productGroup: -1,
};

const Factor: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, factor, configuration, configurationLoading } =
    useAppSelector((state) => state.factorSlice);
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);

  const [selectedClass, setSelectedClass] = useState<number>(-1);
  const [selectedCategory, setSelectedCategory] = useState<number>(-1);
  const [selectedProductGroup, setSelectedProductGroup] = useState<number>(-1);

  const [selectedProxyProduce, setSelectedProxyProduce] =
    useState<IProxyProduceDetails>(defaultProxyProduceDetails);

  const [classes, setClasses] = useState<[] | IDropdown[]>([]);
  const [category, setCategory] = useState<[] | IDropdown[]>([]);
  const [subcategory, setSubcategory] = useState<[] | IDropdown[]>([]);
  const [functionalUnit, setFunctionalUnit] = useState<[] | IDropdown[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [proxyCategory, setProxyCategory] = useState<[] | IDropdown[]>([]);
  const [proxySubcategory, setProxySubcategory] = useState<[] | IDropdown[]>(
    []
  );
  const [proxyFunctionalUnit, setProxyFunctionalUnit] = useState<
    [] | IDropdown[]
  >([]);
  const prevProductType = useRef<number>(factor.productType);
  const blocker = useRef<number | null>(null);
  const isCustomEF = useRef<boolean>(false);

  const factorId = searchParams.get("itemId") || "";
  const isNew = factorId === "new";

  useEffect((): ReturnType<EffectCallback> => {
    if (!companiesLoading) {
      if (factorId == "") {
        navigate("/not-found");
      }
      if (isNew) {
        dispatch(getConfiguration("0"));
      } else {
        dispatch(getConfiguration(factorId));
      }
      if (!isNew) {
        dispatch(fetchFactor(factorId, () => navigate("/emission-factors")));
      }
      return () => {
        dispatch(setDefaultFactor());
      };
    }
  }, [companiesLoading, isNew]);

  useEffect(() => {
    formik.setValues({ ...factor });
  }, [factor]);

  useEffect(() => {
    if (configuration.productTaxonomies.functionalUnits !== null) {
      const classFromTaxonomy = configuration.productTaxonomies.classes?.[0];
      const subCategoryIdFromTaxonomy =
        configuration.productTaxonomies.functionalUnits.find(
          (el) => el.id === factor.productId
        )?.subcategoryId;
      const subCategoryFromTaxonomy =
        configuration.productTaxonomies.subcategories.filter(
          (el) => el.id === subCategoryIdFromTaxonomy
        )[0];
      setSelectedClass(classFromTaxonomy?.id || -1);
      setSelectedCategory(subCategoryFromTaxonomy?.categoryId || -1);
      setSelectedProductGroup(subCategoryFromTaxonomy?.id || -1);
    }
    if (configuration.specialTaxonomies.functionalUnits !== null) {
      const classFromTaxonomy = configuration.specialTaxonomies.classes?.[0];
      const categoryIdFromTaxonomy =
        configuration.specialTaxonomies.functionalUnits.find(
          (el) => el.id === factor.productId
        )?.categoryId;
      setSelectedClass(classFromTaxonomy?.id || -1);
      setSelectedCategory(categoryIdFromTaxonomy || -1);
    }
  }, [
    factor.productId,
    configuration.productTaxonomies,
    configuration.specialTaxonomies,
  ]);

  useEffect(() => {
    if (
      configuration.eutrophicationProxyProduceTaxonomies &&
      factor.proxyProduceId !== null &&
      factor.proxyProduceId !== undefined
    ) {
      const proxyClassId =
        configuration.eutrophicationProxyProduceTaxonomies?.classes?.[0]?.id ||
        -1;
      const proxyProductGroupId =
        configuration.eutrophicationProxyProduceTaxonomies.functionalUnits?.find(
          (el) => el.id === factor.proxyProduceId
        )?.subcategoryId || -1;
      const proxyCategoryId =
        configuration.eutrophicationProxyProduceTaxonomies.subcategories?.filter(
          (el) => el.id === proxyProductGroupId
        )[0]?.categoryId || -1;
      setSelectedProxyProduce({
        class: proxyClassId,
        category: proxyCategoryId,
        productGroup: proxyProductGroupId,
      });
    }
  }, [
    factor.proxyProduceId,
    configuration.eutrophicationProxyProduceTaxonomies,
  ]);

  const formik = useFormik({
    initialValues: {
      ...factor,
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      if (factor.state === 0) {
        publishEmissionFactors(factorId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data[0]?.errorMessage));
      } else {
        unPublishEmissionFactors(factorId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data.title));
      }
    },
  });

  const showPostHarvestLosses =
    formik.values.functionalUnit == 0 ||
    formik.values.functionalUnit == 5 ||
    formik.values.functionalUnit == 6;

  const getTabErrors = (tab: TabsEnum) => {
    return TAB_ERRORS[tab].some(
      (value) => formik.touched[value] && !!formik.errors[value]
    );
  };

  const tabs = [
    {
      label: TAB_LABELS[TabsEnum.PRODUCT],
      hasErrors: getTabErrors(TabsEnum.PRODUCT),
    },
    {
      label: TAB_LABELS[TabsEnum.DATA_ORIGIN],
      hasErrors: getTabErrors(TabsEnum.DATA_ORIGIN),
    },
    {
      label: TAB_LABELS[TabsEnum.EMISSIONS],
      hasErrors: getTabErrors(TabsEnum.EMISSIONS),
    },
    {
      label: TAB_LABELS[TabsEnum.IMPACTS],
      hasErrors: getTabErrors(TabsEnum.IMPACTS),
    },
    {
      label: TAB_LABELS[TabsEnum.LOSSES],
      hasErrors: getTabErrors(TabsEnum.LOSSES),
      disabled: !showPostHarvestLosses,
    },
    {
      label: TAB_LABELS[TabsEnum.DATA_QUALITY],
      hasErrors: getTabErrors(TabsEnum.DATA_QUALITY),
    },
  ];

  const saveDraft = async (e) => {
    e.preventDefault();
    const { values } = formik;

    setErrors([]);
    setShowError("");

    const tValues = transformFormValues(values);

    tValues.postHarvestLossesId =
      tValues.postHarvestLossesId != null &&
      (formik.values.functionalUnit === 0 ||
        formik.values.functionalUnit === 5 ||
        formik.values.functionalUnit === 6)
        ? +tValues.postHarvestLossesId
        : null;
    tValues.postHarvestLosses =
      tValues.postHarvestLosses != null &&
      (formik.values.functionalUnit === 0 ||
        formik.values.functionalUnit === 5 ||
        formik.values.functionalUnit === 6)
        ? +tValues.postHarvestLosses
        : null;
    tValues.postHarvestLossesNotes =
      formik.values.functionalUnit === 0 ||
      formik.values.functionalUnit === 5 ||
      formik.values.functionalUnit === 6
        ? tValues.postHarvestLossesNotes
        : [];
    tValues.productionSystem =
      formik.values.productType === 0 && tValues.productionSystem !== null
        ? +tValues.productionSystem
        : null;
    tValues.plantProtectionTypeGroup =
      formik.values.productType === 0 &&
      tValues.plantProtectionTypeGroup !== null
        ? +tValues.plantProtectionTypeGroup
        : null;
    tValues.hestiaProduceId =
      tValues.farmDataCalculator !== 0 ? null : tValues.hestiaProduceId;
    tValues.hestiaSubmissionId =
      tValues.farmDataCalculator !== 0 ? null : tValues.hestiaSubmissionId;
    tValues.externalSource =
      tValues.dataOrigin !== 3 ? null : tValues.externalSource;
    tValues.missingImpactFactor =
      tValues.dataOrigin !== 1 ? null : tValues.missingImpactFactor;
    tValues.farmDataCalculator =
      tValues.farmDataCalculator != null ? +tValues.farmDataCalculator : null;
    tValues.clickUpId = tValues.clickUpId?.trim()
      ? tValues.clickUpId?.trim()
      : null;

    tValues.coreVersionRelease = tValues.coreVersionRelease?.toString();
    tValues.calculatorVersion = tValues.calculatorVersion?.toString();

    if (tValues.dataOrigin != 1 && tValues.dataOrigin != 2) {
      tValues.calculatorVersion = null;
      tValues.farmDataCalculator = null;
      tValues.hestiaProduceId = null;
      tValues.hestiaSubmissionId = null;
      tValues.biodiversityPDFYear = null;
    }

    if (tValues.mondraMAToFillMissingEutrophication === "false") {
      tValues.mondraMAToFillMissingEutrophication = false;
    }
    if (tValues.mondraMAToFillMissingEutrophication === "true") {
      tValues.mondraMAToFillMissingEutrophication = true;
    }
    if (isNew) {
      try {
        await createEmissionFactors(tValues).then(() => {
          navigate("/emission-factors");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateEmissionFactors(factorId, tValues)
          .then(() => {
            dispatch(
              fetchFactor(factorId, () => navigate("/emission-factors"))
            );
            dispatch(getConfiguration(factorId));
            setClasses([]);
            setCategory([]);
            setSubcategory([]);
            setFunctionalUnit([]);
            setProxyCategory([]);
            setProxySubcategory([]);
            setProxyFunctionalUnit([]);
            setSelectedClass(-1);
            setSelectedCategory(-1);
            setSelectedProductGroup(-1);
            setSelectedProxyProduce(defaultProxyProduceDetails);
            prevProductType.current = values.productType;
          })
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  };

  useEffect(() => {
    isCustomEF.current =
      formik.values.companyId != null &&
      formik.values.companyId !== "" &&
      formik.values.state === 1;
    if (blocker.current === null) {
      prevProductType.current = formik.values.productType;
    }
  }, [formik.values.productType]);

  // Unset flag_DisaggregationType and proxyCountryId if not PnN data origin
  useEffect(() => {
    if (formik.values.dataOrigin != 1) {
      if (formik.values.flag_DisaggregationType) {
        formik.setFieldValue("flag_DisaggregationType", null);
      }

      if (formik.values.proxyCountryId) {
        formik.setFieldValue("proxyCountryId", null);
      }
    }
  }, [formik.values.dataOrigin]);

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    !isNew ? JSON.stringify(factor) != JSON.stringify(formik.values) : false
  );

  const clickHandler = useCallback(() => {
    copyToClipboard(formik.values.clickUpId || "");
  }, [formik.values.clickUpId]);

  const handleProductTypeChange = (e: React.ChangeEvent) => {
    blocker.current = 1;
    prevProductType.current = formik.values.productType;
    (e.target as any).value != "3"
      ? getProcessedDropdowns("class")
          .then((res) => setClasses(res.data))
          .then((res) => getProcessedDropdowns("category?classId=1"))
          .then((res) => setCategory(res.data))
      : getSpecialDropdowns("class")
          .then((res) => setClasses(res.data))
          .then((res) => getSpecialDropdowns("category?classId=1"))
          .then((res) => setCategory(res.data));
    formik.setFieldValue("productType", +(e.target as any).value);
    setSelectedClass(-1);
    setSelectedCategory(-1);
    setSelectedProductGroup(-1);
    formik.setFieldValue("productId", -1);
  };

  const handleClassChange = (e: React.ChangeEvent) => {
    const classSelected = (e.target as any).value;
    formik.values.productType == 3
      ? getSpecialDropdowns(`category?classId=${classSelected}`).then((res) =>
          setCategory(res.data)
        )
      : getProcessedDropdowns(`category?classId=${classSelected}`).then((res) =>
          setCategory(res.data)
        );
    setSelectedClass(+classSelected);
    setSelectedCategory(-1);
    setSelectedProductGroup(-1);
    formik.setFieldValue("productId", -1);
  };

  const handleCategoryChange = (e: React.ChangeEvent) => {
    const categorySelected = (e.target as any).value;
    formik.values.productType == 3
      ? getSpecialDropdowns(
          `functionalUnit?categoryId=${categorySelected}`
        ).then((res) => setFunctionalUnit(res.data))
      : getProcessedDropdowns(
          `subcategory?categoryId=${categorySelected}`
        ).then((res) => setSubcategory(res.data));
    setSelectedCategory(+categorySelected);
    setSelectedProductGroup(-1);
    formik.setFieldValue("productId", -1);
  };

  const handleProductGroupChange = (e: React.ChangeEvent) => {
    const productGroupSelected = (e.target as any).value;
    getProcessedDropdowns(
      `functionalUnit?subcategoryId=${productGroupSelected}`
    ).then((res) => setFunctionalUnit(res.data));
    setSelectedProductGroup(+productGroupSelected);
    formik.setFieldValue("productId", -1);
  };

  const handleProxyClassChange = (e: React.ChangeEvent) => {
    const classSelected = (e.target as any).value;
    if (classSelected) {
      getProcessedDropdowns(`category?classId=${classSelected}`).then((res) =>
        setProxyCategory(res.data)
      );
    }
    setSelectedProxyProduce({
      ...defaultProxyProduceDetails,
      class: +classSelected,
    });
    formik.setFieldValue("proxyProduceId", -1);
  };

  const handleProxyCategoryChange = (e: React.ChangeEvent) => {
    const categoryProxySelected = (e.target as any).value;
    if (categoryProxySelected) {
      getProcessedDropdowns(
        `subcategory?categoryId=${categoryProxySelected}`
      ).then((res) => setProxySubcategory(res.data));
    }
    setSelectedProxyProduce((prevProxy) => ({
      ...prevProxy,
      productGroup: -1,
      category: +categoryProxySelected,
    }));
    formik.setFieldValue("proxyProduceId", -1);
  };

  const handleProxyProductGroupChange = (e: React.ChangeEvent) => {
    const productGroupProxySelected = (e.target as any).value;
    if (productGroupProxySelected) {
      getProcessedDropdowns(
        `functionalUnit?subcategoryId=${productGroupProxySelected}`
      ).then((res) => setProxyFunctionalUnit(res.data));
    }
    setSelectedProxyProduce((prevProxy) => ({
      ...prevProxy,
      productGroup: +productGroupProxySelected,
    }));
    formik.setFieldValue("proxyProduceId", -1);
  };

  return (loading !== true && configurationLoading !== true) || isNew ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification
              type="error"
              label="Oops:"
              description={showError}
              onClose={() => setShowError("")}
            />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-5 mb-5">
          <Breadcrumbs
            routes={[
              {
                path: "/emission-factors",
                title: "Emission Factors Table",
              },
              {
                path: `/emission-factors/sale?itemId=${factorId}`,
                title: "Emission Factors Item",
              },
            ]}
          />
        </div>
        {!isNew && (
          <EFHeader
            label={formik.values.productNameAtSource}
            isPublished={factor.state !== 0}
            datePublished={factor?.lastPublishedDate}
          />
        )}
        {!isNew && (
          <>
            {configuration.productTaxonomies.functionalUnits === null &&
              formik.values.productType != 3 && (
                <div className="text-base text-rose-600">
                  There is unpublished changes in product taxonomy: ID{" "}
                  {factor.productId}
                </div>
              )}
            {configuration.specialTaxonomies.functionalUnits === null &&
              formik.values.productType == 3 && (
                <div className="text-base text-rose-600">
                  There is unpublished changes in special taxonomy: ID{" "}
                  {factor.productId}
                </div>
              )}
            {factor.dataOrigin == 1 &&
              configuration.eutrophicationProxyProduceTaxonomies
                .functionalUnits === null &&
              factor.proxyProduceId !== null && (
                <div className="text-base text-rose-600 mt-3">
                  There is unpublished changes in eutrophication proxy produce
                  taxonomies: ID {factor.proxyProduceId}
                </div>
              )}
          </>
        )}

        <ContentTabs
          items={tabs}
          selectedIndex={selectedTab}
          onChange={(index) => setSelectedTab(index)}
        />

        <form onSubmit={formik.handleSubmit} className="piece-content">
          {selectedTab === 0 && (
            <ProductInfo
              isCustomEF={isCustomEF}
              formik={formik}
              configuration={configuration}
              isNew={isNew}
              prevProductType={prevProductType}
              classes={classes}
              selectedClass={selectedClass}
              selectedCategory={selectedCategory}
              category={category}
              subcategory={subcategory}
              functionalUnit={functionalUnit}
              factor={factor}
              selectedProductGroup={selectedProductGroup}
              onProductTypeChange={handleProductTypeChange}
              onCategoryChange={handleCategoryChange}
              onClassChange={handleClassChange}
              onProductGroupChange={handleProductGroupChange}
            />
          )}
          {selectedTab === 1 && (
            <DataOrigin
              isCustomEF={isCustomEF}
              formik={formik}
              configuration={configuration}
              factor={factor}
              clickHandler={clickHandler}
              factorId={factorId}
            />
          )}
          {selectedTab === 2 && (
            <Emissions isCustomEF={isCustomEF} formik={formik} />
          )}
          {selectedTab === 3 && (
            <Impacts isCustomEF={isCustomEF} formik={formik} />
          )}
          {selectedTab === 4 && showPostHarvestLosses && (
            <Losses
              isCustomEF={isCustomEF}
              formik={formik}
              configuration={configuration}
            />
          )}
          {selectedTab === 5 && (
            <DataQuality
              isCustomEF={isCustomEF}
              formik={formik}
              configuration={configuration}
              isNew={isNew}
              selectedProxyProduce={selectedProxyProduce}
              proxyCategory={proxyCategory}
              proxySubcategory={proxySubcategory}
              factor={factor}
              proxyFunctionalUnit={proxyFunctionalUnit}
              onProxyClassChange={handleProxyClassChange}
              onProxyCategoryChange={handleProxyCategoryChange}
              onProxyProductGroupChange={handleProxyProductGroupChange}
            />
          )}
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map((err) => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              factorId != "new" && (
                <Button
                  variant="tertiary"
                  size="md"
                  type="submit"
                  className="w-32"
                  disabled={
                    formik.isSubmitting ||
                    isCustomEF.current ||
                    JSON.stringify(factor) != JSON.stringify(formik.values)
                  }
                >
                  {factor.state === 0 ? "Publish" : "Unpublish"}
                </Button>
              )}
            {JSON.stringify(factor) !== JSON.stringify(formik.values) && (
              <div
                className="bg-orange-100 border-l-4 border-orange-500 px-4 py-2 rounded text-color-rose-500"
                role="alert"
              >
                <p>Please save before publishing new changes</p>
              </div>
            )}
            <Button
              variant="primary"
              size="md"
              onClick={saveDraft}
              className="w-32"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={factorId}
        activityType={ActivityTypes.EmissionFactors}
        auditCreatedBy={factor.researcherName}
        auditLastUpdatedBy={factor.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Factor;
