import React, { FC } from "react";

import { sortDropdown } from "../../../shared/formFunctions";
import { Button } from "@mondra/ui-components";
import InputItem from "../../../components/InputItem";
import { IConfiguration, IEmissionFactors } from "./types";

interface IDataOrigin {
  isCustomEF: React.MutableRefObject<boolean>;
  formik: any;
  configuration: IConfiguration;
  factor: IEmissionFactors;
  clickHandler(): void;
  factorId: string;
}

export const DataOrigin: FC<IDataOrigin> = ({
  isCustomEF,
  formik,
  configuration,
  factor,
  clickHandler,
  factorId,
}) => {
  return (
    <div className="mb-5">
      <div className="piece-content-inputs">
        <InputItem
          disabled={isCustomEF.current}
          name="Data Origin"
          description=""
          type="select"
          defaultValue={formik.values.dataOrigin}
          onChange={formik.handleChange}
          formikName="dataOrigin"
          showAsterisk
          formik={formik}
          options={sortDropdown(
            configuration.dataOrigins,
            formik.values.dataOrigin,
            "description"
          )}
        />
        <InputItem
          disabled
          name="Data Quality Completeness Score"
          description="Read only"
          type="input"
          formik={formik}
          defaultValue={formik.values.dataQualityCompletenessScore || ""}
          formikName="dataQualityCompletenessScore"
        />
        <InputItem
          disabled={isCustomEF.current}
          name="Core Version Release"
          description="“Core 1.0” release in 2022."
          type="input"
          onChange={formik.handleChange}
          formikName="coreVersionRelease"
          formik={formik}
          showAsterisk
          defaultValue={formik.values.coreVersionRelease}
        />
        {formik.values.dataOrigin == 1 && (
          <InputItem
            disabled={isCustomEF.current}
            name="Type of FLAG disaggregation for PnN Data"
            description="Number and Type of PnN cycles used for the FLAG data disaggregation"
            type="select"
            defaultValue={formik.values.flag_DisaggregationType}
            onChange={formik.handleChange}
            formikName="flag_DisaggregationType"
            formik={formik}
            options={sortDropdown(
              configuration.flagDisaggregationTypes,
              formik.values.flag_DisaggregationType,
              "description"
            )}
          />
        )}
        {formik.values.dataOrigin == 3 && (
          <InputItem
            disabled={isCustomEF.current}
            name="External Source"
            description="Please specify if the source is not Mondra Core Platform, PnN Original Publication, Mondra MA."
            type="input"
            onChange={formik.handleChange}
            formikName="externalSource"
            showAsterisk
            formik={formik}
            defaultValue={
              formik.values.dataOrigin == 3
                ? formik.values.externalSource || ""
                : undefined
            }
          />
        )}
      </div>
      {(formik.values.dataOrigin == 1 || formik.values.dataOrigin == 2) && (
        <h6 className={"mt-5"}>Data Origin Farm</h6>
      )}
      <div className="piece-content-inputs">
        {(formik.values.dataOrigin == 1 || formik.values.dataOrigin == 2) && (
          <InputItem
            disabled={isCustomEF.current}
            name="Farm Data Calculator"
            description=""
            type="select"
            defaultValue={formik.values.farmDataCalculator}
            onChange={formik.handleChange}
            formikName="farmDataCalculator"
            showAsterisk
            create={factorId == "new" || factor.farmDataCalculator == null}
            formik={formik}
            options={sortDropdown(
              configuration.farmDataCalculators,
              formik.values.farmDataCalculator || -1,
              "description"
            )}
          />
        )}
        {formik.values.farmDataCalculator == 0 && (
          <InputItem
            disabled={isCustomEF.current}
            name="Hestia Produce ID"
            description="Please provide the Hestia produce ID that was used for the run."
            type="input"
            onChange={formik.handleChange}
            formikName="hestiaProduceId"
            formik={formik}
            defaultValue={formik.values.hestiaProduceId || ""}
          />
        )}
        {formik.values.farmDataCalculator == 0 && (
          <InputItem
            disabled={isCustomEF.current}
            name="Hestia Submission ID"
            description="Please provide the Hestia submission ID that was created for the run."
            type="input"
            onChange={formik.handleChange}
            formikName="hestiaSubmissionId"
            formik={formik}
            defaultValue={formik.values.hestiaSubmissionId || ""}
          />
        )}
        {(formik.values.dataOrigin == 1 || formik.values.dataOrigin == 2) && (
          <>
            <InputItem
              disabled={isCustomEF.current}
              name="Calculator Version"
              description="Please specify the version of the calculator that was used"
              type="input"
              onChange={formik.handleChange}
              formikName="calculatorVersion"
              showAsterisk
              formik={formik}
              defaultValue={formik.values.calculatorVersion || ""}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="PnN Excel Calculator"
              description=""
              type="select"
              defaultValue={formik.values.pnNExcelCalculator}
              onChange={formik.handleChange}
              formikName="pnNExcelCalculator"
              showAsterisk
              formik={formik}
              options={sortDropdown(
                configuration.pnNExcelCalculators,
                formik.values.pnNExcelCalculator || -1,
                "description"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Mondra MA to fill missing eutrophication"
              description="Please specify if this MA was carried out to replace a missing eutrophication value in the PnN Original Publication"
              type="select"
              defaultValue={formik.values.mondraMAToFillMissingEutrophication}
              onChange={formik.handleChange}
              formikName="mondraMAToFillMissingEutrophication"
              create={factorId == "new"}
              formik={formik}
              options={sortDropdown(
                [
                  { id: true, label: "Yes" },
                  { id: false, label: "No" },
                ],
                formik.values.mondraMAToFillMissingEutrophication as boolean,
                "label"
              )}
            />
            <InputItem
              disabled={isCustomEF.current}
              name="Click up ID"
              description=""
              type="input"
              onChange={formik.handleChange}
              formikName="clickUpId"
              formik={formik}
              defaultValue={formik.values.clickUpId || ""}
            />
            {formik.values.clickUpId && (
              <div className="flex items-end w-2/4">
                <a
                  href={formik.values.clickUpId || ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="secondary" disabled={isCustomEF.current}>
                    View clickup
                  </Button>
                </a>
                <Button
                  variant="secondary"
                  className="ml-2"
                  onClick={clickHandler}
                  disabled={isCustomEF.current}
                >
                  Copy URL
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DataOrigin;
