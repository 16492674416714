import { FC, useEffect, useState } from "react";
import { Icon } from "@mondra/ui-components";
import "./index.scss";
import { getDashboard } from "./api";

interface DashboardProps {
  name: string;
}

interface IResponse {
  amountOfAllEntities: number;
  amountOfPublishedEntities: number;
}

const Dashboard: FC<DashboardProps> = ({ name }) => {

  const [data, setData] = useState<IResponse>();

  useEffect(() => {
    getDashboard().then(res => setData(res.data));
  }, [])

  return (
    <div className="dashboard ml-5 mt-5">
      <h4 className="text-3xl font-medium">Welcome Back, {name}</h4>
      <div className="dashboard-information mt-7">
        <div className="dashboard-information-flex">
          <div>
            <div className="dashboard-information-icon">
              <Icon type="product" />
            </div>
            <h6 className="text-lg font-semibold mt-4">Data Overview</h6>
            <p className="mt-2 font-normal text-base leading-6">
              Here is a breakdown of {data?.amountOfAllEntities} data records.
            </p>
          </div>
          <div className="dashboard-information-main">
            <h5 className="text-4xl font-normal leading-11">{data?.amountOfAllEntities}</h5>
            <h6 className="text-sm font-bold leading-5">Data Points</h6>
          </div>
        </div>
        <hr className="mt-24" />
        <div className='mt-10'>
          <div className='w-fit flex items-center border border-emerald-700	 rounded-md'>
            <div className="p-5 bg-emerald-700">
              <Icon className="text-white" size={32} type="butterfly" />
            </div>
            <span className='text-lg font-medium ml-6 mr-44'>{data?.amountOfPublishedEntities} Published</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
