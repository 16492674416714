import { FC, useState } from "react";
import { FormDialog, Button } from "@mondra/ui-components";
import InputItem from "../../../../components/InputItem";
import { useFormik } from "formik";
import { resourceSchema } from "../utils";
import { addLocalPopUpNote } from "../../../../shared/noteFunctions";

interface ComponentFormProps {
  resourceSources: any;
  addProcess: (variables?: any, array?: string) => void;
}

const ResourceForm: FC<ComponentFormProps> = ({
  resourceSources,
  addProcess,
}) => {
  const [openForm, setOpenForm] = useState(false);

  const initialValues = {
    resourceSourceId: -1,
    quantity: "",
    quantityNotes: [],
    correspondingProcess: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: resourceSchema,
    onSubmit: (values) => {
      values.resourceSourceId = +values.resourceSourceId;
      addProcess(values, "resourceSources");
      setTimeout(() => setOpenForm(false), 500);
    },
  });

  const onCreateComponent = () => {
    setOpenForm(true);
    formik.setValues(initialValues);
    formik.setTouched({}, false);
  };

  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className="mt-5"
        onClick={onCreateComponent}
      >
        Create resource
      </Button>
      <FormDialog
        title="Create resource"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              defaultValue={
                formik.values.resourceSourceId
                  ? formik.values.resourceSourceId
                  : -1
              }
              name="Resource & Source"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              options={resourceSources.map((el) => ({
                label: el.combinedName,
                value: el.id,
              }))}
            />
            <InputItem
              defaultValue={
                formik.values.resourceSourceId
                  ? formik.values.resourceSourceId
                  : -1
              }
              name="Resource + Source ID"
              description=""
              type="select"
              create={true}
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              formikName="resourceSourceId"
              options={resourceSources.map((el) => ({
                label: el.combinedId,
                value: el.id,
              }))}
            />
            <InputItem
              name="Quantity"
              description="Please provide the quantity of the resource source in the displayed unit"
              type="input"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={formik.values.quantity}
              formikName="quantity"
              addonTitle={
                resourceSources.find(
                  (el) => el.id === +formik.values.resourceSourceId
                )?.unit
              }
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.quantityNotes.length,
                  ...params,
                })
              }
              notes={formik.values.quantityNotes || []}
            />
            <InputItem
              name="Corresponding Process"
              description="Please review the excel file called “Mondra ProcessingActivities Ontology” to list the corresponding process for this ingredient E.g. Mixing, Shaping, Cream Filling, Baking, Cleaning, Cooling."
              type="input"
              formik={formik}
              onChange={formik.handleChange}
              defaultValue={formik.values.correspondingProcess}
              formikName="correspondingProcess"
            />
          </div>
        </form>
      </FormDialog>
    </>
  );
};

export default ResourceForm;
