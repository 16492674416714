import { Button, Icon } from "@mondra/ui-components";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../const";
import "./index.scss";

const Menu: FC = () => {
  const {
    packaging,
    dashboard,
    transportActivity,
    saleActivity,
    resourcesSources,
    resourcesSourcesReadonly,
    storageActivity,
    processingActivity,
    processedIngredients,
    specialIngredients,
    produce,
    product,
    emissionFactors,
    waterScarcity,
    resources,
    sources
  } = ROUTES;
  return (
    <nav className="menu">
      <div className="menu-items">
        <NavLink
          to={dashboard}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="home" /> Dashboard
        </NavLink>
        <NavLink
          to={processingActivity}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="processing" /> Processing
        </NavLink>
        <NavLink
          to={storageActivity}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="map" /> Storage
        </NavLink>
        <NavLink
          to={resourcesSources}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="resources" /> Resources
        </NavLink>
        <NavLink
          to={saleActivity}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="sale" /> Sales
        </NavLink>
        <NavLink
          to={packaging}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="packaging" /> Packaging
        </NavLink>
        <NavLink
          to={transportActivity}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="deliveryTruck" /> Transport
        </NavLink>
        <NavLink
          to={emissionFactors}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="waterPollutionImpact" /> Emission Factors
        </NavLink>
        <br/>
        <hr/>
        <h4 className='ml-3 mt-5 mb-3'>Taxonomies</h4>
        <NavLink
          to={processedIngredients}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="ingredients" /> Processed Ingredients
        </NavLink>
        <NavLink
          to={specialIngredients}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="butterfly" /> Special Ingredients
        </NavLink>
        <NavLink
          to={produce}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="produce" /> Produce
        </NavLink>
        <NavLink
          to={product}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="product" />GPC (Product)
        </NavLink>
        <br/>
        <hr/>
        <h4 className='ml-3 mt-5 mb-3'>Others</h4>
        <NavLink
          to={waterScarcity}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="waterUsageImpact" /> Water Scarcity
        </NavLink>
        <NavLink
          to={resources}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="resources" /> Resources
        </NavLink>
        <NavLink
          to={sources}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="building" /> Sources
        </NavLink>
        <NavLink
          to={resourcesSourcesReadonly}
          className={({ isActive }) =>
            isActive ? "menu-items-item menu-items-active" : "menu-items-item"
          }
        >
          <Icon type="industry" /> Resource sources
        </NavLink>
      </div>
    </nav>
  );
};

export default Menu;
