import { IGetPieces } from "./types";

export const getPiecesVars: IGetPieces = {
  pieceGroup: {
    in:  [
      "Bag",
      "BinBucket",
      "Bottle",
      "Box",
      "Cage",
      "Can",
      "Carton",
      "Container",
      "Crate",
      "DrumBarrel",
      "Film",
      "Liner",
      "Pallet",
      "PalletWrap",
      "SleeveWrapperTray",
      "Wheels"
    ]},
  packagingType: {
    in: ["ConsumerPackaging", "DistributionPackaging"],
  },
  secondaryDataReferenceSourceType: {
    in: [
      "PrimaryDataFromIndustrySenseChecked",
      "PrimaryDataMeasurementsTakenByMondra",
      "PeerReviewedPublishedLiterature",
      "PublishedBook",
      "IndustryPublishedReport",
      "Ecoinvent",
      "AgriFootPrint",
      "GovernmentFundedOrganizationReport",
      "NonGovernmentalFundedOrganizationReport",
      "Patent",
      "CompanyWebsite",
      "BlogWebsiteForum",
      "OpenSourceWebsite",
      "UndocumentedEstimate",
    ],
  },
  state: {
    in: ["Unpublished", "Published"],
  },
  typicalUses: {
    in: [
      "GrainsLegumesSeedsNutsGranulates",
      "MeatFish",
      "VegetablesFruits",
      "PreparedPreservedFoods",
      "General",
      "Beverages",
      "Oils",
      "Eggs",
    ],
  },
};
