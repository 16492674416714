import {
  IConfiguration,
  IProcessingActivity,
} from "../modules/processing-activity/process/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  process: IProcessingActivity;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    regions: [],
    countries: [],
    productTypes: [],
    inputIngredientTypes: [],
    outputProductTypes: [],
    processedTaxonomies:{
      activities:[],
      classes: [],
      functionalUnits:[],
      produceCategories:[],
      produceSubcategories:[]
    },
    resourceSources: [],
    secondaryDataReferenceSourceType: [],
  },
  process: {
    code: null,
    lastPublishedDate: "",
    researcherName: "",
    researcherEmail: "",
    lastUpdatedName: null,
    updated: "",
    state: 0,
    processFlow: "",
    countryId: null,
    regionIdentifierId: -1,
    ingredients: [],
    resourceSources: [],
    products: [],
    waste: [],
    emissions: [],
    clickUpId: null,
    additionalSupplementaryInformation: "",
    secondaryDataReferenceSourceType: null,
    dataQualityReliabilityScore: null,
    dataQualityCompletenessScore: null,
    referencesSamplingYear: null,
    referenceDataPublicationYear: null,
    referenceRegionIdentifier: null,
    dataQualityComment: "",
    referenceSourcingCountry: null,
    companyId: null,
    referenceTechnologyType: null,
    referenceTechnologyProxyContribution: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  error: "",
};

const processSlice = createSlice({
  name: "process",
  initialState,
  reducers: {
    processRequest(state) {
      state.loading = true;
    },
    processSuccess(state, action: PayloadAction<IProcessingActivity>) {
      state.loading = false;
      state.process = action.payload;
    },
    processFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.process.state = action.payload;
    },
    addNote(state, action) {
      if (action.payload.index != undefined) {
        if (
          state.process[action.payload.list][action.payload.index][
            action.payload.key
          ] == null
        ) {
          state.process[action.payload.list][action.payload.index][
            action.payload.key
          ] = [];
        }
        state.process[action.payload.list][action.payload.index][
          action.payload.key
        ].push(action.payload.value);
      } else {
        if (state.process[action.payload.key] == null) {
          state.process[action.payload.key] = [];
        }
        state.process[action.payload.key].push(action.payload.value);
      }
    },
    setDefaultProcess: () => initialState,
  },
});

export default processSlice.reducer;
export const {
  processRequest,
  processSuccess,
  processFailure,
  handlePublishStatus,
  setDefaultProcess,
  addNote,
  configuration,
} = processSlice.actions;
