import { ITransportActivity } from "./types";
import http from "../../../http";
import { API_URL } from "../../../const";

const TRANSPORT_ACTIVITY_URL: string = `${API_URL}/TransportActivity`;

export const getTransportActivity = (pieceId: string) => http.get(`${TRANSPORT_ACTIVITY_URL}/${pieceId}`);

export const updateTransportActivity = (pieceId: string, data: ITransportActivity) => http.put(`${TRANSPORT_ACTIVITY_URL}/${pieceId}`, {data});

export const publishTransportActivity = (pieceId: string) => http.post(`${TRANSPORT_ACTIVITY_URL}/publish/${pieceId}`);

export const unPublishTransportActivity = (pieceId: string) => http.delete(`${TRANSPORT_ACTIVITY_URL}/unpublish/${pieceId}`);

export const deleteTransportActivity = (pieceId: string) => http.delete(`${TRANSPORT_ACTIVITY_URL}/${pieceId}`);

export const createTransportActivity = (data) => http.post(`${TRANSPORT_ACTIVITY_URL}`, {data});

export const getTransportActivityConfiguration = () => http.get(`${TRANSPORT_ACTIVITY_URL}/configuration`);
