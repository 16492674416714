import { FC, useState } from "react";
import { FormDialog, Button } from "@mondra/ui-components";
import InputItem from "../../../../components/InputItem";
import { useFormik } from "formik";
import {
  getProcessedDropdowns,
  getProduceDropdowns,
  getProductDropdowns,
  getProduct,
  getSpecialIngredientDropdowns,
} from "../api";
import { productSchema } from "../utils";
import { addLocalPopUpNote } from "../../../../shared/noteFunctions";
import { sortDropdown } from "../../../../shared/formFunctions";
import { IAttributes, IGpcAttribute, IGpcBrick } from "../types";
import AttributeSelector from "./AttributeSelector";

interface ComponentFormProps {
  procIngDropdownItems: any;
  specIngDropdownItems: any;
  productType: any[];
  outputProductType: any[];
  addProcess: (variables?: any, array?: string) => void;
}

interface Dropdown {
  id: number;
  code: string;
  name: string;
  level: number;
  parentCode: string;
}

const initialValues = {
  productId: 0,
  classId: 0,
  class: "",
  activityId: 0,
  activity: "",
  categoryId: 0,
  category: "",
  family: "",
  inputIngredientAttributes: [],
  subcategoryId: 0,
  subcategory: "",
  product: "",
  productClass: "",
  productType: 0,
  outputProductId: 0,
  outputProductTypeName: "",
  outputProductType: 0,
  quantity: "",
  quantityNotes: [],
  economicAllocation: "",
  economicAllocationNotes: [],
};

const ProductForm: FC<ComponentFormProps> = ({
  procIngDropdownItems = [],
  specIngDropdownItems = [],
  productType,
  outputProductType,
  addProcess,
}) => {
  const [openForm, setOpenForm] = useState(false);
  const [brick, setBrick] = useState<IGpcBrick>();
  const [gpcAttributes, setGpcAttributes] = useState<[] | IGpcAttribute[]>([]);
  const [family, setFamily] = useState<[] | Dropdown[]>([]);
  const [productClass, setProductClass] = useState<[] | Dropdown[]>([]);
  const [activity, setActivity] = useState<[] | Dropdown[]>([]);
  const [category, setCategory] = useState<[] | Dropdown[]>([]);
  const [subcategory, setSubcategory] = useState<[] | Dropdown[]>([]);
  const [functionalUnit, setFunctionalUnit] = useState<[] | Dropdown[]>([]);

  const formik = useFormik({
    initialValues,
    validationSchema: productSchema,
    onSubmit: (values) => {
      if (gpcAttributes.find((p) => !p.gpcValueId)) {
        return;
      }
      values.productType = +values.productType;
      addProcess(
        { ...values, outputProductAttributes: gpcAttributes },
        "products"
      );
      setTimeout(() => setOpenForm(false), 500);
    },
  });

  const onCreateComponent = () => {
    setOpenForm(true);
    setGpcAttributes([]);
    formik.setValues(initialValues);
    formik.setTouched({}, false);
  };

  const outputType = formik.values.outputProductType;
  return (
    <>
      <Button
        title="Create component"
        iconType="add"
        variant="primary"
        className="mt-5"
        onClick={onCreateComponent}
      >
        Create product
      </Button>
      <FormDialog
        title="Create product"
        formId="component"
        onClose={() => setTimeout(() => setOpenForm(false), 500)}
        primaryBtnText="Create"
        onPrimaryButtonClick={formik.handleSubmit}
        open={openForm}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="piece-content-inputs">
            <InputItem
              name="Output product type"
              description="Please specify the product type of this entry"
              type="select"
              formikName="outputProductType"
              create
              formik={formik}
              showAsterisk
              defaultValue={formik.values.outputProductType}
              onChange={(e) => {
                var value = +(e.target as any).value;
                formik.setFieldValue(
                  "outputProductTypeName",
                  outputProductType.find(
                    (el) => +(e.target as any).value === el.id
                  )?.description
                );
                if (value === 1) {
                  getProductDropdowns("segment").then((res) => {
                    if (res?.data[0]?.id) {
                      getProductDropdowns(
                        `family?segmentId=${res?.data[0]?.id}`
                      ).then((res) => {
                        setFamily(res.data);
                      });
                    }
                  });
                }
                setCategory([]);
                setSubcategory([]);
                setFunctionalUnit([]);
                formik.setFieldValue("outputProductType", value);
              }}
              options={sortDropdown(outputProductType, 0, "description")}
            />
            {outputType == 1 && (
              <InputItem
                name="Family"
                description=""
                type="select"
                formik={formik}
                showAsterisk
                defaultValue={formik.values.family}
                onChange={(e) => {
                  const selectedFamily = family.find(
                    (el) => (e.target as any).value === el.name
                  );
                  formik.setFieldValue("family", selectedFamily?.name);
                  getProductDropdowns(
                    `class?familyId=${selectedFamily?.id}`
                  ).then((res) => {
                    setProductClass(res.data);
                  });
                }}
                options={family.map((el) => ({
                  label: el.name,
                  value: el.name,
                }))}
              />
            )}
            {outputType === 1 && (
              <InputItem
                name="Class"
                description=""
                type="select"
                formik={formik}
                showAsterisk
                disabled={family.length === 0}
                defaultValue={formik.values.productClass}
                onChange={(e) => {
                  const selectedProductClass = productClass.find(
                    (el) => (e.target as any).value === el.name
                  );
                  formik.setFieldValue(
                    "productClass",
                    selectedProductClass?.name
                  );
                  getProductDropdowns(
                    `brick?classId=${selectedProductClass?.id}`
                  ).then((res) => {
                    setFunctionalUnit(res.data);
                  });
                }}
                options={productClass.map((el) => ({
                  label: el.name,
                  value: el.name,
                }))}
              />
            )}
            {(outputType == 2 || outputType == 3) && (
              <InputItem
                name="Class"
                description=""
                type="select"
                formik={formik}
                showAsterisk
                defaultValue={formik.values.class}
                onChange={(e) => {
                  const selectedClass =
                    outputType == 2
                      ? procIngDropdownItems.find(
                          (el) => (e.target as any).value === el.name
                        )
                      : specIngDropdownItems.find(
                          (el) => (e.target as any).value === el.name
                        );
                  formik.setFieldValue("class", selectedClass?.name);
                  formik.setFieldValue("classId", selectedClass?.id);
                  outputType == 2
                    ? getProcessedDropdowns(
                        `activity?classId=${selectedClass?.id}`
                      ).then((res) => setActivity(res.data))
                    : getSpecialIngredientDropdowns(
                        `category?classId=${selectedClass?.id}`
                      ).then((res) => setCategory(res.data));
                }}
                options={
                  outputType == 2
                    ? procIngDropdownItems.map((el) => ({
                        label: el.name,
                        value: el.name,
                      }))
                    : specIngDropdownItems.map((el) => ({
                        label: el.name,
                        value: el.name,
                      }))
                }
                formikName="classId"
              />
            )}
            {outputType == 2 && (
              <InputItem
                name="Activity"
                description=""
                type="select"
                formik={formik}
                showAsterisk
                disabled={activity.length === 0}
                defaultValue={formik.values.activity}
                onChange={(e) => {
                  const selectedActivity = activity.find(
                    (el) => (e.target as any).value === el.name
                  );

                  formik.setFieldValue("activity", selectedActivity?.name);
                  formik.setFieldValue("activityId", selectedActivity?.id);
                  getProduceDropdowns(
                    `category?classId=${formik.values.classId}`
                  ).then((res) => setCategory(res.data));
                }}
                options={activity.map((el) => ({
                  label: el.name,
                  value: el.name,
                }))}
                formikName="activityId"
              />
            )}
            {(outputType == 2 || outputType == 3) && (
              <InputItem
                name="Category"
                description=""
                type="select"
                formik={formik}
                showAsterisk
                disabled={category.length === 0}
                defaultValue={formik.values.category}
                onChange={(e) => {
                  const selectedCategory = category.find(
                    (el) => (e.target as any).value === el.name
                  );
                  formik.setFieldValue("category", selectedCategory?.name);
                  formik.setFieldValue("categoryId", selectedCategory?.id);
                  outputType == 2
                    ? getProduceDropdowns(
                        `subcategory?categoryId=${selectedCategory?.id}`
                      ).then((res) => setSubcategory(res.data))
                    : getSpecialIngredientDropdowns(
                        `functionalUnit?categoryId=${selectedCategory?.id}`
                      ).then((res) => setFunctionalUnit(res.data));
                }}
                options={category.map((el) => ({
                  label: el.name,
                  value: el.name,
                }))}
                formikName="categoryId"
              />
            )}
            {outputType == 2 && (
              <InputItem
                name="Subcategory"
                description=""
                type="select"
                formik={formik}
                showAsterisk
                disabled={subcategory.length === 0}
                defaultValue={formik.values.subcategory}
                onChange={(e) => {
                  const selectedSubCat = subcategory.find(
                    (el) => (e.target as any).value === el.name
                  );
                  formik.setFieldValue("subcategory", selectedSubCat?.name);
                  formik.setFieldValue("subcategoryId", selectedSubCat?.id);
                  getProcessedDropdowns(
                    `functionalUnit?produceSubcategoryId=${selectedSubCat?.id}&activityId=${formik.values.activityId}`
                  ).then((res) => setFunctionalUnit(res.data));
                }}
                options={subcategory.map((el) => ({
                  label: el.name,
                  value: el.name,
                }))}
                formikName="subcategoryId"
              />
            )}
            <InputItem
              name={
                outputType === 1
                  ? "Brick Name"
                  : outputType === 2
                  ? "Processed Ingredient Name"
                  : "Special Ingredient Name"
              }
              description=""
              type="select"
              defaultValue={formik.values.outputProductId}
              formik={formik}
              showAsterisk
              disabled={functionalUnit.length === 0}
              onChange={(e) => {
                const selectedFunctionalUnit = functionalUnit.find(
                  (el) => +(e.target as any).value === el.id
                );
                if (outputType === 1) {
                  getProduct(`${selectedFunctionalUnit?.id}`).then((res) =>
                    setBrick(res.data)
                  );
                }
                setGpcAttributes([]);
                formik.setFieldValue("product", selectedFunctionalUnit?.name);
                formik.setFieldValue(
                  "outputProductId",
                  selectedFunctionalUnit?.id
                );
              }}
              formikName="outputProductId"
              options={functionalUnit.map((el) => ({
                label: el.name,
                value: el.id,
              }))}
            />
            {brick && (
              <AttributeSelector
                brick={brick}
                attributes={brick.attributes}
                gpcAttributes={gpcAttributes}
                setGpcAttributes={setGpcAttributes}
              />
            )}
            <InputItem
              name="Product type"
              description=""
              type="select"
              formik={formik}
              showAsterisk
              defaultValue={formik.values.productType}
              onChange={formik.handleChange}
              formikName="productType"
              options={productType.map((el) => ({
                label: el.description,
                value: el.id,
              }))}
            />
            <InputItem
              name="Quantity"
              description="Please provide the quantity of the ingredient in kg"
              type="input"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={formik.values.quantity}
              addonTitle="kg"
              formikName="quantity"
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.quantityNotes.length,
                  ...params,
                })
              }
              notes={formik.values.quantityNotes || []}
            />
            <InputItem
              name="Economic Allocation"
              description="Please provide the economic allocation in %"
              type="input"
              addonTitle="%"
              formik={formik}
              showAsterisk
              onChange={formik.handleChange}
              defaultValue={formik.values.economicAllocation}
              formikName="economicAllocation"
              addLocal={(params) =>
                addLocalPopUpNote({
                  formik,
                  index: formik.values.economicAllocationNotes.length,
                  ...params,
                })
              }
              notes={formik.values.economicAllocationNotes || []}
            />
          </div>
        </form>
      </FormDialog>
    </>
  );
};

export default ProductForm;
