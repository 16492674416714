import { FC, useEffect, useState, EffectCallback, useMemo } from "react";
import { useAppDispatch } from "../../../hooks/redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Icon,
  Input,
  LottieLoader,
  Notification,
  Checkbox,
  Label,
} from "@mondra/ui-components";
import { useAppSelector } from "../../../hooks/redux";
import { useFormik } from "formik";
import { fetchProcess, getConfiguration } from "./transportActions";
import { sortDropdown } from "../../../shared/formFunctions";
import { findParentWithChildId, formSchema } from "./utils";
import {
  updateProcessingActivity,
  publishProcessingActivity,
  unPublishProcessingActivity,
  createProcessingActivity,
  getProcessedClass,
  getSpecialIngredientClass,
} from "./api";

import { usePrompt } from "../../../hooks/usePrompt";

import {
  handlePublishStatus,
  setDefaultProcess,
} from "../../../redux/processSlice";

import InputItem from "../../../components/InputItem";
import Collapsible from "react-collapsible";
import IngredientsForm from "./components/IngredientsForm";
import ResourceForm from "./components/ResourceForm";
import ProductForm from "./components/ProductForm";
import WasteForm from "./components/WasteForm";
import EmissionToAirForm from "./components/EmissionToAirForm";
import { addLocalFormNote } from "../../../shared/noteFunctions";
import { showCorrelationIdError } from "../../../redux/correlationIdSlice";
import "./index.scss";
import AttributeDetails from "./components/AttributeDetails";
import ActivityRightPanel from "../../../components/ActivityRightPanel";
import { ActivityTypes, ROUTES } from "../../../const";

const Process: FC = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { loading, process, configuration } = useAppSelector(
    (state) => state.processSlice
  );
  const { loading: companiesLoading } = useAppSelector(
    (state) => state.companiesSlice
  );
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [showError, setShowError] = useState<string>("");
  const [errors, setErrors] = useState<[any, string[]][]>();
  const [showPublished, setShowPublished] = useState<boolean>(false);
  const [procIngDropdownItems, setProcIngDropdownItems] = useState([]);
  const [specIngDropdownItems, setSpecIngDropdownItems] = useState([]);
  const [mainProductDetails, setMainProductDetails] = useState<any>();
  let mainCategory, mainActivity;
  let processId = searchParams.get("itemId") || "";

  const isForWasteResourceSources = useMemo(() => {
    return configuration.resourceSources.filter((el) => el.isForWaste);
  }, [configuration.resourceSources]);

  const isEmissionToAirSources = useMemo(() => {
    return configuration.resourceSources.filter((el) => el.isEmissionToAir);
  }, [configuration.resourceSources]);

  useEffect((): ReturnType<EffectCallback> => {
    if (!companiesLoading) {
      if (processId == "") {
        navigate("/not-found");
      }
      dispatch(getConfiguration());
      if (processId != "new") {
        dispatch(
          fetchProcess(processId, () => navigate(ROUTES.processingActivity))
        );
      }
      getProcessedClass().then((res) => setProcIngDropdownItems(res.data));
      getSpecialIngredientClass().then((res) =>
        setSpecIngDropdownItems(res.data)
      );
      return () => {
        dispatch(setDefaultProcess());
      };
    }
  }, [companiesLoading]);

  useEffect(() => {
    formik.setValues({ ...process });
    if (process.products.length > 0 && configuration) {
      const main = process.products.find((el) => el.productType === 0);
      const mainProduct = findParentWithChildId(
        main?.outputProductType,
        main?.outputProductId,
        configuration
      );
      setMainProductDetails(mainProduct);
    }
  }, [configuration, process]);

  const formik = useFormik({
    initialValues: {
      ...process,
    },
    validationSchema: formSchema,
    // enableReinitialize: true,
    onSubmit: () => {
      if (process.state === 0) {
        publishProcessingActivity(processId)
          .then(() => {
            dispatch(handlePublishStatus(1));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data.title));
      } else {
        unPublishProcessingActivity(processId)
          .then(() => {
            dispatch(handlePublishStatus(0));
            setShowPublished(true);
            setTimeout(() => {
              setShowPublished(false);
            }, 4000);
          })
          .catch((error) => setShowError(error.response.data.title));
      }
    },
  });

  const saveDraft = async (e) => {
    e.preventDefault();
    const { values } = formik;

    values.companyId =
      values.companyId && +values.companyId === (0 || 1)
        ? null
        : values.companyId;
    values.regionIdentifierId = +values.regionIdentifierId;
    values.countryId = values.referenceSourcingCountry
      ? +values.referenceSourcingCountry
      : null;
    values.code = values.code === "" ? null : values.code;
    values.clickUpId = values.clickUpId?.trim()
      ? values.clickUpId?.trim()
      : null;
    values.referenceDataPublicationYear = values.referenceDataPublicationYear
      ? +values.referenceDataPublicationYear
      : null;
    values.referenceRegionIdentifier =
      !values.referenceRegionIdentifier &&
      values.referenceRegionIdentifier !== 0
        ? null
        : +values.referenceRegionIdentifier;
    values.secondaryDataReferenceSourceType =
      !values.secondaryDataReferenceSourceType &&
      values.secondaryDataReferenceSourceType !== 0
        ? null
        : +values.secondaryDataReferenceSourceType;
    values.referencesSamplingYear = values.referencesSamplingYear
      ? Number(values.referencesSamplingYear)
      : null;
    values.referenceSourcingCountry =
      !values.referenceSourcingCountry && values.referenceSourcingCountry !== 0
        ? null
        : +values.referenceSourcingCountry;
    values.referenceTechnologyProxyContribution =
      values.referenceTechnologyProxyContribution !== null
        ? +values.referenceTechnologyProxyContribution
        : null;
    for (let i = 0; i < values.products.length; i++) {
      if (typeof values.products[i].productType === "string") {
        values.products[i].productType = +values.products[i].productType;
      }
    }
    for (let i = 0; i < values.resourceSources.length; i++) {
      if (typeof values.resourceSources[i].resourceSourceId === "string") {
        values.resourceSources[i].resourceSourceId =
          +values.resourceSources[i].resourceSourceId;
      }
    }
    if (processId === "new") {
      try {
        await createProcessingActivity(values).then(() => {
          navigate("/processing-activity");
        });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    } else {
      try {
        await updateProcessingActivity(processId, values)
          .then(() =>
            dispatch(
              fetchProcess(processId, () => navigate("/processing-activity"))
            )
          )
          .then(() => {
            setShowNotification(true);
            setTimeout(() => {
              setShowNotification(false);
            }, 5000);
          });
      } catch (error: any) {
        if (error.showCorrelationId) {
          dispatch(showCorrelationIdError(error.CorrelationId));
        } else {
          setShowError(
            typeof error.response.data === "string"
              ? error.response.data
              : typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.values(error.response.data.errors)
                  .toString()
                  .replace(/!,/g, "!\n")
          );
          setTimeout(() => {
            setShowError("");
          }, 5000);
          setErrors(
            typeof error.response.data.errors === "string"
              ? error.response.data.errors
              : Object.entries(error.response.data.errors)
          );
        }
      }
    }
  };

  const disableRequest =
    formik.values.ingredients.length === 0 ||
    formik.values.products.length === 0 ||
    formik.values.resourceSources.length === 0 ||
    formik.isSubmitting;

  usePrompt(
    "There are unsaved changes, do you want to leave?",
    processId !== "new"
      ? JSON.stringify(process) != JSON.stringify(formik.values)
      : false
  );

  const addProcess = (values, array) => {
    formik.setValues({
      ...formik.values,
      [array]: [...formik.values[array], values],
    });
  };

  const deleteProcess = (idx, array) => {
    const newComponents = formik.values[array].filter(
      (el, index) => index != +idx
    );
    formik.setValues({
      ...formik.values,
      [array]: newComponents,
    });
  };

  let totalMass = 0;
  formik.values.ingredients.forEach(
    (el) => (totalMass += el.includeInTotalIngredientMass ? +el.quantity : 0)
  );

  let totalProductWasteMass = 0;
  formik.values.products.forEach(
    (el) => (totalProductWasteMass += +el.quantity)
  );
  formik.values.waste.forEach((el) => (totalProductWasteMass += +el.quantity));
  formik.values.emissions.forEach(
    (el) => (totalProductWasteMass += +el.quantity)
  );

  return loading !== true || processId === "new" ? (
    <>
      <div className="piece content">
        {showError !== "" && (
          <div className={"piece-notification"}>
            <Notification type="error" label="Oops:" description={showError} />
          </div>
        )}
        {showPublished && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Status was updated!"
              isToast={false}
            />
          </div>
        )}
        {showNotification && (
          <div className={"piece-notification"}>
            <Notification
              type="success"
              label="Success:"
              description="Item was updated!"
              isToast={false}
            />
          </div>
        )}
        <div className="mt-3 mb-3">
          <Breadcrumbs
            routes={[
              {
                path: "/processing-activity",
                title: "Processing Activity Table",
              },
              {
                path: `/transport-activity/process?itemId=${processId}`,
                title: "Processing Activity Item",
              },
            ]}
          />
        </div>
        {processId !== "new" && (
          <>
            <div className="piece-header">
              <div
                className={`piece-header-status ${
                  process.state === 0 ? "bg-red-500" : "bg-emerald-500"
                }`}
              >
                {process.state === 0 ? "Not Published" : "Published"}
              </div>
              {process?.lastPublishedDate ? (
                <span className={"inputItem-header"}>
                  {`Last published date: ${new Date(
                    process.lastPublishedDate
                  ).toLocaleString()}
                  `}
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        )}

        <p className={"decoration-gray-600 mt-1 mb-7 piece-description"}>
          This database is a collation of resource type and consumption involved
          in the processing of different food products. All processing activity
          data has been reported for a functional unit of{" "}
          <b>1 kg of the key product</b>.
        </p>
        <form onSubmit={formik.handleSubmit} className="piece-content">
          <h5 className={"mt-5 mb-5"}>Activity Type</h5>
          <h3>Activity Name</h3>
          <p className={"decoration-gray-600 mb-1"}>
            Please indicate the name of the processing as a combination of the
            functional unit output, and the process flows separated by a comma.
            E.g. Beetroot powder - Washing, Slicing, Extracting, Spray Drying.
          </p>
          <Input
            disabled={true}
            className={"w-full h-20 mt-2 mb-5"}
            value={
              mainProductDetails?.functionalUnit?.name &&
              mainProductDetails?.functionalUnit?.name +
                " - " +
                formik.values.processFlow
            }
          />
          <InputItem
            type="input"
            name={"Process Flow"}
            description="Please review the excel file called
              “Mondra_ProcessingActivities_Ontology” to list the process flow
              steps that this activity goes through, separate by a comma. E.g.
              Mixing, Shaping, Cream Filling, Baking, Cleaning, Cooling."
            className={"w-full mb-5"}
            formik={formik}
            showAsterisk
            defaultValue={formik.values.processFlow}
            onChange={formik.handleChange}
            formikName="processFlow"
          />
          <InputItem
            type="input"
            name={"Click up ID"}
            description="Please provide the URL to the click-up task "
            className={"w-full mb-5"}
            formik={formik}
            defaultValue={formik.values.clickUpId || ""}
            onChange={formik.handleChange}
            formikName="clickUpId"
          />
          <InputItem
            type="input"
            name={"Additional Supplementary Information"}
            description="Please add the Processing LCI file URL for any additional calculation, or information about modification on existing Agri-footprint and Ecoinvent processes"
            className={"w-full mb-5"}
            defaultValue={
              formik.values.additionalSupplementaryInformation || ""
            }
            onChange={formik.handleChange}
            formikName="additionalSupplementaryInformation"
          />
          <InputItem
            name="External code"
            description=""
            type="input"
            disabled
            className={"w-full h-20 mt-2 mb-5"}
            formik={formik}
            onChange={formik.handleChange}
            defaultValue={formik.values.code || ""}
            formikName="code"
          />
          <div className={"piece-content-collapsible"}>
            <h2>Inputs</h2>
            <h5 className={"mt-5"}>Ingredients</h5>
            {formik.values.ingredients.length === 0 && (
              <span className="text-rose-600">
                You should have at least one Ingredient
              </span>
            )}
            <br />
            {formik.values.ingredients &&
              formik.values.ingredients.map((component, index: number) => {
                const {
                  activity,
                  class: cls,
                  family,
                  category,
                  functionalUnit,
                  subCategory,
                } = findParentWithChildId(
                  component.inputIngredientType,
                  component.inputIngredientId,
                  configuration
                );
                return (
                  <Collapsible
                    key={
                      "input-ingredient-" +
                      component.inputIngredientType +
                      "-" +
                      component.inputIngredientId
                    }
                    trigger={
                      <div className="collapsible_trigger">
                        <span>{`${
                          component.functionalUnit || functionalUnit?.name
                        } - ${component.quantity} Kg`}</span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    <div className="piece-content-inputs">
                      <InputItem
                        disabled={true}
                        name="Ingredient Type"
                        description="Read only"
                        type="input"
                        defaultValue={
                          component.ingredientType ||
                          configuration?.inputIngredientTypes?.find(
                            (t) => t.id === component.inputIngredientType
                          )?.description
                        }
                      />
                      {component?.inputIngredientType === 2 && (
                        <InputItem
                          disabled={true}
                          name="Activity"
                          description="Read only"
                          type="input"
                          defaultValue={component.activity || activity?.name}
                        />
                      )}
                      {component.inputIngredientType === 1 && (
                        <InputItem
                          disabled={true}
                          name="Family"
                          description="Read only"
                          type="input"
                          defaultValue={component.family || family?.name}
                        />
                      )}
                      {component.inputIngredientType === 1 && (
                        <InputItem
                          disabled={true}
                          name="Product class"
                          description="Read only"
                          type="input"
                          defaultValue={component.productClass || cls?.name}
                        />
                      )}
                      {component.inputIngredientType !== 1 && (
                        <InputItem
                          disabled={true}
                          name="Category"
                          description="Read only"
                          type="input"
                          defaultValue={component.categogy || category?.name}
                        />
                      )}
                      {component.inputIngredientType !== 1 &&
                        component.inputIngredientType !== 3 && (
                          <InputItem
                            disabled={true}
                            name="Sub Category"
                            description="Read only"
                            type="input"
                            defaultValue={
                              component.subCategory || subCategory?.name
                            }
                          />
                        )}
                      <InputItem
                        disabled={true}
                        name={
                          component.inputIngredientType === 1
                            ? "Brick Name"
                            : "Ingredient Name"
                        }
                        description="Read only"
                        type="input"
                        defaultValue={
                          component.functionalUnit || functionalUnit?.name
                        }
                      />
                      {component.inputIngredientAttributes?.length > 0 && (
                        <AttributeDetails
                          attributes={component.inputIngredientAttributes}
                          brickName={
                            component.functionalUnit || functionalUnit?.name
                          }
                        />
                      )}
                      <InputItem
                        name="Quantity"
                        description="Please provide the quantity of the ingredient in kg"
                        type="input"
                        formik={formik}
                        showAsterisk
                        addonTitle="kg"
                        onChange={(e) => {
                          formik.setFieldTouched(
                            `ingredients[${index}].quantity`
                          );
                          formik.handleChange(e);
                        }}
                        defaultValue={component.quantity}
                        formikName={`ingredients[${index}].quantity`}
                        notes={component.quantityNotes || []}
                        addLocal={(params) =>
                          addLocalFormNote({
                            formik: formik,
                            arrayName: "ingredients",
                            componentIndex: index,
                            index: component.quantityNotes?.length,
                            ...params,
                          })
                        }
                        index={index}
                      />
                      <InputItem
                        name="Corresponding Process"
                        description="Please review the excel file called “Mondra_ProcessingActivities_Ontology” to list the corresponding process for this ingredient E.g. Mixing, Shaping, Cream Filling, Baking, Cleaning, Cooling."
                        type="input"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldTouched(
                            `ingredients[${index}].correspondingProcess`
                          );
                          formik.handleChange(e);
                        }}
                        defaultValue={component.correspondingProcess}
                        formikName={`ingredients[${index}].correspondingProcess`}
                        index={index}
                      />
                      <div className="inputItem mx-1 mt-6">
                        <div className="flex justify-start items-center gap-3">
                          <Label
                            className={`w-72 flex-wrap`}
                            title="Include in batch input weight"
                          >
                            <Checkbox
                              id={
                                "include-" +
                                component.inputIngredientType +
                                "-" +
                                component.inputIngredientId
                              }
                              key={
                                "include-" +
                                component.inputIngredientType +
                                "-" +
                                component.inputIngredientId
                              }
                              checked={component.includeInTotalIngredientMass}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `ingredients[${index}].includeInTotalIngredientMass`,
                                  e.currentTarget.checked
                                );
                                formik.setFieldTouched(
                                  `ingredients[${index}].includeInTotalIngredientMass`
                                );
                                formik.handleChange(e);
                              }}
                              size={18}
                            />
                            Include in batch input weight
                          </Label>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={() => deleteProcess(index, "ingredients")}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </div>
                  </Collapsible>
                );
              })}
            <IngredientsForm
              addProcess={addProcess}
              configuration={configuration}
            />
          </div>
          <h3>Batch Input Weight</h3>
          <p className={"decoration-gray-600 mb-1"}>
            Sum of weight of ingredients that go in as raw materials at the
            start of the activity. Read only
          </p>
          <Input
            disabled={true}
            className={"w-full h-20 mt-2 mb-5"}
            value={totalMass}
            addonTitle="kg"
          />
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Resources</h5>
            {formik.values.resourceSources.length === 0 && (
              <span className="text-rose-600">
                You should have at least one Resource
              </span>
            )}
            <br />
            {formik.values.resourceSources &&
              formik.values.resourceSources.map((component, index: number) => {
                return (
                  <Collapsible
                    key={index + component.id}
                    trigger={
                      <div className="collapsible_trigger">
                        <span>
                          {
                            configuration.resourceSources.find(
                              (el) => el.id === +component.resourceSourceId
                            )?.combinedName
                          }
                        </span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    <div className="piece-content-inputs">
                      <InputItem
                        name="Resource & Source"
                        description=""
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        defaultValue={
                          formik.values.resourceSources[index].resourceSourceId
                            ? formik.values.resourceSources[index]
                                .resourceSourceId
                            : -1
                        }
                        formikName={`resourceSources[${index}].resourceSourceId`}
                        options={sortDropdown(
                          configuration.resourceSources,
                          component.resourceSourceId,
                          "combinedName"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Resource + Source ID"
                        description=""
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        defaultValue={
                          formik.values.resourceSources[index].resourceSourceId
                            ? formik.values.resourceSources[index]
                                .resourceSourceId
                            : -1
                        }
                        formikName={`resourceSources[${index}].resourceSourceId`}
                        options={sortDropdown(
                          configuration.resourceSources,
                          component.resourceSourceId,
                          "combinedId"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Quantity"
                        description="Please provide the quantity of the resource source in the displayed unit"
                        type="input"
                        formik={formik}
                        addonTitle={
                          configuration.resourceSources.find(
                            (el) =>
                              el.id ===
                              +formik.values.resourceSources[index]
                                .resourceSourceId
                          )?.unit
                        }
                        onChange={(e) => {
                          formik.setFieldTouched(
                            `resourceSources[${index}].quantity`
                          );
                          formik.handleChange(e);
                        }}
                        defaultValue={component.quantity}
                        formikName={`resourceSources[${index}].quantity`}
                        notes={component.quantityNotes || []}
                        addLocal={(params) =>
                          addLocalFormNote({
                            formik: formik,
                            arrayName: "resourceSources",
                            componentIndex: index,
                            index: component.quantityNotes?.length,
                            ...params,
                          })
                        }
                        index={index}
                      />
                      <InputItem
                        name="Corresponding Process"
                        description="Please review the excel file called “Mondra_ProcessingActivities_Ontology” to list the corresponding process for this ingredient E.g. Mixing, Shaping, Cream Filling, Baking, Cleaning, Cooling."
                        type="input"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldTouched(
                            `resourceSources[${index}].correspondingProcess`
                          );
                          formik.handleChange(e);
                        }}
                        defaultValue={component.correspondingProcess}
                        formikName={`resourceSources[${index}].correspondingProcess`}
                        index={index}
                      />
                    </div>
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={() => deleteProcess(index, "resourceSources")}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </div>
                  </Collapsible>
                );
              })}
            <ResourceForm
              addProcess={addProcess}
              resourceSources={configuration.resourceSources}
            />
          </div>
          <div className={"piece-content-collapsible"}>
            <h2>Outputs</h2>
            <h5 className={"mt-5"}>Products</h5>
            {formik.values.products.length === 0 && (
              <span className="text-rose-600">
                You should have at least one Main product
              </span>
            )}
            <br />
            {formik.values.products &&
              formik.values.products.map((component, index: number) => {
                const {
                  class: cls,
                  activity,
                  category,
                  family,
                  functionalUnit,
                  subCategory,
                } = findParentWithChildId(
                  component.outputProductType,
                  component.outputProductId,
                  configuration
                );
                //work around as these fields are not coming from api
                if (component.productType === 0) {
                  mainCategory = category?.name;
                  mainActivity = activity?.name;
                }
                return (
                  <Collapsible
                    key={index + component.id}
                    trigger={
                      <div className="collapsible_trigger">
                        <span>{component.product || functionalUnit?.name}</span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    <div className="piece-content-inputs">
                      <InputItem
                        disabled={true}
                        name="Output product type"
                        description="Read only"
                        type="input"
                        defaultValue={
                          configuration?.outputProductTypes?.find(
                            (t) => t.id === component.outputProductType
                          )?.description
                        }
                      />
                      {component.outputProductType === 1 && (
                        <InputItem
                          disabled={true}
                          name="Family"
                          description="Read only"
                          type="input"
                          defaultValue={component.family || family?.name}
                        />
                      )}
                      {component.outputProductType === 1 && (
                        <InputItem
                          disabled={true}
                          name="Product class"
                          description="Read only"
                          type="input"
                          defaultValue={component.productClass || cls?.name}
                        />
                      )}
                      {(component.outputProductType === 2 ||
                        component.outputProductType === 3) && (
                        <InputItem
                          disabled={true}
                          name="Class"
                          description="Read only"
                          type="input"
                          defaultValue={component.class || cls?.name}
                        />
                      )}
                      {component.outputProductType === 2 && (
                        <InputItem
                          disabled={true}
                          name="Activity"
                          description="Read only"
                          type="input"
                          defaultValue={component.activity || activity?.name}
                        />
                      )}
                      {(component.outputProductType === 2 ||
                        component.outputProductType === 3) && (
                        <InputItem
                          disabled={true}
                          name="Category"
                          description="Read only"
                          type="input"
                          defaultValue={component.category || category?.name}
                        />
                      )}
                      {component.outputProductType === 2 && (
                        <InputItem
                          disabled={true}
                          name="Subcategory"
                          description="Read only"
                          type="input"
                          defaultValue={
                            component.subcategory || subCategory?.name
                          }
                        />
                      )}
                      <InputItem
                        disabled={true}
                        name={
                          component.outputProductType === 1
                            ? "Brick Name"
                            : "Product"
                        }
                        description="Read only"
                        type="input"
                        defaultValue={component.product || functionalUnit?.name}
                      />
                      {component.outputProductAttributes?.length > 0 && (
                        <AttributeDetails
                          attributes={component.outputProductAttributes}
                          brickName={component.product || functionalUnit?.name}
                        />
                      )}
                      <InputItem
                        name="Product type"
                        description=""
                        type="select"
                        formik={formik}
                        defaultValue={component.productType}
                        onChange={formik.handleChange}
                        formikName={`products[${index}].productType`}
                        options={sortDropdown(
                          configuration.productTypes,
                          component.productType,
                          "description"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Quantity"
                        description="Please provide the quantity of the ingredient in kg"
                        type="input"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldTouched(`products[${index}].quantity`);
                          formik.handleChange(e);
                        }}
                        addonTitle="kg"
                        defaultValue={component.quantity}
                        formikName={`products[${index}].quantity`}
                        notes={component.quantityNotes || []}
                        addLocal={(params) =>
                          addLocalFormNote({
                            formik: formik,
                            arrayName: "products",
                            componentIndex: index,
                            index: component.quantityNotes?.length,
                            ...params,
                          })
                        }
                        index={index}
                      />
                      <InputItem
                        name="Economic Allocation"
                        description="Please provide the economic allocation in %"
                        type="input"
                        addonTitle="%"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldTouched(
                            `products[${index}].economicAllocation`
                          );
                          formik.handleChange(e);
                        }}
                        defaultValue={component.economicAllocation}
                        formikName={`products[${index}].economicAllocation`}
                        notes={component.economicAllocationNotes || []}
                        addLocal={(params) =>
                          addLocalFormNote({
                            formik: formik,
                            arrayName: "products",
                            componentIndex: index,
                            index: component.economicAllocationNotes?.length,
                            ...params,
                          })
                        }
                        index={index}
                      />
                    </div>
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={() => deleteProcess(index, "products")}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </div>
                  </Collapsible>
                );
              })}
            <ProductForm
              procIngDropdownItems={procIngDropdownItems}
              specIngDropdownItems={specIngDropdownItems}
              addProcess={addProcess}
              productType={configuration.productTypes}
              outputProductType={configuration.outputProductTypes}
            />
            <br />
            <br />
            {formik.values.products.filter((el) => el.productType == 0).length >
              1 && (
              <span className="formik-error">
                You must have only one Main product!
              </span>
            )}
            {formik.values.products.length > 0 &&
              formik.values.products.filter((el) => el.productType == 0)
                .length === 0 && (
                <span className="formik-error">
                  You must have at least one Main product!
                </span>
              )}
          </div>
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Waste</h5>
            <br />
            {formik.values.waste &&
              formik.values.waste.map((component, index: number) => {
                return (
                  <Collapsible
                    key={index + component.id}
                    trigger={
                      <div className="collapsible_trigger">
                        <span>
                          {
                            isForWasteResourceSources.find(
                              (el) => el.id === +component.resourceSourceId
                            )?.combinedName
                          }
                        </span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    <div className="piece-content-inputs">
                      <InputItem
                        key={
                          formik.values.waste[index].resourceSourceId
                            ? formik.values.waste[index].resourceSourceId +
                              Math.random()
                            : Math.random()
                        }
                        name="Waste type"
                        description="Please specify what kind of waste was generated during the process. Please provide the waste in the units displayed. Wastewater density can be assumed to be similar to water, for oil waste please consider an average density of 914.5 kg/m3."
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        formikName={`waste[${index}].resourceSourceId`}
                        defaultValue={
                          formik.values.waste[index].resourceSourceId
                            ? formik.values.waste[index].resourceSourceId
                            : -1
                        }
                        options={sortDropdown(
                          isForWasteResourceSources,
                          component.resourceSourceId,
                          "combinedName"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Waste ID"
                        description=""
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        formikName={`waste[${index}].resourceSourceId`}
                        defaultValue={
                          formik.values.waste[index].resourceSourceId
                            ? formik.values.waste[index].resourceSourceId
                            : -1
                        }
                        options={sortDropdown(
                          isForWasteResourceSources,
                          component.resourceSourceId,
                          "combinedId"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Quantity"
                        description="Please provide the quantity of the waste in the displayed unit"
                        type="input"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldTouched(`waste[${index}].quantity`);
                          formik.handleChange(e);
                        }}
                        addonTitle={
                          configuration.resourceSources.find(
                            (el) =>
                              el.id ===
                              +formik.values.waste[index].resourceSourceId
                          )?.unit
                        }
                        defaultValue={component.quantity}
                        formikName={`waste[${index}].quantity`}
                        notes={component.quantityNotes || []}
                        addLocal={(params) =>
                          addLocalFormNote({
                            formik: formik,
                            arrayName: "waste",
                            componentIndex: index,
                            index: component.quantityNotes?.length,
                            ...params,
                          })
                        }
                      />
                    </div>
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={() => deleteProcess(index, "waste")}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </div>
                  </Collapsible>
                );
              })}
            <WasteForm
              addProcess={addProcess}
              resourceSources={isForWasteResourceSources}
            />
          </div>
          <div className={"piece-content-collapsible"}>
            <h5 className={"mt-5"}>Emission</h5>
            <br />
            {formik.values.emissions &&
              formik.values.emissions.map((component, index: number) => {
                return (
                  <Collapsible
                    key={index + component.id}
                    trigger={
                      <div className="collapsible_trigger">
                        <span>
                          {
                            isEmissionToAirSources.find(
                              (el) => el.id === +component.resourceSourceId
                            )?.combinedName
                          }
                        </span>
                        <Icon className="collapsible_icon" type="chevronDown" />
                      </div>
                    }
                  >
                    <div className="piece-content-inputs">
                      <InputItem
                        name="Emission type"
                        description="Please specify what kind of emission to air was generated during the process. For evaporation losses please consider the density of steam at 1 bar (0.59 kg/m3)"
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        formikName={`emissions[${index}].resourceSourceId`}
                        defaultValue={
                          formik.values.emissions[index].resourceSourceId
                            ? formik.values.emissions[index].resourceSourceId
                            : -1
                        }
                        options={sortDropdown(
                          isEmissionToAirSources,
                          component.resourceSourceId,
                          "combinedName"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Emission ID"
                        description=""
                        type="select"
                        formik={formik}
                        onChange={formik.handleChange}
                        formikName={`emissions[${index}].resourceSourceId`}
                        defaultValue={
                          formik.values.emissions[index].resourceSourceId
                            ? formik.values.emissions[index].resourceSourceId
                            : -1
                        }
                        options={sortDropdown(
                          isEmissionToAirSources,
                          component.resourceSourceId,
                          "combinedId"
                        )}
                        index={index}
                      />
                      <InputItem
                        name="Quantity"
                        description="Please provide the quantity of the emission in the displayed unit"
                        type="input"
                        formik={formik}
                        onChange={(e) => {
                          formik.setFieldTouched(
                            `emissions[${index}].quantity`
                          );
                          formik.handleChange(e);
                        }}
                        addonTitle={
                          configuration.resourceSources.find(
                            (el) =>
                              el.id ===
                              +formik.values.emissions[index].resourceSourceId
                          )?.unit
                        }
                        defaultValue={component.quantity}
                        formikName={`emissions[${index}].quantity`}
                        notes={component.quantityNotes || []}
                        addLocal={(params) =>
                          addLocalFormNote({
                            formik: formik,
                            arrayName: "emissions",
                            componentIndex: index,
                            index: component.quantityNotes?.length,
                            ...params,
                          })
                        }
                      />
                    </div>
                    <div className="flex justify-end mt-4">
                      <Button
                        onClick={() => deleteProcess(index, "emissions")}
                        variant="danger"
                        iconType="close"
                      >
                        Delete
                      </Button>
                    </div>
                  </Collapsible>
                );
              })}
            <EmissionToAirForm
              addProcess={addProcess}
              resourceSources={isEmissionToAirSources}
            />
          </div>
          <h3>Total output</h3>
          <p className={"decoration-gray-600 mb-1"}>
            Please use as sense check for mass balance. The total input should
            equal the total output.
          </p>
          <Input
            disabled={true}
            className={"w-full h-20 mt-2 mb-5"}
            value={totalProductWasteMass}
            addonTitle="kg"
          />
          <h5 className={"mt-5"}>Data Quality</h5>
          <div className={"piece-content-inputs mb-5"}>
            <InputItem
              name="Secondary Data Reference Source Type"
              description="If multiple references sources where used to generate this record please indicate the overall reference source type by select the reference where the majority of the data was retrieved from, if this is not possible please select the source type with the lowest ranking. If this is not possible take the reference which was published dating back the longest. Please note “Peer-reviewed published literature” can be a journal, conference publication of book chapter."
              type="select"
              showAsterisk
              defaultValue={formik.values.secondaryDataReferenceSourceType}
              onChange={formik.handleChange}
              formik={formik}
              formikName="secondaryDataReferenceSourceType"
              options={sortDropdown(
                configuration.secondaryDataReferenceSourceType,
                process.secondaryDataReferenceSourceType ?? -1,
                "description"
              )}
            />
            <InputItem
              disabled
              name="Data quality reliability score"
              description="Read only"
              type="input"
              formik={formik}
              defaultValue={formik.values.dataQualityReliabilityScore || ""}
              formikName="dataQualityReliabilityScore"
            />
            <InputItem
              name="Sampling End Date"
              description="The date in which the site in its current form ceased to exist or in which data sampling ended in YYYY. If the data was equally collected from multiple studies please select the sourcing end year from the study published the furthest in the past."
              type="input"
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={formik.values.referencesSamplingYear || ""}
              formikName="referencesSamplingYear"
            />
            <InputItem
              name="Reference Data Publication Year"
              description="The year in which most of the data was published. If the data was equally collected from multiple studies please select the publication year from the study published the furthest in the past. "
              type="input"
              onChange={formik.handleChange}
              showAsterisk
              formik={formik}
              defaultValue={formik.values.referenceDataPublicationYear || ""}
              formikName="referenceDataPublicationYear"
            />
            <InputItem
              name="Reference Sourcing Country"
              description="Please select the Country Name identifier in which the data was collected (not published). Please select the country where most of the data was collected from. Only use publication country if no other information is available."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryName"
              )}
            />
            <InputItem
              name="Reference Country ISO Code"
              description="Please select the Country Name identifier in which the data was collected (not published)."
              type="select"
              defaultValue={
                formik.values.referenceSourcingCountry
                  ? formik.values.referenceSourcingCountry
                  : -1
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="referenceSourcingCountry"
              options={sortDropdown(
                configuration.countries,
                formik.values.referenceSourcingCountry ?? -1,
                "countryISO"
              )}
            />
            <InputItem
              name="Reference Region Identifier"
              description="The region identifier in which the data was collected. Please select the region where most of the data was collected from."
              type="select"
              onChange={formik.handleChange}
              formikName="referenceRegionIdentifier"
              showAsterisk
              defaultValue={formik.values.referenceRegionIdentifier}
              formik={formik}
              options={sortDropdown(
                configuration.regions,
                process.referenceRegionIdentifier || 0,
                "name"
              )}
            />
            <InputItem
              name="Reference Technology Type"
              description="Please specify the technology type (i.e. the processed ingreident that is the main product of this processing activity) that was used to generate this entry. If no proxy was used this should be identical to the main product that specifies this activity. If a proxy was used, please specify this, and indicate for which key input data a proxy was used for. Please be descriptive but very concise. More information can be documented in the LCI file."
              type="input"
              defaultValue={formik.values.referenceTechnologyType || ""}
              onChange={formik.handleChange}
              showAsterisk
              formik={formik}
              formikName="referenceTechnologyType"
            />
            <InputItem
              name="Reference Technology Proxy Contribution"
              description="Please indicate the sum of the respective percentage contribution for the research areas that use a proxy reference technology type to complete this processing activity. The research areas and their contributions are: 'Ingredient form and quantity' 19%, 'Product information (quantity) & economic allocation' 23%, 'Co-Products & By-products' 10%, 'Resource Source & Amount' 16%, 'Processes losses (evaporation losses)' 6.5%, 'Water usage' 6.5%, 'Waste production (water and biowaste)' 19%. 
              Please indicate the value as a percentage (e.g. 16%, instead of 0.16)."
              type="input"
              defaultValue={
                formik.values.referenceTechnologyProxyContribution == null
                  ? ""
                  : formik.values.referenceTechnologyProxyContribution.toString()
              }
              onChange={formik.handleChange}
              showAsterisk
              formik={formik}
              formikName="referenceTechnologyProxyContribution"
              addonTitle="%"
            />
            <InputItem
              name="Data Quality Completeness Score"
              description="Readonly"
              type="input"
              disabled
              defaultValue={
                formik.values.dataQualityCompletenessScore == null
                  ? ""
                  : formik.values.dataQualityCompletenessScore.toString()
              }
              onChange={formik.handleChange}
              formik={formik}
              formikName="dataQualityCompletenessScore"
            />
          </div>
          <InputItem
            name="Data Quality Comment"
            description="Please provide any comments on assumption that were made to
              determine the criteria for data quality for this record taking
              multiple references into account."
            type="text"
            onChange={formik.handleChange}
            formik={formik}
            className={"w-full"}
            defaultValue={formik.values.dataQualityComment || ""}
            formikName="dataQualityComment"
          />
          {errors &&
            (Array.isArray(errors) ? (
              <ul className="piece-errors">
                {errors.map(([key, value]) =>
                  value.map((err) => (
                    <li>
                      <b>{key}:</b> {err}
                    </li>
                  ))
                )}
              </ul>
            ) : (
              <ul className="piece-errors">
                <li>{errors}</li>
              </ul>
            ))}
          <div className="piece-actions">
            {sessionStorage.getItem("permission") == "true" &&
              processId != "new" && (
                <Button
                  variant="tertiary"
                  className="w-32"
                  size="md"
                  type="submit"
                  disabled={
                    disableRequest ||
                    JSON.stringify(process) != JSON.stringify(formik.values)
                  }
                >
                  {process.state === 0 ? "Publish" : "Unpublish"}
                </Button>
              )}
            <Button
              variant="primary"
              className="w-32"
              size="md"
              onClick={saveDraft}
            >
              Save
            </Button>
            {JSON.stringify(process) != JSON.stringify(formik.values) && (
              <b className="absolute text-sm bottom-0 text-rose-500">
                Please save before publishing new changes
              </b>
            )}
          </div>
        </form>
      </div>
      <ActivityRightPanel
        activityId={processId}
        activityType={ActivityTypes.ProcessingActivity}
        auditCreatedBy={process.researcherName}
        auditLastUpdatedBy={process.lastUpdatedName}
        companyId={formik.values.companyId}
        isVerified={formik.values.isVerified}
        verificationComments={formik.values.verificationComments}
        verifiedByName={formik.values.verifiedByName}
        verifiedOn={formik.values.verifiedOn}
        onChange={formik.handleChange}
      />
    </>
  ) : (
    <div className="overlay">
      <LottieLoader
        lottieType="butterflyLottie"
        style={{ height: "10.25rem", width: "10.25rem" }}
      />
    </div>
  );
};

export default Process;
