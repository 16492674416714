import * as Yup from "yup";
import { transformNullToString } from "../../../shared/transformStringValue";
import { transformNumberValueAllowZero } from '../../../shared/transformNumberValue';

export const formSchema = Yup.object().shape({
  name: Yup.string().transform(transformNullToString).required("This field is required"),
  functionalUnitProxy: Yup.string().transform(transformNullToString).nullable(true),
  faoTradeSource: Yup.number().transform(transformNumberValueAllowZero).required("This field is required"),
  faoProductionSource: Yup.number().transform(transformNumberValueAllowZero).required("This field is required"),
});
