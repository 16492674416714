import * as Yup from 'yup';
import { transformNumberValue } from '../../../shared/transformNumberValue';
import { testNumberWithDotLength } from '../../../shared/testNumberWithDotLength';

export const resourceSourscesComponentSchema = Yup.object().shape({
    resourceSourceId: Yup.number().transform(transformNumberValue).required('The field is required'),
    quantity: Yup.number().transform(transformNumberValue).typeError('Field accepts numbers only').positive('Field value should be greater than 0!').required('Quantity used is required').test('len', 'Max symbols allowed - 32. Max symbols after dot - 16', testNumberWithDotLength)
});

export const formSchema = Yup.object().shape({
    enclosure: Yup.number().typeError('Field accepts numbers only').min(0, 'Please select activity enclosure').required('Field is required').integer(),
    foodCategory: Yup.number().typeError('Field accepts numbers only').min(0, 'Please select food category').required('Field is required').integer(),
    temperatureConditions: Yup.number().typeError('Field accepts numbers only').min(0, 'Please select temperature conditions uses').required().integer(),
    referenceRegionIdentifier: Yup.number().typeError('Field accepts numbers only').min(1, 'Please select region').required('ReferenceRegionIdentifierId is required').integer(),
    description: Yup.string().max(400, 'Too Long!').required('Field is required'),
    secondaryDataReferenceSourceType: Yup.number().typeError('Field accepts numbers only').min(0, 'Please select sources').required().integer(),
    referenceDataPublicationYear: Yup.number().transform(transformNumberValue).typeError('Field accepts numbers only').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').required('Reference data publication year field is required!'),
    referencesSamplingYear: Yup.number().typeError('Field accepts numbers only').min(1900, 'Year can`t be less than 1900').max(2200, 'Year can`t be greater than 2200').nullable(true),
    resourceSources: Yup.array().of(
        resourceSourscesComponentSchema
    ),
});
