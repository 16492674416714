import {
  ITransportActivity,
  IConfiguration,
} from "./../modules/transport-activity/transport/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  transport: ITransportActivity;
  loading: boolean;
  error: string;
  configuration: IConfiguration;
}

const initialState: IState = {
  configuration: {
    medium: [],
    regions: [],
    countries: [],
    processes: [],
    transportResources: [],
    temperatureConditions: [],
    secondaryDataReferenceSourceType: [],
  },
  transport: {
    lastPublishedDate: null,
    researcherName: "",
    researcherEmail: "",
    state: 0,
    userId: null,
    userEmail: null,
    lastUpdatedName: null,
    updated: null,
    id: 0,
    resource: -1,
    temperatureConditions: -1,
    medium: -1,
    shortDescription: "",
    countryId: null,
    regionIdentifierId: -1,
    refQuantity: 1,
    processId: "",
    processName: "",
    description: "",
    comment: "",
    carbon: "",
    waterUsage: "",
    eutrophication: "",
    secondaryDataReferenceSourceType: -1,
    dataQualityReliabilityScore: null,
    referencesSamplingYear: null,
    referenceRegionIdentifier: -1,
    referenceDataPublicationYear: null,
    dataQualityComment: "",
    referenceSourcingCountry: null,
    companyId: null,
    code: null,
    isVerified: false,
    verificationComments: null,
    verifiedOn: null,
    verifiedByEmail: null,
    verifiedByName: null,
  },
  loading: true,
  error: "",
};

const transportSlice = createSlice({
  name: "transport",
  initialState,
  reducers: {
    transportRequest(state) {
      state.loading = true;
    },
    transportSuccess(state, action: PayloadAction<ITransportActivity>) {
      state.loading = false;
      state.transport = action.payload;
    },
    transportFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    configuration(state, action: PayloadAction<IConfiguration>) {
      state.configuration = action.payload;
    },
    handlePublishStatus(state, action: PayloadAction<number>) {
      state.transport.state = action.payload;
    },
    // addNote(state, action) {
    //   if (action.payload.index != undefined) {
    //     if (
    //       state.transport.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] == null
    //     ) {
    //       state.piece.componentsDetails[action.payload.index][
    //         action.payload.key
    //       ] = [];
    //     }
    //     state.piece.componentsDetails[action.payload.index][
    //       action.payload.key
    //     ].push(action.payload.value);
    //   } else {
    //     if (state.piece[action.payload.key] == null) {
    //       state.piece[action.payload.key] = [];
    //     }
    //     state.piece[action.payload.key].push(action.payload.value);
    //   }
    // },
    setDefaultTransport: () => initialState,
  },
});

export default transportSlice.reducer;
export const {
  transportRequest,
  transportSuccess,
  transportFailure,
  handlePublishStatus,
  setDefaultTransport,
  // addNote,
  configuration,
} = transportSlice.actions;
