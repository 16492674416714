import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Breadcrumbs, Button, Input } from "@mondra/ui-components";
import { useQuery } from "@apollo/client";
import { IFactor } from "./types";
import { getEmissionVars } from "./helpers";
import { filterEnums, getTableData } from "../../../shared/gridFunctions";
import Table from "../../../components/Table";
import { GET_FACTORS_ALL } from "../../../query/emissionFactors";

const EmissionFactors: FC = () => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState<IFactor[]>([]);
  const [columnDefs, setColumn] = useState([]);
  const [tableSearch, setTableSearch] = useState<string | null>(null);
  const [debouncedTableSearch, setDebouncedTableSearch] = useState(tableSearch);
  const [totalCount, setTotalCount] = useState<string>("");
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [emissionVars, setEmissionVars] = useState<any>(getEmissionVars);
  const [order, setOrder] = useState([{ id: "ASC" }]);

  const { data, loading, error, refetch } = useQuery(GET_FACTORS_ALL, {
    variables: {
      str: "",
      first: 100,
      last: null,
      order: order,
      ...getEmissionVars,
    },
  });

  const anyRefetch = (variables?: any): Promise<any> => {
    return refetch(variables);
  };

  const setCursors = () => {
    setEndCursor(data.emissionFactors.pageInfo.endCursor);
    setStartCursor(data.emissionFactors.pageInfo.startCursor);
  };

  useEffect(() => {
    const timer = setTimeout(() => setTableSearch(debouncedTableSearch), 1000);
    return () => clearTimeout(timer);
  }, [debouncedTableSearch]);

  useEffect(() => {
    refetch();
    getTableData(
      data,
      loading,
      setTableData,
      setColumn,
      setTotalCount,
      setCursors,
      "/emission-factors/factor",
      "emissionFactors",
      "emissionFactor"
    );
  }, [data]);

  useEffect(() => {
    if (tableSearch != null || !order[0].id) {
      if (!loading) {
        setEmissionVars({ ...filterEnums(tableSearch, getEmissionVars, data) });
        anyRefetch({
          str: tableSearch || "",
          ...filterEnums(tableSearch, getEmissionVars, data),
          after: null,
          before: null,
          first: 100,
          last: null,
          order: order,
        });
      }
    }
  }, [tableSearch, order, loading]);

  return (
    <div className={"packaging"}>
      <div style={{ maxWidth: "50rem" }} className={"mb-4"}>
        <p className={"decoration-gray-600 mt-3"}>
          <Breadcrumbs
            routes={[
              { path: "/emission-factors", title: "Emission Factors Table" },
            ]}
          />
        </p>
        <h2 className={"mt-3 mb-5 text-2xl"}>Emission Factors</h2>
        <div className="packaging-actions">
          <Input
            onChange={(e) => setDebouncedTableSearch(e.target.value)}
            iconType="search"
            placeholder="Search"
          />
          <Button
            onClick={() => navigate("/emission-factors/factor?itemId=new")}
            variant="primary"
          >
            Add Factor
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        tableData={tableData}
        columnDefs={columnDefs}
        totalNumber={totalCount}
        endCursor={endCursor}
        startCursor={startCursor}
        refetch={refetch}
        defaultVars={emissionVars || getEmissionVars}
        setCursors={setCursors}
        tableSearch={tableSearch}
        setOrder={setOrder}
        order={order}
        suppressRowClickSelection
      />
    </div>
  );
};

export default EmissionFactors;
